//
//
//  Team Join
//
//

import {Link, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Button, Container, Flex, LoadingOverlay, Title} from "@mantine/core";
import Api from "../api.ts";
import LoggedHeader from "../components/LoggedHeader.tsx";
import {useApiErrorHandler} from "../hooks.ts";
import {Team} from "../interfaces.ts";
import { useTranslation } from "react-i18next";

function TeamJoin() {
    const { t } = useTranslation()
    const [team, setTeam] = useState<Team | null>(null)
    const handleError = useApiErrorHandler()
    const [loading, setLoading] = useState(true)
    const [searchParams] = useSearchParams()

    useEffect(() => {
        const token = searchParams.get("token")
        if (token == null) {
            return
        }

        Api.joinTeam(token)
            .then(response => {
                setTeam(response)
            }).catch(err => {
                console.error(err)
                handleError(err)
            }).finally(() => {
                setLoading(false)
            })
    }, [])

    if (loading) {
        return <LoadingOverlay visible loaderProps={{size: "xl", variant: "oval"}}/>
    }

    let content
    if (team == null) {
        content = (
            <Title size="h3">{t("You couldn't join the team. Please try again later")}</Title>
        )
    } else {
        content = (
            <Title size="h3">{t("You have successfully joined")} {team.name}</Title>
        )
    }

    return (
        <>
            <header style={{height: 60}}>
                <LoggedHeader/>
            </header>
            <Container>
                <Flex mt="xl" align="center" direction="column" gap={20}>
                    {content}
                    <Flex>
                        <Button component={Link} to="/settings/teams" color="green">{t("Continue")}</Button>
                    </Flex>
                </Flex>
            </Container>
        </>
    )
}

export default TeamJoin
