//
//
//  Project Stories
//
//

import {
    Button,
    Flex,
    Group,
    Modal,
    rem,
    Spoiler,
    TextInput,
    Title,
    Text,
    Accordion,
    Card,
    SimpleGrid, Badge, Anchor, ThemeIcon, LoadingOverlay,
    Container
} from "@mantine/core";
import {useEffect, useRef, useState} from "react";
import {IconAlertTriangle, IconCheck, IconCirclePlus, IconExclamationCircle} from "@tabler/icons-react";
import ProjectStoriesDataTable from "../components/ProjectStoriesDataTable.tsx";
import {Project, Story} from "../interfaces.ts";
import {useNavigate, useOutletContext} from "react-router-dom";
import Api from "../api.ts";
import {useForm} from "@mantine/form";
import ProjectStoryDrawer from "../components/ProjectStoryDrawer.tsx";
import {hasProjectWritePermission, onResourceAdd, onResourceUpdate, sortByUpdatedAt} from "../utils.ts";
import {Socket} from "socket.io-client";
import {useSocket} from "../contexts/SocketContext.tsx";
import {useUser} from "../contexts/AuthContext.tsx";
import PaginationControl from "../components/PaginationControl.tsx";
import { useApiErrorHandler } from "../hooks.ts";
import { useTranslation } from "react-i18next";


interface StoryForm {
    name: string
}


function ProjectStories() {
    const { t } = useTranslation()
    const pageLength = 5

    const {socket}: {socket: Socket} = useSocket()
    const navigate = useNavigate()
    const handleError = useApiErrorHandler()
    const [project]: [Project] = useOutletContext()
    const {user} = useUser()
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [stories, setStories] = useState<Story[]>([])
    const [systemStories, setSystemStories] = useState<Story[]>([])
    const storiesRef = useRef<Story[]>([])
    const systemStoriesRef = useRef<Story[]>([])
    const [selectedStory, setSelectedStory] = useState<Story | null>()
    const [loading, setLoading] = useState(true)
    const [createStoryOpen, setCreateStoryOpen] = useState(false)
    const [createStoryLoading, setCreateStoryLoading] = useState(false)
    const [totalStories, setTotalStories] = useState(0)
    const [page, setPage] = useState(1)

    useEffect(() => {
        if (!hasProjectWritePermission(project, user)) {
            navigate("*")
        }
    }, [project, user])

    function loadStoriesPage() {
        setLoading(true)
        Api.getStories(project.id, page, pageLength)
            .then(pageStories => {
                if (pageStories["items"].length < 1 && page > 1) {
                    setPage(page-1)
                } else {
                    storiesRef.current = pageStories["items"]
                    setStories(sortByUpdatedAt(storiesRef.current))
                    setTotalStories(pageStories["total"])
                    setLoading(false)
                }
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoading(false)
            })
    }

    function loadSystemStories() {
        setLoading(true)
        Api.getSystemStories(project.id)
            .then(stories => {
                systemStoriesRef.current = stories
                setSystemStories(stories)
            }).catch(console.error)
    }

    useEffect(() => {
        loadSystemStories()
    }, [project.id])

    useEffect(() => {
        loadStoriesPage()
    }, [project.id, page])

    useEffect(() => {
        function onAdd(added: Story) {
            if (added.project.id === project.id) {
                if (added.type === null) {
                    const newStory = {...added, _isNew: true}
                    storiesRef.current = [newStory, ...storiesRef.current]
                    setStories(storiesRef.current)
                } else {
                    systemStoriesRef.current = onResourceAdd(added, systemStoriesRef.current)
                    setSystemStories(systemStoriesRef.current)
                }
            } 
        }

        function onUpdate(updated: Story) {
            if (updated.project.id === project.id) {
                storiesRef.current = onResourceUpdate(updated, storiesRef.current)
                setStories(storiesRef.current)
                systemStoriesRef.current = onResourceUpdate(updated, systemStoriesRef.current)
                setSystemStories(systemStoriesRef.current)
            }
            if (updated.id == selectedStory?.id) {
                setSelectedStory({...updated})
            }
        }

        function onDelete(deleted: Story) {
            if (deleted.project.id === project.id) {
                loadStoriesPage()
            }
            if (deleted.id === selectedStory?.id) {
                setSelectedStory(null)
            }
        }

        socket.on("story:add", onAdd)
        socket.on("story:update", onUpdate)
        socket.on("story:delete", onDelete)

        return () => {
            socket.off("story:add", onAdd)
            socket.off("story:update", onUpdate)
            socket.off("story:delete", onDelete)
        }
    }, [project.id, socket, page])

    const createStoryForm = useForm({
        initialValues: {
            name: "",
        },

        validate: {
            name: value => value.length < 1
        }
    })

    function onSubmitCreateStory(values: StoryForm) {
        setCreateStoryLoading(true)
        Api.createStory(project.id, values.name)
            .then(story => {
                setCreateStoryLoading(false)
                setCreateStoryOpen(false)
                createStoryForm.reset()
                setSelectedStory(story)
            })
    }

    function onDeleteStory(story: Story) {
        setDeleteLoading(true)
        Api.deleteStory(story.id)
            .then(() => {
                setDeleteLoading(false)
                setSelectedStory(null)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setDeleteLoading(false)
            })
    }

    function onChangeAccordion(newValue: any) {
        localStorage.setItem(`special-stories-${project.id}-accordion-value`, newValue == null ? "none" : newValue)
    }

    function onSystemStorySelection(systemStory: any) {
        if (systemStory.story == null) {
            setLoading(true)
            Api.createStory(project.id, undefined, systemStory.type)
                .then(story => {
                    setLoading(false)
                    setSelectedStory(story)
                }).catch((err) => {
                    console.error(err);
                    handleError(err)
                    setLoading(false)
                })
        } else {
            setSelectedStory(systemStory.story)
        }
    }

    const userStories = stories.filter(story => story.type == null)
    const welcomeStory = systemStories.find(story => story.type === "SYSTEM_WELCOME")
    const fallbackStory = systemStories.find(story => story.type === "SYSTEM_FALLBACK")
    const videochatIntroductionStory = systemStories.find(story => story.type === "SYSTEM_VIDEOCHAT_INTRODUCTION")
    const welcomeMAIACognitiveStory = systemStories.find(story => story.type === "SYSTEM_WELCOME_MAIACOGNITIVE")

    const doneBadge = <ThemeIcon radius="xl" color="teal"><IconCheck size={16}/></ThemeIcon>
    const notDoneBadge = <ThemeIcon radius="xl" color="red"><IconExclamationCircle size={16}/></ThemeIcon>
    const warningBadge = <ThemeIcon radius="xl" color="yellow"><IconAlertTriangle size={16}/></ThemeIcon>

    const chat_badge = <Badge key="chat" style={{whiteSpace: "nowrap"}} variant="outline" color="cyan">Chat</Badge>
    const videochat_badge = <Badge key="videochat" style={{whiteSpace: "nowrap"}} variant="outline" color="pink">VideoChat</Badge>
    const alexa_badge = <Badge key="alexa" style={{whiteSpace: "nowrap"}} variant="outline" color="lime">Alexa</Badge>
    const phone_badge= <Badge key="phone" style={{whiteSpace: "nowrap"}} variant="outline" color="indigo">{t("Phone")}</Badge>
    const telegram_badge = <Badge key="telegram" style={{whiteSpace: "nowrap"}} variant="outline" color="orange">Telegram</Badge>

    const specialStories = [
        {
            type: "SYSTEM_WELCOME",
            title: t("Welcome"),
            visible: true,
            story: welcomeStory,
            description: t("This story will be triggered when the user starts any channel."),
            status: welcomeStory == null || welcomeStory.steps < 1 ? notDoneBadge : doneBadge,
            badges: [
                chat_badge,
                videochat_badge,
                alexa_badge,
                phone_badge,
                telegram_badge
            ]
        },
        {
            type: "SYSTEM_WELCOME_MAIACOGNITIVE",
            visible: user?.is_admin,
            story: welcomeMAIACognitiveStory,
            status: welcomeMAIACognitiveStory == null || welcomeMAIACognitiveStory.steps < 1 ? warningBadge : doneBadge,
            title: t("Welcome - maiacognitive.com"),
            description: t("This story will be triggered when the user starts the videochat on maiacognitive.com"),
            badges: [
                videochat_badge
            ]
        },
        {
            type: "SYSTEM_VIDEOCHAT_INTRODUCTION",
            title: t("Who is MAIA?"),
            story: videochatIntroductionStory,
            status: videochatIntroductionStory == null || videochatIntroductionStory.steps < 1 ? warningBadge : doneBadge,
            visible: true,
            description: t("This story will be triggered when the user presses the button 'Who is MAIA?' in the VideoChat"),
            badges: [
                videochat_badge,
                alexa_badge
            ]
        },
        {
            type: "SYSTEM_FALLBACK",
            title: t("Fallback"),
            visible: true,
            story: fallbackStory,
            status: fallbackStory == null || fallbackStory.steps < 1 ? notDoneBadge : doneBadge,
            description: t("This story will be triggered when the conversational assistant doesn't know how to respond."),
            badges: [
                chat_badge,
                videochat_badge,
                alexa_badge,
                phone_badge,
                telegram_badge
            ]
        },
    ]

    let specialStoriesValue = localStorage.getItem(`special-stories-${project.id}-accordion-value`)
    if (specialStoriesValue === "none") {
        specialStoriesValue = null
    } else {
        specialStoriesValue = "special-stories"
    }

    return (
        <>
            <Container>
                <Flex
                    justify="space-between"
                    align="center"
                    direction="row"
                    mt={rem(50)}
                    mb={rem(30)}
                >
                    <Title size="h1">{t("Stories")}</Title>

                    <Button
                        onClick={() => setCreateStoryOpen(true)}
                        leftSection={<IconCirclePlus size={16}/>}
                    >
                        {t("Create Story")}
                    </Button>
                </Flex>
                <Spoiler mb="xl" maxHeight={50} showLabel="Show more" hideLabel="Hide">
                    {t("In conversational assistant development, stories are narrative sequences that illustrate the interaction flow between users and the conversational assistant.")}
                    <br/>
                    {t("These stories are constructed during the training process to teach the conversational assistant how to handle different scenarios and maintain coherent conversations.")}
                    <br/>
                    <Text mt={12} mb={4} fw={600}>Example:</Text>
                    {t('For the topic "Book a Flight," a corresponding story might include:')}
                    <ul>
                        <li>
                            <Text component="span" fw={500}>{t("User Input:")}</Text> {t(`"I need to book a flight to Paris."`)}
                        </li>
                        <li>
                            <Text component="span" fw={500}>{t("Conversational Assistant Response:")}</Text> {t(`"Sure, let's find the best options for you. When would you like to depart?"`)}
                        </li>
                        <li>
                            <Text component="span" fw={500}>{t("User Input:")}</Text> {t(`"I'll be flying from New York, and I prefer to leave on December 10th."`)}
                        </li>
                        <li>
                            <Text component="span" fw={500}>{t("Conversational Assistant Response:")}</Text> {t(`"Got it! Let me check available flights from New York on December 10th."`)}
                        </li>
                    </ul>
                    {t("Understanding how stories guide the conversational assistant through different scenarios is fundamental to creating conversational agents capable of handling diverse user interactions.")}
                </Spoiler>
                <Accordion mb="lg" variant="separated" defaultValue={specialStoriesValue} onChange={onChangeAccordion}>
                    <Accordion.Item value="special-stories">
                        <Accordion.Control>
                            <Title size="h3" mb="md">{t("MAIA Studio Stories")}</Title>
                        </Accordion.Control>
                        <Accordion.Panel>
                            <LoadingOverlay visible={loading}/>
                            <SimpleGrid cols={3} spacing="xl">
                                {specialStories.flatMap(specialStory => {
                                    if (!specialStory.visible) {
                                        return []
                                    }

                                    return (
                                        <Card key={specialStory.type} shadow="sm" padding="lg" radius="md" withBorder>
                                            <Flex direction="column" justify="space-between" style={{height: "100%"}}>
                                                <Flex align="center" justify="space-between" mb="xs" gap="sm">
                                                    <Anchor onClick={() => onSystemStorySelection(specialStory)}>
                                                        <Title size="h3">
                                                            {specialStory.title}
                                                        </Title>
                                                    </Anchor>
                                                    {specialStory.status}
                                                </Flex>
                                                <Text size="sm" c="dimmed">
                                                    {specialStory.description}
                                                </Text>
                                                <Flex mt="sm" gap="sm" wrap="wrap">
                                                    {specialStory.badges}
                                                </Flex>
                                            </Flex>
                                        </Card>
                                    )
                                })}
                            </SimpleGrid>
                        </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>
                <Title size="h3" mb="md">{t("Your Stories")}</Title>
                <ProjectStoriesDataTable
                    stories={userStories}
                    loading={loading}
                    onCreate={() => setCreateStoryOpen(true)}
                    onSelection={story => setSelectedStory(story)}
                />
                <PaginationControl 
                    totalElements={totalStories}
                    page={page}
                    pageLength={pageLength}
                    onChange={setPage}
                />
            </Container>
            <Modal opened={createStoryOpen} onClose={() => setCreateStoryOpen(false)} title={t("Create Story")}>
                <form onSubmit={createStoryForm.onSubmit(onSubmitCreateStory)}>
                    <TextInput
                      withAsterisk
                      label={t("Name")}
                      required
                      placeholder={t("Super duper story")}
                      {...createStoryForm.getInputProps("name")}
                    />
                    <Group align="right" mt="md">
                        <Button type="submit" loading={createStoryLoading}>{t("Submit")}</Button>
                    </Group>
                </form>
            </Modal>
            {selectedStory != null &&
                <ProjectStoryDrawer
                    story={selectedStory}
                    opened={true}
                    onClose={() => setSelectedStory(null)}
                    deleteLoading={deleteLoading}
                    onDelete={onDeleteStory}
                />
            }
        </>
    )
}

export default ProjectStories
