//
//
//  Response Drawer
//
//

import {Alert, Button, Divider, Drawer, Flex, Textarea, TextInput, Title} from "@mantine/core"
import {Project, Response} from "../interfaces.ts"
import {
    IconInfoCircle,
    IconTrash,
} from "@tabler/icons-react";
import classes from "./ProjectResponseDrawer.module.css"
import {useForm} from "@mantine/form";
import {useEffect, useRef, useState} from "react";
import {useOutletContext} from "react-router-dom";
import ProjectResponseAudioPlayer from "./ProjectResponseAudioPlayer.tsx";
import Api from "../api.ts";
import MediaUpload, {IMAGE_MIME_TYPES, VIDEO_MIME_TYPES} from "./MediaUpload.tsx";
import {megabytes} from "../utils.ts";
import { useApiErrorHandler } from "../hooks.ts";
import { useTranslation } from "react-i18next";

interface ResponseForm {
    response: string
}


function ProjectResponseDrawer({response, opened, onClose, onDelete, deleteLoading, onUpdate, updateLoading}: {
    response: Response,
    opened: boolean,
    onClose: () => void,
    onDelete: (response: Response) => void,
    deleteLoading: boolean,
    onUpdate: (response: Response, values: ResponseForm) => void,
    updateLoading: boolean
}) {
    const { t } = useTranslation();
    const [uploadLoading, setUploadLoading] = useState(false)
    const [project]: [Project] = useOutletContext()

    const textRef = useRef(null)
    const handleError = useApiErrorHandler()
    const updateResponseForm = useForm({
        initialValues: {
            response: response.response,
            name: response.name
        }
    })

    useEffect(() => {
        updateResponseForm.setValues({
            response: response.response,
            name: response.name
        })
    }, [response])

    function onSubmitUpdateResponse(values: ResponseForm) {
        onUpdate(response, values)
    }

    function onDropBackground(file: File) {
        setUploadLoading(true)
        Api.createResponseBackground(response.id, file)
            .then(() => {
                setUploadLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setUploadLoading(false)
            })
    }

    function onDeleteBackground() {
        setUploadLoading(true)
        Api.deleteResponseBackground(response.id)
            .then(() => {
                setUploadLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setUploadLoading(false)
            })
    }

    const textValue = (textRef.current as (HTMLTextAreaElement | null))?.value || ""

    function backgroundImage(): string | undefined {
        if ((response.background_content_type != null) && (IMAGE_MIME_TYPES.indexOf(response.background_content_type) >= 0)) {
            return response.background
        }
    }

    function backgroundVideo(): string | undefined {
        if ((response.background_content_type != null) && (VIDEO_MIME_TYPES.indexOf(response.background_content_type) >= 0)) {
            return response.background
        }
    }

    return (
        <Drawer
            opened={opened}
            onClose={onClose}
            title={response.name}
            position="right"
            styles={{
                content: {
                    display: "flex",
                    flexDirection: "column"
                },
                body: {
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1
                }
            }}
        >
            <div className={classes.content}>
                <Flex direction="column">
                    <form onSubmit={updateResponseForm.onSubmit(onSubmitUpdateResponse)}>
                        <TextInput
                            withAsterisk
                            required
                            label={t("Name")}
                            placeholder={t("Super duper response")}
                            mb="md"
                            {...updateResponseForm.getInputProps("name")}
                        />
                        <Textarea
                            label={t("Response")}
                            name={t("response")}
                            withAsterisk
                            required
                            autosize
                            ref={textRef}
                            mb="sm"
                            {...updateResponseForm.getInputProps("response")}
                        />
                        <Flex justify="flex-end">
                        </Flex>
                        <Flex justify="space-between" mt="sm">
                            <Button mt="lg" type="submit" loading={updateLoading}>{t("Save")}</Button>
                            <ProjectResponseAudioPlayer
                                project={project}
                                text={textValue}
                            />
                        </Flex>
                    </form>
                    <Title size="h3" mt="md" mb="xs">{t("Background")}</Title>
                    <Alert variant="light" mb="md" color="orange" title={t("Image will be public")} icon={<IconInfoCircle />}>
                        {t("Don't store any confidential information.")}
                    </Alert>
                    <MediaUpload
                        loading={uploadLoading}
                        onDrop={onDropBackground}
                        onDelete={onDeleteBackground}
                        maxSize={megabytes(50)}
                        acceptImages={true}
                        acceptVideos={true}
                        image={backgroundImage()}
                        video={backgroundVideo()}
                    />
                </Flex>
                <div className={classes.footer}>
                    <Divider mt="xl" mb="xl"/>
                    <Button loading={deleteLoading} color="red" leftSection={<IconTrash size={16}/>} fullWidth
                            onClick={() => onDelete(response)}>
                        {t("Delete response")}
                    </Button>
                </div>
            </div>
        </Drawer>
    )
}

export default ProjectResponseDrawer
