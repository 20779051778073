//
//
//  Project
//
//

import CustomProjectLayout from "../layouts/CustomProjectLayout.tsx";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Api from "../api.ts";
import {LoadingOverlay} from "@mantine/core";
import {Project} from "../interfaces.ts";
import {Socket} from "socket.io-client";
import {useSocket} from "../contexts/SocketContext.tsx";
import DocumentsProjectLayout from "../layouts/DocumentsProjectLayout.tsx";
import { useApiErrorHandler } from "../hooks.ts";
import { useUser } from "../contexts/AuthContext.tsx";

function Project() {
    const navigate = useNavigate()
    const handleError = useApiErrorHandler()
    const { user } = useUser()
    const {socket}: {socket: Socket} = useSocket()
    const { projectId } = useParams()
    const [project, setProject] = useState<Project | null>(null)
    const [explore, setExplore] = useState(false)

    useEffect(() => {
        if (projectId != null) {
            Api.getProject(projectId)
                .then(serverProject => {
                    setProject(serverProject)

                    if (serverProject.created_by.id === user?.id || user?.is_admin) {
                        setExplore(false)
                    } else if (serverProject.is_public) {
                        setExplore(true)
                    }
                }).catch((err) => {
                    console.error(err);
                    handleError(err)
                })
        }
    }, [projectId, socket, user])

    useEffect(() => {
        function onProjectUpdate(updatedProject: Project) {
            if (projectId != null && updatedProject.id === parseInt(projectId)) {
                setProject(updatedProject)
            }
        }

        function onProjectDelete(deletedProject: Project) {
            if (projectId != null && deletedProject.id === parseInt(projectId)) {
                navigate("/projects")
            }
        }

        socket.on("project:update", onProjectUpdate)
        socket.on("project:delete", onProjectDelete)

        return () => {
            socket.off("project:update", onProjectUpdate)
            socket.off("project:delete", onProjectDelete)
        }
    }, [socket, projectId, navigate])

    if (project == null) {
        return (
            <LoadingOverlay visible loaderProps={{ size: "xl", variant: "oval"}}/>
        )
    }

    if (project.type === "CUSTOM") {
        return (
            <CustomProjectLayout project={project} explore={explore}>
                <Outlet context={[project, setProject]}/>
            </CustomProjectLayout>
        )
    } else if (project.type === "DOCUMENTS") {
        return (
            <DocumentsProjectLayout project={project} explore={explore}>
                <Outlet context={[project, setProject]}/>
            </DocumentsProjectLayout>
        )
    } else {
        throw new Error(`Project type ${project.type} unknown`)
    }
}

export default Project
