//
//
//  Project Layout
//
//

import {Project} from "../interfaces.ts";
import {ReactNode} from "react";
import {
    IconChartDots3,
    IconDeviceGamepad,
    IconHome2,
    IconMessage2, IconPackages, IconRocket,
    IconShare2, IconSpy, IconStethoscope,
    IconTargetArrow
} from "@tabler/icons-react";
import ProjectLayout from "./ProjectLayout.tsx";
import { useTranslation } from "react-i18next";


function CustomProjectLayout({project, children, explore}: {project: Project, children: ReactNode, explore: boolean}) {
    const { t } = useTranslation();
    const sections = [
        {
            label: undefined,
            items: [
                { icon: <IconHome2 size={16} />, color: 'teal', label: t("Home"), to: `/projects/${project.id}`, active: "home"},
            ]
        },
        {
            label: t("Conversational Assistant"),
            items: [
                { icon: <IconTargetArrow size={16} />, color: 'blue', label: t("Topics (Intents)"), to: "intents" , active: "intents"},
                { icon: <IconMessage2 size={16} />, color: 'red', label: t("Responses"), to: "responses", active:  "responses"},
                { icon: <IconChartDots3 size={16} />, color: 'violet', label: t("Stories"), to: "stories", active: "stories" },
                { icon: <IconRocket size={16} />, color: 'orange', label: t("Builds"), to: "builds", active: "builds" },
            ]
        },
        {
            label: t("Connect"),
            items: [
                {icon: <IconStethoscope size={16}/>, color: "gray", label: t("Test"), to: "test" , active: "test"},
                {icon: <IconDeviceGamepad size={16}/>, color: "lime", label: t("Playground"), to: "playground" , active: "playground"},
                {icon: <IconShare2 size={16}/>, color: "blue", label: t("Share"), to: "share" , active: "share"},
                {icon: <IconSpy size={16}/>, color: "dark", label: t("Conversations"), to: "sniffer" , active: "sniffer"},
                {icon: <IconPackages size={16}/>, color: "pink", label: t("Deployments"), to: "deployments" , active: "deployments"},
            ]
        }
    ]

    if (explore) {
        sections.splice(1, 1)
    }

    return (
        <ProjectLayout
            project={project}
            sections={sections}
            children={children}
            explore={explore}
        />
    )
}

export default CustomProjectLayout
