//
//
//  Document Viewer
//
//

import {Document, Page} from "react-pdf";
import {Flex, Loader} from "@mantine/core";
import {useState} from "react";
import { useTranslation } from "react-i18next";

import classes from "./DocumentViewer.module.css"

function DocumentViewer({file}: {file?: string}) {
    const { t } = useTranslation()
    
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }: {numPages: number}) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset: number) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    return (
        <div className={classes.documentWrapper}>
            <Document onLoadSuccess={onDocumentLoadSuccess} file={file} loading={<Flex align="center" justify="center" mt="xl"><Loader/></Flex>}>
                <Page width={300} pageNumber={pageNumber} />
            </Document>
            <div className={classes.pageControls}>
                <button
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                    type="button"
                    aria-label={t("Previous page")}
                >
                    ‹
                </button>
                <span>
                    {pageNumber} / {numPages}
                </span>
                <button
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                    type="button"
                    aria-label={t("Next page")}
                >
                    ›
                </button>
            </div>
        </div>
    )
}

export default DocumentViewer
