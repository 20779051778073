//
//
//  Forgot Password
//
//

import {
    Container,
    Flex,
    Paper,
    Group, TextInput, Anchor, Center, Box, Button, rem, Title, Text
} from "@mantine/core";
import MAIALogo from "../components/MAIALogo.tsx";
import {IconArrowLeft} from "@tabler/icons-react";
import {Link} from "react-router-dom";
import {useForm, isEmail} from "@mantine/form";
import Api from "../api.ts";
import {useState} from "react";
import {notifications} from "@mantine/notifications";
import { useApiErrorHandler } from "../hooks.ts";
import { useTranslation } from "react-i18next";


function ForgotPassword() {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const handleError = useApiErrorHandler()
    const form = useForm({
        initialValues: {
            email: ""
        },
        validate: {
            email: isEmail(t('Invalid email')),
        },
    })

    function onSubmit(values: any) {
        setLoading(true)
        Api.forgotPassword(values["email"])
            .then(() => {
                setLoading(false)
                notifications.show({
                    title: t("Email sent"),
                    message: t("We have sent you an email with a method to reset your password"),
                    color: "green",
                })
                form.reset()
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoading(false)
            })
    }

    return (
        <Container size={460} my={30}>
            <Flex justify="center" style={{height: "100px"}} direction="column" align="center">
                <MAIALogo/>
            </Flex>

            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                <Title ta="center" size="h3" mb="xs">{t("Forgot your password?")}</Title>
                <Text c="dimmed" fz="sm" ta="center" mb="md">
                    {t("Enter your email to get a reset link")}
                </Text>
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <TextInput label={t("Your email")} placeholder="you@maiacognitive.com" required {...form.getInputProps("email")}/>
                    <Group justify="space-between" mt="lg">
                        <Anchor c="dimmed" size="sm" component={Link} to="/login">
                            <Center inline>
                                <IconArrowLeft style={{width: rem(12), height: rem(12)}} stroke={1.5}/>
                                <Box ml={5}>{t("Back to the login page")}</Box>
                            </Center>
                        </Anchor>
                        <Button loading={loading} type="submit">{t("Reset password")}</Button>
                    </Group>
                </form>
            </Paper>
        </Container>
    )
}

export default ForgotPassword
