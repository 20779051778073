import React from "react";
import { useTranslation } from "react-i18next";
import { Select } from "@mantine/core"


export default function LanguageSwitcher () {
    const { t } = useTranslation()
    const options: Record<string, string> = {
        "en": "English",
        "es": "Español"
    }

    const { i18n } = useTranslation();
    let language = "en";

    if (i18n.language in options) {
        language = i18n.language;
    }

    const [selectedLanguage, setSelectedLanguage] = React.useState(language);

    const handleSelect = (newValue: string | null) => {
        if (newValue) {
            setSelectedLanguage(newValue);
            i18n.changeLanguage(newValue as string);
        }
    };

    return (
        <>
        <Select
            label={t("Language")}
            data={[
                {"label": "Español", "value": "es"},
                {"label": "English", "value": "en"}
            ]}
            value={selectedLanguage}
            onChange={handleSelect}
        />
        </>
    );
} 