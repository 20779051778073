//
//
//  IconHelpAssistant
//
//

import {useState} from 'react';
import classes from './IconHelpAssistant.module.css'
import {ActionIcon, Tooltip} from '@mantine/core'
import {IconMessageChatbot} from "@tabler/icons-react";
import {IconChevronDown} from "@tabler/icons-react";
import HelpAssistantChatWrapper from './HelpAssistantChatWrapper';
import { useTranslation } from "react-i18next";

function IconHelpAssistant() {
    const { t } = useTranslation()
    const [helpAssistantOpened, setHelpAssistantOpened] = useState<boolean>(false)

    return (
        <>
            <Tooltip label={t("Do you need help with MAIA Studio?")} opened={!helpAssistantOpened && undefined}>
                <ActionIcon variant="filled" size="xl" radius="xl" aria-label={t("Help")} onClick={() => setHelpAssistantOpened(!helpAssistantOpened)} className={classes.wrapperIcon}>
                {helpAssistantOpened ? (
                    <IconChevronDown style={{ width: '70%', height: '70%' }} stroke={1.5} />
                ) : (
                    <IconMessageChatbot style={{ width: '70%', height: '70%' }} stroke={1.5} />
                )}

                </ActionIcon>
            </Tooltip>
            <HelpAssistantChatWrapper
             opened={helpAssistantOpened}
             onClose={() => setHelpAssistantOpened(false)}
            />
        </>
    )
}

export default IconHelpAssistant
