//
//
//  Socket Context
//
//

import {io, Socket} from "socket.io-client";
import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {getAccessToken} from "../utils.ts";


function initSocket() {
    return io({
        autoConnect: false
    })
}

interface SocketContextInterface {
    socket: Socket
}

const SocketContext = createContext<SocketContextInterface | undefined>(undefined)

export const SocketProvider = function({children}: {children: ReactNode}) {
    const [socket, setSocket] = useState<Socket>(initSocket)

    const accessToken = getAccessToken()

    useEffect(() => {
        if (accessToken == null) {
            setSocket(initSocket())
        }

        const newSocket = io(import.meta.env.VITE_API_URL, {
            path: "/ws/socket.io",
            transports: ["websocket", "polling", "flashsocket"],
            auth: {
                token: accessToken
            }
        })
        setSocket(newSocket)

        return () => {
            newSocket.disconnect()
        }
    }, [accessToken])

    return (
        <SocketContext.Provider value={{socket: socket}}>
            {children}
        </SocketContext.Provider>
    )
}

export function useSocket() {
    const socketContext = useContext(SocketContext)
    if (!socketContext) {
        throw new Error("No SocketContext.Provider found when calling useSocket.")
    }
    return socketContext
}
