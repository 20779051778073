//
//
//  Project Test
//
//

import {Flex, rem, Spoiler, Text, Title} from "@mantine/core";
import "@maia-cognitive/maia-videochat"
// import "maia-chat-widget"  FIXME: NotSupportedError: Cannot define multiple custom elements with the same tag name
import {Project} from "../../interfaces.ts";
import {useOutletContext} from "react-router-dom";
import ProjectCustomBuildPlayer from "../../components/ProjectCustomBuildPlayer.tsx";
import { useTranslation } from "react-i18next";


function ProjectPlaygroundCustom() {
    const { t } = useTranslation();
    const [project]: [Project] = useOutletContext()

    return (
        <>
            <Flex
                justify="space-between"
                align="center"
                direction="row"
                mt={rem(50)}
                mb={rem(30)}
            >
                <Title size="h1">{t("Playground")}</Title>
            </Flex>
            <Spoiler mb="xl" maxHeight={50} showLabel={t("Show more")} hideLabel={t("Hide")}>
                {t("In the realm of conversational assistant development, a playground serves as an interactive space where developers can engage with their trained models. This section provides a hands-on environment for testing, fine-tuning, and exploring the capabilities of the conversational assistant in a controlled setting.")}
                <br/>
                <Text mt={12} mb={4} fw={600}>{t("Example")}:</Text>
                <ol>
                    <li>
                        <Text component="span" fw={500}>{t("Input")}</Text>: {t(`Developers simulate a user asking, "What's the weather like tomorrow in New York?"`)}
                    </li>
                    <li>
                        <Text component="span" fw={500}>{t("Response")}</Text>: {t(`The model generates a response, providing accurate weather information for New York.`)}
                    </li>
                    <li>
                        <Text component="span" fw={500}>{t("Evaluation")}</Text>: {t(`Developers assess the response quality and make adjustments as needed, refining the model's understanding.`)}
                    </li>
                </ol>
                {t("Understanding and utilizing the playground section are integral to the ongoing development and enhancement of conversational assistant models, ensuring they meet the desired standards of performance and user engagement.")}
            </Spoiler>
            <ProjectCustomBuildPlayer project={project}/>
        </>
    )
}

export default ProjectPlaygroundCustom
