//
//
//  LoggedRoute
//
//

import {useUser} from "../contexts/AuthContext.tsx"
import {Navigate, Outlet, useLocation} from "react-router-dom"
import {SocketProvider} from "../contexts/SocketContext.tsx";
import IconHelpAssistant from "./IconHelpAssistant.tsx";


function RequireLoggedIn() {
    const {user} = useUser()
    const location = useLocation()

    if (user == null) {
        return <Navigate to="/login" state={{ from: location }} />
    }

    return (
        <>
            <SocketProvider>
                <Outlet/>
                <IconHelpAssistant/>
            </SocketProvider>
        </>
    )
}

export default RequireLoggedIn
