//
//
//  Project Source Drawer
//
//

import {Source} from "../interfaces.ts";
import {Drawer} from "@mantine/core";
import {useForm} from "@mantine/form";
import {Button, TextInput} from "@mantine/core";
import {useState} from "react";

import classes from "./ProjectSourceDrawer.module.css"
import {Divider} from "@mantine/core";
import {IconTrash} from "@tabler/icons-react";
import Api from "../api.ts";
import DocumentViewer from "./DocumentViewer.tsx";
import { useApiErrorHandler } from "../hooks.ts";
import { useTranslation } from "react-i18next";

function ProjectSourceDrawer({source, opened, onClose, onDelete, deleteLoading}: {source: Source, opened: boolean, onClose: () => void, onDelete: (source: Source) => void, deleteLoading: boolean}) {
    const { t } = useTranslation()
    const [updateLoading, setUpdateLoading] = useState(false)
    const handleError = useApiErrorHandler()
    const sourceForm = useForm({
        initialValues: {
            name: source.name
        }
    })

    function onSubmitUpdateSource(values: any) {
        setUpdateLoading(true)
        Api.updateSource(source.id, values)
            .then(() => {
                setUpdateLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setUpdateLoading(false)
            })
    }

    return (
        <Drawer
            opened={opened}
            onClose={onClose}
            title={source.name}
            position="right"
            className={classes.drawer}
            withinPortal={true}
            styles={{
                content: {
                    display: "flex",
                    flexDirection: "column"
                },
                body: {
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1
                }
            }}
        >
            <form onSubmit={sourceForm.onSubmit(onSubmitUpdateSource)}>
                <TextInput
                  withAsterisk
                  required
                  label={t("Name")}
                  placeholder={t("Super duper source")}
                  {...sourceForm.getInputProps("name")}
                />
                <Button mt="md" type="submit" loading={updateLoading}>{t("Save")}</Button>
            </form>
            <div className={classes.content}>
                <DocumentViewer file={source.file}/>
                <div className={classes.footer}>
                    <Divider mt="xl" mb="xl"/>
                    <Button loading={deleteLoading} color="red" leftSection={<IconTrash size={16}/>} fullWidth onClick={() => onDelete(source)}>
                        {t("Delete source")}
                    </Button>
                </div>
            </div>
        </Drawer>
    )
}

export default ProjectSourceDrawer
