//
//
//  Project Intents
//
//

import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import {
    Alert,
    Button,
    Flex,
    Group,
    Modal,
    rem, Spoiler,
    TextInput,
    Title,
    Container
} from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { IconAlertCircle, IconCirclePlus } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import Api from "../api.ts";
import { Intent, Project, Utterance } from "../interfaces.ts";
import ProjectIntentDrawer from "../components/ProjectIntentDrawer.tsx";
import ProjectIntentsDataTable from "../components/ProjectIntentsDataTable.tsx";
import {hasProjectWritePermission, onResourceUpdate, sortByUpdatedAt } from "../utils.ts";
import { Socket } from "socket.io-client";
import { useSocket } from "../contexts/SocketContext.tsx";
import  {notifications } from "@mantine/notifications";
import PaginationControl from "../components/PaginationControl.tsx";
import { useApiErrorHandler } from "../hooks.ts";
import { useUser } from "../contexts/AuthContext.tsx";
import { useTranslation } from "react-i18next";


interface IntentForm {
    name: string
}

function ProjectIntents() {
    const { t } = useTranslation();
    const pageLength = 5

    const { socket }: { socket: Socket } = useSocket()
    const navigate = useNavigate()
    const handleError = useApiErrorHandler()
    const { user } = useUser()
    const location = useLocation()
    const [loading, setLoading] = useState(true)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [project]: [Project] = useOutletContext()
    const [selectedIntent, setSelectedIntent] = useState<Intent | null>(location.state?.selected)
    const intentsRef = useRef<Intent[]>([])
    const [intents, setIntents] = useState<Intent[]>([])
    const [createIntentOpen, setCreateIntentOpen] = useState(false)
    const [createIntentLoading, setCreateIntentLoading] = useState(false)
    const [totalIntents, setTotalIntents] = useState(0)
    const [page, setPage] = useState(1)

    useEffect(() => {
        if (!hasProjectWritePermission(project, user)) {
            navigate("*")
        }
    }, [project, user])

    function loadIntentsPage() {
        setLoading(true)
        Api.getIntentsPage(project.id, page, pageLength)
            .then(pageIntents => {
                if (pageIntents["items"].length < 1 && page > 1) {
                    setTotalIntents(totalIntents-1)
                } else {
                    intentsRef.current = pageIntents["items"]
                    setIntents(sortByUpdatedAt(intentsRef.current))
                    setTotalIntents(pageIntents["total"])
                    setLoading(false)
                }
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoading(false)
            })
    }

    useEffect(() => {
        loadIntentsPage()
    }, [project.id, page])

    useEffect(() => {
        function onAdd(added: Intent) {
            const newIntent = {...added, _isNew: true}
            intentsRef.current = [newIntent, ...intentsRef.current]
            setIntents(intentsRef.current)
        }

        function onUpdate(updated: Intent) {
            if (updated.project.id === project.id) {
                intentsRef.current = onResourceUpdate(updated, intentsRef.current)
                setIntents(intentsRef.current)
            }
            if (updated.id == selectedIntent?.id) {
                setSelectedIntent({ ...updated })
            }
        }

        function onDelete(deleted: Intent) {
            if (deleted.project.id === project.id) {
                loadIntentsPage()
            }
            if (deleted.id === selectedIntent?.id) {
                setSelectedIntent(null)
            }
        }

        // noinspection DuplicatedCode
        socket.on("intent:add", onAdd)
        socket.on("intent:update", onUpdate)
        socket.on("intent:delete", onDelete)

        return () => {
            socket.off("intent:add", onAdd)
            socket.off("intent:update", onUpdate)
            socket.off("intent:delete", onDelete)
        }
    }, [project.id, socket, page])

    const createIntentForm = useForm({
        initialValues: {
            name: "",
        },
        validate: {
            name: value => value.length < 1
        }
    })

    function onSubmitCreateIntent(values: IntentForm) {
        setCreateIntentLoading(true)
        Api.createIntent(project.id, values.name)
            .then(created => {
                setCreateIntentLoading(false)
                setCreateIntentOpen(false)
                createIntentForm.reset()
                setSelectedIntent(created)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setCreateIntentLoading(false)
            })
    }

    function onDeleteIntent(intent: Intent) {
        setDeleteLoading(true)
        Api.deleteIntent(intent.id)
            .then(() => {
                setDeleteLoading(false)
                setSelectedIntent(null)
            }).catch(err => {
                if (err.response.status == 409) {
                    // Intent used in story
                    err.response.json()
                        .then((data: any) => {
                            if (data["detail"]["code"] === "used_by_story") {
                                notifications.show({
                                    title: t("Error"),
                                    message: t("Topic is in use by story") + ` ${data["detail"]["story"]["name"]}. ` + t("Please delete story before."),
                                    color: "red"
                                })
                                setDeleteLoading(false)
                            }
                        })
                } else {
                    console.error(err);
                    handleError(err)
                    setDeleteLoading(false)
                }
            })
    }

    function onUtteranceAdd(_: Utterance, intent: Intent) {
        setIntents(intents.map((x: Intent) => {
            if (x.id == intent.id) {
                return Object.assign({}, x, { "utterances": x.utterances + 1 })
            } else {
                return x
            }
        }))
    }

    function onUtteranceDelete(_: Utterance, intent: Intent) {
        setIntents(intents.map((x: Intent) => {
            if (x.id == intent.id) {
                return Object.assign({}, x, { "utterances": x.utterances - 1 })
            } else {
                return x
            }
        }))
    }

    return (
        <>
            <Container>
                <Flex
                    justify="space-between"
                    align="center"
                    direction="row"
                    mt={rem(50)}
                    mb={rem(30)}
                >
                    <Title size="h1">{t("Topics (Intents)")}</Title>

                    <Button
                        onClick={() => setCreateIntentOpen(true)}
                        leftSection={<IconCirclePlus size={16} />}
                    >
                        {t("Create Topic (Intent)")}
                    </Button>
                </Flex>

                <Spoiler mb="xl" maxHeight={50} showLabel={t("Show more")} hideLabel={t("Hide")}>
                    {t("In conversational assistant development, a topic represents the user's goal or purpose behind a message. It's the key to understanding what the user wants.")}
                    <br />
                    {t('During training, developers define different topics (e.g., "Book a Flight," "Check Weather") to teach the conversational assistant how to recognize and respond to various user requests. The conversational assistant uses natural language processing to identify topics, enabling it to deliver relevant responses.')}
                    <br />
                    {t("Understanding topics is essential for creating effective and responsive conversational assistants.")}
                </Spoiler>

                {!loading && totalIntents < 2 && <Alert variant="filled" color="yellow" withCloseButton title={t("Model won't work")} icon={<IconAlertCircle />} mb="lg">
                    {t("Model with less than 2 topics (intents) won't recognize any topic. Please add at least 2 topics.")}
                </Alert>}
                <ProjectIntentsDataTable
                    intents={intents}
                    loading={loading}
                    onCreate={() => setCreateIntentOpen(true)}
                    onSelection={intent => setSelectedIntent(intent)}
                />
                <PaginationControl
                    totalElements={totalIntents}
                    page={page}
                    pageLength={pageLength}
                    onChange={setPage}
                />
            </Container>
            <Modal opened={createIntentOpen} onClose={() => setCreateIntentOpen(false)} title={t("Create Topic")}>
                <form onSubmit={createIntentForm.onSubmit(onSubmitCreateIntent)}>
                    <TextInput
                        withAsterisk
                        label={t("Name")}
                        required
                        placeholder={t("Super duper topic")}
                        {...createIntentForm.getInputProps("name")}
                    />
                    <Group align="right" mt="md">
                        <Button type="submit" loading={createIntentLoading}>{t("Submit")}</Button>
                    </Group>
                </form>
            </Modal>
            {selectedIntent != null &&
                <ProjectIntentDrawer
                    intent={selectedIntent}
                    opened={true}
                    onClose={() => setSelectedIntent(null)}
                    onDelete={onDeleteIntent}
                    onUtteranceAdd={onUtteranceAdd}
                    onUtteranceDelete={onUtteranceDelete}
                    deleteLoading={deleteLoading}
                />
            }
        </>
    )
}

export default ProjectIntents
