//
//
//  Settings Layout
//
//

import {useLocation} from "react-router-dom";
import {NavbarSection, NavbarSectionItem} from "../interfaces.ts";
import {IconAffiliate, IconCreditCard, IconSettings} from "@tabler/icons-react";
import {AppShell, Avatar, Container, Flex, Text, Title} from "@mantine/core";
import LoggedHeader from "../components/LoggedHeader.tsx";
import classes from "./SettingsLayout.module.css"
import NavbarLink from "../components/NavbarLink.tsx";
import {useUser} from "../contexts/AuthContext.tsx";
import {ReactNode} from "react";
import { useTranslation } from "react-i18next";


function SettingsLayout({children}: {children: ReactNode}) {
    const { t } = useTranslation()
    const {pathname} = useLocation()
    const {user} = useUser()

    const pathparts = /\/settings\/(\w*)\/?/.exec(pathname)
    let active: string
    if (!pathparts) {
        active = "account"
    } else {
        active = pathparts[1]
    }

    const sections: NavbarSection[] = [
        {
            label: undefined,
            items: [
                { icon: <IconSettings size={16} />, color: 'teal', label: t("Account"), to: "/settings/account", active: "account"},
                { icon: <IconCreditCard size={16} />, color: 'blue', label: t("Billing"), to: "/settings/billing", active: "billing"},
                { icon: <IconAffiliate size={16}/>, color: "orange", label: t("Teams"), to: "/settings/teams", active: "teams"}
            ]
        }
    ]

    return (
        <AppShell
            header={{height: 60}}
            navbar={{width: 300, breakpoint: 0}}
        >
            <AppShell.Header>
                <LoggedHeader/>
            </AppShell.Header>
            <AppShell.Navbar>
                <div className={classes.header}>
                    <Avatar src={user?.avatar} alt="Avatar" radius="xl" />
                    <Flex direction="column">
                        <Title size="h4">{user?.name}</Title>
                        <Title size="h7" c="dimmed">{user?.email}</Title>
                    </Flex>
                </div>
                <Flex direction="column" mt="md" className={classes.navbarMainSection}>
                    {sections.map((section: NavbarSection, sectionIndex: number) => {
                        return (
                            <Flex direction="column" key={sectionIndex}>
                                {section.label != null &&
                                    <Text size="xs" w={500} c="dimmed" className={classes.navbarSectionHeader}>
                                        {section.label}
                                    </Text>
                                }
                                {section.items.map((item: NavbarSectionItem, itemIndex: number) => {
                                    return (
                                        <NavbarLink {...item} active={active === item.active} key={itemIndex}/>
                                    )
                                })}
                            </Flex>
                        )
                    })}
                </Flex>
            </AppShell.Navbar>
            <AppShell.Main className={classes.main}>
                <Container className={classes.container}>
                    {children}
                </Container>
            </AppShell.Main>
        </AppShell>
    )
}

export default SettingsLayout
