//
//
//  Project Builds Data Table
//
//

import {DataTableEmpty} from "./DataTable.tsx";
import classes from "./DataTable.module.css";
import {Badge, Flex, Text, Title} from "@mantine/core";
import { DataTable } from "mantine-datatable";
import {Build} from "../interfaces.ts";
import {IconCircleCheck, IconCurrentLocation, IconRocket} from "@tabler/icons-react";
import {useAutoAnimate} from "@formkit/auto-animate/react";
import { useTranslation } from "react-i18next";
import TimeAgoLocale from "./TimeAgoLocale.tsx";



function ProjectBuildsDataTable({builds, loading, onBuild, buildLoading}: {builds: Build[], loading: boolean, onBuild: () => void, buildLoading: boolean}) {
    const { t, i18n } = useTranslation()
    const [parent] = useAutoAnimate()

    const colorByStatus: Record<string, string> = {
        "PENDING": "gray",
        "TRAINING": "indigo",
        "DEPLOYING": "grape",
        "DONE": "teal",
        "ERROR": "red"
    }

    function renderBuildDetails(build: Build) {

        const isNew = (build as any)._isNew == true
        const itemStyle = isNew ? { backgroundColor: "#F5FBEF", marginBottom:'1rem'} : {marginBottom:'1rem'};

        return (
            <div className={classes.row} key={build.id} style={itemStyle}>
                <div className={classes.rowContent}>
                    <Flex align="center" gap={12}>
                        <Title size="h4" className={classes.rowTitle}>
                            {build.name}
                        </Title>
                        <Badge color={colorByStatus[build.status]}>
                            <span style={{textTransform: "capitalize"}}>{t(build.status)}</span>
                        </Badge>
                        {build.current &&
                            <Badge
                               color={colorByStatus[build.status]}
                               variant="gradient"
                               gradient={{ from: 'teal', to: 'lime', deg: 90 }}
                               leftSection={<IconCurrentLocation size={12}/>}
                            >
                               {t("Current")}
                            </Badge>
                        }
                    </Flex>
                    <Flex mt={4} mb={4} gap={10}>
                        {build.capabilities_chat &&
                            <Badge variant="outline" color="cyan">Chat</Badge>
                        }
                        {build.capabilities_videochat &&
                            <Badge variant="outline" color="pink">VideoChat</Badge>
                        }
                    </Flex>
                    <Flex className={classes.rowFooter}>
                        {build.ended_at
                            ? <Flex align="center" columnGap={6}>
                                <IconCircleCheck size={14} color={"gray"}/>
                                <Text c="dimmed" size="sm">
                                    <span style={{fontWeight: "bold", marginRight: 6}}>{t("Ended")}:</span>
                                    <TimeAgoLocale date={new Date(build.ended_at)} language={i18n.language}/>
                                </Text>
                            </Flex>
                            : <Flex align="center" columnGap={6}>
                                <IconRocket size={14} color={"gray"}/>
                                <Text c="dimmed" size="sm">
                                    <span style={{fontWeight: "bold", marginRight: 6}}>{t("Created")}:</span>
                                    <TimeAgoLocale date={new Date(build.created_at)} language={i18n.language}/>
                                </Text>
                            </Flex>
                        }
                    </Flex>
                </div>
            </div>
        )
    }

    return (
        <div className={classes.wrapper} ref={parent}>
            <DataTable
                noHeader
                withRowBorders={false}
                borderColor={'white'}
                rowClassName={classes.rowTitle}
                fetching={loading}
                columns={[{ accessor: 'projects', render: renderBuildDetails }]}
                records={builds}
                verticalSpacing=""
                emptyState={
                    builds.length === 0 && (
                    <div style={{ pointerEvents: 'all', width:'100%'}}>
                    <DataTableEmpty
                        title={t("You don't have any build")}
                        subtitle={t("Let's get started") + " 🚀"}
                        action={t("Build your first Project")}
                        onAction={onBuild}
                        actionLoading={buildLoading}
                    />
                    </div>)
                }
                minHeight={300}
                defaultColumnProps={{
                    noWrap: true,
                    ellipsis: true,
                }}
            />
        </div>
    )
}

export default ProjectBuildsDataTable
