//
//
//  Project List
//
//

import {useEffect, useRef, useState} from "react";
import {
    Container,
    Flex,
    rem,
    Title,
    Spoiler
} from "@mantine/core";
import {useNavigate} from "react-router-dom"
import Api from "../api.ts"
import LoggedHeader from "../components/LoggedHeader.tsx";
import ProjectsDataTable from "../components/ProjectsDataTable.tsx";
import {Project} from "../interfaces.ts";
import {useSocket} from "../contexts/SocketContext.tsx";
import {onResourceDelete, onResourceUpdate, sortByUpdatedAt} from "../utils.ts";
import PaginationControl from "../components/PaginationControl.tsx";
import { useApiErrorHandler } from "../hooks.ts";
import { useTranslation } from "react-i18next";


function ProjectListExplore() {
    const pageLength = 5
    const { t } = useTranslation()
    const handleError = useApiErrorHandler()
    const navigate = useNavigate()
    const {socket} = useSocket()
    const projectsRef = useRef<Project[]>([])
    const [projects, setProjects] = useState<Project[]>([])
    const [loading, setLoading] = useState(true)
    const [totalProjects, setTotalProjects] = useState(0)
    const [page, setPage] = useState(1)

    function loadProjectsPage() {
        setLoading(true)
        Api.getExploreProjects(page, pageLength)
            .then(pageProjects => {
                projectsRef.current = pageProjects["items"]
                setProjects(sortByUpdatedAt(projectsRef.current))
                setTotalProjects(pageProjects["total"])
                setLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoading(false)
            })
    }

    useEffect(() => {
        loadProjectsPage()
    }, [page])

    useEffect(() => {
        function onProjectAdd(added: Project) {
            const newProject = {...added, _isNew: true}
            projectsRef.current = [newProject, ...projectsRef.current]
            setProjects(projectsRef.current)
        }

        function onProjectUpdate(updated: Project) {
            projectsRef.current = onResourceUpdate(updated, projectsRef.current)
            setPage(1)
            loadProjectsPage()
        }

        function onProjectDelete(deleted: Project) {
            projectsRef.current = onResourceDelete(deleted, projectsRef.current)
            setProjects(projectsRef.current)
        }

        socket.on("project:add", onProjectAdd)
        socket.on("project:update", onProjectUpdate)
        socket.on("project:delete", onProjectDelete)

        return () => {
            socket.off("project:add", onProjectAdd)
            socket.off("project:update", onProjectUpdate)
            socket.off("project:delete", onProjectDelete)
        }
    }, [socket])

    return (
        <>
            <header style={{height: 60}}>
                <LoggedHeader/>
            </header>
            <main>
                <Container>
                    <Flex
                        justify="space-between"
                        align="center"
                        direction="row"
                        mt={rem(50)}
                        mb={rem(30)}
                    >

                    <>
                    <Title size="h1">{t("Explore Projects")}</Title>
                    </>
                        
                    </Flex>
                    <Spoiler mb="xl" maxHeight={50} showLabel={t("Show more")} hideLabel={t("Hide")}>
                        {t("This is a centralized view that presents users with an organized list of available conversational assistant projects. This user interface serves as a control center, offering an overview of each project's status, allowing seamless navigation, and providing quick access to essential components.")}
                        <br/>
                        {t("Users can click on a project entry to access detailed project information or interact with project components.")}
                    </Spoiler>
                    <ProjectsDataTable
                        projects={projects}
                        loading={loading}
                        onCreate={() => navigate("/projects/create")}
                        explore={true}
                    />
                    <PaginationControl 
                        totalElements={totalProjects}
                        page={page}
                        pageLength={pageLength}
                        onChange={setPage}
                    />
                </Container>
            </main>
        </>
    )
}

export default ProjectListExplore
