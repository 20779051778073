//
//
//  Project Test Share
//
//

import {Flex, rem, Title, Button, Spoiler, Container} from "@mantine/core";
import {IconCirclePlus} from "@tabler/icons-react";
import SharedLinksDataTable from "../components/SharedLinksDataTable.tsx";
import {Project, SharedLink} from "../interfaces.ts";
import {useEffect, useRef, useState} from "react";
import Api from "../api.ts";
import {useOutletContext} from "react-router-dom";
import {onResourceUpdate, sortByCreatedAt} from "../utils.ts";
import {Socket} from "socket.io-client";
import {useSocket} from "../contexts/SocketContext.tsx";
import ProjectBuildRetriever from "../components/ProjectBuildRetriever.tsx";
import PaginationControl from "../components/PaginationControl.tsx";
import { useApiErrorHandler } from "../hooks.ts";
import { useTranslation } from "react-i18next";


function ProjectShare() {
    const { t } = useTranslation()
    const pageLength = 10
    const handleError = useApiErrorHandler()
    const {socket}: {socket: Socket} = useSocket()
    const [project]: [Project] = useOutletContext()
    const [loading, setLoading] = useState(true)
    const linksRef = useRef<SharedLink[]>([])
    const [links, setLinks] = useState<SharedLink[]>([])
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [shareLoading, setShareLoading] = useState(false)
    const [totalLinks, setTotalLinks] = useState(0)
    const [page, setPage] = useState(1)

    function loadLinksPage() {
        setLoading(true)
        Api.getSharedLinks(project.id, page, pageLength)
            .then(pageLinks => {
                if (pageLinks["items"].length < 1 && page > 1) {
                    setTotalLinks(totalLinks-1)
                } else {
                    linksRef.current = pageLinks["items"]
                    setLinks(sortByCreatedAt(linksRef.current))
                    setTotalLinks(pageLinks["total"])
                    setLoading(false)
                }
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoading(false)
            })
    }

    useEffect(() => {
        loadLinksPage()
    }, [project.id, page])

    useEffect(() => {
        function onAdd(added: SharedLink) {
                    const newLink = {...added, _isNew: true}
                    linksRef.current = [newLink, ...linksRef.current]
                    setLinks(linksRef.current)
        }

        function onUpdate(updated: SharedLink) {
            if (updated.project.id === project.id) {
                linksRef.current = onResourceUpdate(updated, linksRef.current)
                setLinks(linksRef.current)
            }
        }

        function onDelete(deleted: SharedLink) {
            if (deleted.project.id === project.id) {
                loadLinksPage()
            }
        }

        socket.on("shared_link:add", onAdd)
        socket.on("shared_link:update", onUpdate)
        socket.on("shared_link:delete", onDelete)

        return () => {
            socket.off("shared_link:add", onAdd)
            socket.off("shared_link:update", onUpdate)
            socket.off("shared_link:delete", onDelete)
        }
    }, [project.id, socket, page])

    function onCreate() {
        setShareLoading(true)
        Api.createSharedLink(project.id)
            .then(() => {
                setShareLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setShareLoading(false)
            })
    }

    function onDelete(link: SharedLink) {
        setDeleteLoading(true)
        Api.deleteSharedLink(link.token)
            .then(() => {
                setDeleteLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setDeleteLoading(false)
            })
    }

    return (
        <>
            <Container>
                <Flex
                    justify="space-between"
                    align="center"
                    direction="row"
                    mt={rem(50)}
                    mb={rem(30)}
                >
                    <Title size="h1">{t("Share")}</Title>
                    <Button
                        onClick={onCreate}
                        loading={shareLoading}
                        disabled={project.current_build == null}
                        leftSection={<IconCirclePlus size={16}/>}
                    >
                        {t("Share")}
                    </Button>
                </Flex>
                <Spoiler mb="xl" maxHeight={50} showLabel={t("Show more")} hideLabel={t("Hide")}>
                    {t("In the realm of conversational assistant development, the share feature serves as a collaborative tool, allowing developers to distribute access to their trained conversational assistant models easily. This section provides a mechanism for generating shareable links, enabling others to interact with and test the conversational assistant without requiring access to the entire development environment.")}
                </Spoiler>
                <ProjectBuildRetriever project={project}>
                    <SharedLinksDataTable
                        links={links}
                        onCreate={onCreate}
                        onDelete={onDelete}
                        loading={loading}
                        deleteLoading={deleteLoading}
                    />
                </ProjectBuildRetriever>
                <PaginationControl 
                    totalElements={totalLinks}
                    page={page}
                    pageLength={pageLength}
                    onChange={setPage}
                />
            </Container>
        </>
    )
}

export default ProjectShare
