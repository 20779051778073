//
//
//  Password Reset
//
//

import {useNavigate, useSearchParams} from "react-router-dom";
import {
    Button,
    Container,
    Flex,
    Group,
    Paper,
    PasswordInput,
    Stack,
    Text,
    Title
} from "@mantine/core";
import {useDisclosure} from "@mantine/hooks";
import {hasLength, matchesField, useForm} from "@mantine/form";
import MAIALogo from "../components/MAIALogo.tsx";
import {useState} from "react";
import Api, {RequestError} from "../api.ts";
import {notifications} from "@mantine/notifications";
import { useTranslation } from "react-i18next";

function PasswordReset() {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [visible, { toggle }] = useDisclosure(false)
    const [searchParams] = useSearchParams()
    const form = useForm({
        initialValues: {
            password: "",
            confirm_password: ""
        },
        validate: {
            password: hasLength({ min: 8 }, t('Must have 8 or more characters')),
            confirm_password: matchesField('password', t('Passwords are not the same')),
        }
    })

    const token = searchParams.get("token")

    if (token == null) {
        navigate("/forgot-password")
        return
    }

    function onSubmit(values: any) {
        setLoading(true)
        Api.resetPassword(values["password"], token!)
            .then(() => {
                setLoading(false)
                navigate("/login")
            }).catch(err => {
                if (err instanceof RequestError && err.response.status == 409) {
                    setLoading(false)
                    notifications.show({
                        title: t("Invalid token"),
                        message: t("Please check your reset token and make sure it has not expired."),
                        color: "red",
                    })
                }
        })
    }

    return (
        <Container size={460} my={30}>
            <Flex justify="center" style={{height: "100px"}} direction="column" align="center">
                <MAIALogo/>
            </Flex>

            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                <Title ta="center" size="h3" mb="xs">{t("Forgot your password?")}</Title>
                <Text c="dimmed" fz="sm" ta="center" mb="md">
                    {t("Enter your email to get a reset link")}
                </Text>
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <Stack>
                        <PasswordInput
                            label={t("Password")}
                            visible={visible}
                            onVisibilityChange={toggle}
                            {...form.getInputProps("password")}
                        />
                        <PasswordInput
                            label={t("Confirm password")}
                            visible={visible}
                            onVisibilityChange={toggle}
                            {...form.getInputProps("confirm_password")}
                        />
                    </Stack>
                    <Group justify="space-between" mt="lg">
                        <Button loading={loading} type="submit">{t("Reset password")}</Button>
                    </Group>
                </form>
            </Paper>
        </Container>
    )
}

export default PasswordReset
