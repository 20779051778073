//
//
//  ProjectConversationsDataTable
//
//

import classes from "./DataTable.module.css"
import {Conversation} from "../interfaces.ts";
import {useAutoAnimate} from "@formkit/auto-animate/react";
import {DataTableEmpty} from "./DataTable.tsx";
import { DataTable } from "mantine-datatable";
import {Anchor, Badge, Flex, Text, Title} from "@mantine/core";
import {IconEdit, IconQuote} from "@tabler/icons-react";
import TimeAgoLocale from "./TimeAgoLocale.tsx";
import { useTranslation } from "react-i18next";

function ProjectConversationsDataTable({conversations, loading, onSelection}: {conversations: Conversation[], loading: boolean, onSelection: (conversation: Conversation) => void}) {
    const { t, i18n } = useTranslation();
    const [bodyRef] = useAutoAnimate<HTMLTableSectionElement>();

    function renderConversationDetails(conversation: Conversation) {
        let badge
        if (conversation.channel === "CHAT") {
            badge = <Badge variant="outline" color="cyan">Chat</Badge>
        } else if (conversation.channel === "VIDEOCHAT") {
            badge = <Badge variant="outline" color="pink">VideoChat</Badge>
        } else if (conversation.channel === "ALEXA") {
            badge = <Badge variant="outline" color="lime">Alexa</Badge>
        } else if (conversation.channel === "PHONE") {
            badge = <Badge variant="outline" color="indigo">{t("Phone")}</Badge>
        } else if (conversation.channel === "TELEGRAM") {
            badge = <Badge variant="outline" color="orange">Telegram</Badge>
        }

        const isNew = (conversation as any)._isNew == true
        const itemStyle = isNew ? { backgroundColor: "#F5FBEF", marginBottom:'1rem'} : {marginBottom:'1rem'};

        return (
            <div className={classes.row}  key={conversation.id}  style={itemStyle}>
                <div className={classes.rowContent}>
                    <Anchor onClick={() => onSelection(conversation)}>
                        <Title size="h3" className={classes.rowTitle}>
                            {conversation.id_chat}
                        </Title>
                    </Anchor>
                    {badge != null && <Flex mt={4} mb={4} gap={10}>
                        {badge}
                    </Flex>}
                    <Flex className={classes.rowFooter}>
                        <Flex align="center" columnGap={6}>
                            <IconQuote size={12} color={"gray"}/>
                        <Text c="dimmed" size="xs">
                                {conversation.messages.length} {conversation.messages.length == 1 ? "message" : "messages"}
                            </Text>
                        </Flex>
                        <Flex align="center" columnGap={6}>
                            <IconEdit size={12} color={"gray"}/>
                            <Text c="dimmed" size="xs">
                                <TimeAgoLocale date={new Date(conversation.created_at)} language={i18n.language}/>
                            </Text>
                        </Flex>
                    </Flex>
                </div>
            </div>
        )
    }
    return (
        <DataTable
            noHeader
            withRowBorders={false}
            borderColor={'white'}
            rowClassName={classes.rowTitle}
            fetching={loading}
            columns={[{ accessor: 'conversations', render: renderConversationDetails }]}
            records={conversations}
            bodyRef={bodyRef}
            verticalSpacing=""
            emptyState={
                conversations.length === 0 && (
                <div style={{ pointerEvents: 'all', width:'100%'}}>
                <DataTableEmpty
                    title={t("You don't have any conversation")}
                    subtitle={t("Open the Playground or share a link to get started") + " 🚀"}
                />
                </div>)
            }
            minHeight={300}
            defaultColumnProps={{
                noWrap: true,
                ellipsis: true,
            }}
        />
    )
}

export default ProjectConversationsDataTable
