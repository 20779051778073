//
//
//   Admin Layout
//
//

import {AppShell, Container, Flex, Text, Title} from "@mantine/core";
import LoggedHeader from "../components/LoggedHeader.tsx";
import {ReactNode} from "react";
import classes from "./AdminLayout.module.css"
import {IconHome2, IconUsersGroup, IconNotebook} from "@tabler/icons-react";
import NavbarLink from "../components/NavbarLink.tsx";
import {NavbarSection, NavbarSectionItem} from "../interfaces.ts";
import {useLocation} from "react-router-dom";
import { useTranslation } from "react-i18next";

function AdminLayout({children}: {children: ReactNode}) {
    const { t } = useTranslation()
    const {pathname} = useLocation()

    const pathparts = /\/admin\/(\w*)\/?/.exec(pathname)
    let active: string
    if (!pathparts) {
        active = "home"
    } else {
        active = pathparts[1]
    }

    const sections: NavbarSection[] = [
        {
            label: undefined,
            items: [
                { icon: <IconHome2 size={16} />, color: 'teal', label: t("Home"), to: "/admin", active: "home"},
                { icon: <IconUsersGroup size={16} />, color: 'blue', label: t("Users"), to: "/admin/users", active: "users"},
                { icon: <IconNotebook size={16} />, color: 'red', label: t("Projects"), to: "/admin/projects", active: "projects"},
            ]
        }
    ]
    return (
        <AppShell
            header={{height: 60}}
            navbar={{width: 300, breakpoint: 0}}
        >
            <AppShell.Header>
                <LoggedHeader/>
            </AppShell.Header>
            <AppShell.Navbar>
                <div className={classes.header}>
                    <Title order={1}>{t("Admin")}</Title>
                </div>
                <Flex direction="column" mt="md" className={classes.navbarMainSection}>
                    {sections.map((section: NavbarSection, sectionIndex: number) => {
                        return (
                            <Flex direction="column" key={sectionIndex}>
                                {section.label != null &&
                                    <Text size="xs" w={500} c="dimmed" className={classes.navbarSectionHeader}>
                                        {section.label}
                                    </Text>
                                }
                                {section.items.map((item: NavbarSectionItem, itemIndex: number) => {
                                    return (
                                        <NavbarLink {...item} active={active === item.active} key={itemIndex}/>
                                    )
                                })}
                            </Flex>
                        )
                    })}
                </Flex>
            </AppShell.Navbar>
            <AppShell.Main className={classes.main}>
                <Container className={classes.container}>
                    {children}
                </Container>
            </AppShell.Main>
        </AppShell>
    )
}

export default AdminLayout
