//
//
//  Intents Data Table
//
//


import {Intent} from "../interfaces.ts";
import classes from "./DataTable.module.css"
import {Anchor, Flex, Text, Title} from "@mantine/core";
import {DataTableEmpty} from "./DataTable.tsx";
import { DataTable } from "mantine-datatable";
import {IconEdit, IconQuote} from "@tabler/icons-react";
import {useAutoAnimate} from "@formkit/auto-animate/react";
import { useTranslation } from "react-i18next";
import TimeAgoLocale from "./TimeAgoLocale.tsx";


function ProjectIntentsDataTable({intents, loading, onCreate, onSelection}: {intents: Intent[], loading: boolean, onCreate: () => void, onSelection: (intent: Intent) => void}) {
    const { t, i18n } = useTranslation();
    const [bodyRef] = useAutoAnimate<HTMLTableSectionElement>();

    function renderIntentDetails(intent: Intent) {

        const isNewIntent = (intent as any)._isNew == true
        const itemStyle = isNewIntent ? { backgroundColor: "#F5FBEF", marginBottom:'1rem'} : {marginBottom:'1rem'};

        return (
            <div className={classes.row} key={intent.id} style={itemStyle}>
                <div className={classes.rowContent}>
                    <Anchor onClick={() => onSelection(intent)}>
                        <Title size="h3" className={classes.rowTitle}>
                            {intent.name}
                        </Title>
                    </Anchor>
                    <Flex className={classes.rowFooter}>
                        <Flex align="center" columnGap={6}>
                            <IconQuote size={12} color={"gray"}/>
                            <Text c="dimmed" size="xs">
                                {intent.utterances} {intent.utterances == 1 ? t("sentence") : t("sentences")}
                            </Text>
                        </Flex>
                        <Flex align="center" columnGap={6}>
                            <IconEdit size={12} color={"gray"}/>
                            <Text c="dimmed" size="xs">
                                <TimeAgoLocale date={new Date(intent.updated_at)} language={i18n.language}/>
                            </Text>
                        </Flex>
                    </Flex>
                </div>
            </div>
        );
    }

    return (
        <DataTable
            noHeader
            withRowBorders={false}
            borderColor={'white'}
            rowClassName={classes.rowTitle}
            fetching={loading}
            verticalSpacing=""
            bodyRef={bodyRef}
            style={{width:'100%'}}
            columns={[{ accessor: 'intents', render: renderIntentDetails }]}
            records={intents}
            emptyState={
                intents.length === 0 && (
                <div style={{ pointerEvents: 'all', width:'100%'}}>
                <DataTableEmpty
                    title={t("You don't have any topic")}
                    subtitle={t("Let's get started") + " 🚀"}
                    action={t("Create your first Topic (Intent)")}
                    onAction={onCreate}
                />
                </div>)
            }
            minHeight={300}
            defaultColumnProps={{
                noWrap: true,
                ellipsis: true,
            }}
        />
    )
}

export default ProjectIntentsDataTable




