//
//
//  Project
//
//

import {Flex, List, rem, ThemeIcon, Title, Paper, SimpleGrid, LoadingOverlay, Spoiler, Text} from "@mantine/core";
import {
    IconAlertCircle,
    IconChartDots3,
    IconCircleCheck,
    IconCircleDashed, IconHelpCircle,
    IconMessage2,
    IconRocket,
    IconTargetArrow
} from "@tabler/icons-react";
import classes from "./Home.module.css"
import {useEffect, useRef, useState} from "react";
import {Build, CustomProject, Intent, CustomProjectInfo, Response, Story} from "../../interfaces.ts";
import {Link} from "react-router-dom";
import Api from "../../api.ts";
import {Socket} from "socket.io-client";
import {useSocket} from "../../contexts/SocketContext.tsx";
import { useApiErrorHandler } from "../../hooks.ts";
import { useTranslation } from "react-i18next";

function CustomProjectHome({project, explore}: {project: CustomProject, explore: boolean}) {
    const handleError = useApiErrorHandler()
    const { t } = useTranslation();
    const {socket}: {socket: Socket} = useSocket()
    const [loading, setLoading] = useState(true)
    const [atLeast2Intents, setAtLeast2Intents] = useState<boolean>(false)
    const [addFallbackEvent, setAddFallbackEvent] = useState<boolean>(false)
    const [addWelcomeEvent, setAddWelcomeEvent] = useState<boolean>(false)
    const [createYourFirstSuccessfulBuild, setCreateYourFirstSuccessfulBuild] = useState<boolean>(false)
    const [numIntents, setNumIntents] = useState<number>(NaN)
    const [numResponses, setNumResponses] = useState<number>(NaN)
    const [numStories, setNumStories] = useState<number>(NaN)
    const [numBuilds, setNumBuilds] = useState<number>(NaN)
    const numIntentsRef = useRef<number>(NaN)
    const numResponsesRef = useRef<number>(NaN)
    const numStoriesRef = useRef<number>(NaN)
    const numBuildsRef = useRef<number>(NaN)

    useEffect(() => {
        Api.getInfoProject(project.id)
            .then((info: CustomProjectInfo) => {
                setNumIntents(info.intents.total)
                setNumResponses(info.responses.total)
                setNumStories(info.stories.total)
                setNumBuilds(info.builds.total)

                numIntentsRef.current = info.intents.total
                numResponsesRef.current = info.responses.total
                numStoriesRef.current = info.stories.total
                numBuildsRef.current = info.builds.total

                setAtLeast2Intents(info.intents.total >= 2)
                setAddFallbackEvent(info.stories.system.SYSTEM_FALLBACK)
                setAddWelcomeEvent(info.stories.system.SYSTEM_WELCOME)
                setCreateYourFirstSuccessfulBuild(info.builds.success > 0)

                setLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoading(false)
            })
    }, [project.id])

    useEffect(() => {
        function onIntentAdd(added: Intent) {
            if (added.project.id === project.id) {
                numIntentsRef.current += 1
                setNumIntents(numIntentsRef.current)
            }
        }

        function onResponseAdd(added: Response) {
            if (added.project.id === project.id) {
                numResponsesRef.current += 1
                setNumResponses(numResponsesRef.current)
            }
        }

        function onStoryAdd(added: Story) {
            if (added.project.id === project.id) {
                numStoriesRef.current += 1
                setNumStories(numStoriesRef.current)
            }
        }

        function onBuildAdd(added: Build) {
            if (added.project.id === project.id) {
                numBuildsRef.current += 1
                setNumBuilds(numBuildsRef.current)
            }
        }

        function onIntentDelete(deleted: Intent) {
            if (deleted.project.id === project.id) {
                numIntentsRef.current -= 1
                setNumIntents(numIntentsRef.current)

            }
        }

        function onResponseDelete(deleted: Response) {
            if (deleted.project.id === project.id) {
                numResponsesRef.current -= 1
                setNumResponses(numResponsesRef.current)

            }
        }

        function onStoryDelete(deleted: Story) {
            if (deleted.project.id === project.id) {
                numStoriesRef.current -= 1
                setNumStories(numStoriesRef.current)

            }
        }

        function onBuildDelete(deleted: Build) {
            if (deleted.project.id === project.id) {
                numBuildsRef.current -= 1
                setNumBuilds(numBuildsRef.current)

            }
        }

        // noinspection DuplicatedCode
        socket.on("intent:add", onIntentAdd)
        socket.on("response:add", onResponseAdd)
        socket.on("story:add", onStoryAdd)
        socket.on("build:add", onBuildAdd)

        socket.on("intent:delete", onIntentDelete)
        socket.on("response:delete", onResponseDelete)
        socket.on("story:delete", onStoryDelete)
        socket.on("build:delete", onBuildDelete)

        return () => {
            socket.off("intent:add", onIntentAdd)
            socket.off("response:add", onResponseAdd)
            socket.off("story:add", onStoryAdd)
            socket.off("build:add", onBuildAdd)

            socket.off("intent:delete", onIntentDelete)
            socket.off("response:delete", onResponseDelete)
            socket.off("story:delete", onStoryDelete)
            socket.off("build:delete", onBuildDelete)
        }
    }, [project.id, socket])

    const stats = [
        {icon: IconTargetArrow, color: "blue", label: t("Topics"), value: numIntents, to: "intents"},
        {icon: IconMessage2, color: "red", label: t("Responses"), value: numResponses, to: "responses"},
        {icon: IconChartDots3, color: 'violet', label: t("Stories"), value: numStories, to: "stories"},
        {icon: IconRocket, color: 'orange', label: t("Builds"), value: numBuilds, to: "builds"},
    ]

    const firstSteps = [
        {label: t("Add at least 2 topics"), status: atLeast2Intents, required: true},
        {label: t("Add Fallback story"), status: addFallbackEvent, required: true},
        {label: t("Add Welcome story"), status: addWelcomeEvent, required: true},
        {label: t("Create your first successful build"), status: createYourFirstSuccessfulBuild, required: true},
    ]

    if (loading) {
        return (
            <LoadingOverlay visible loaderProps={{size: "xl", variant: "oval"}}/>
        )
    }

    return (
        <>
            <Flex
                justify="space-between"
                align="center"
                direction="row"
                mt={rem(50)}
                mb={rem(30)}
            >
                <Title size="h1">{t("Home")}</Title>
            </Flex>
            <Spoiler mb="xl" maxHeight={50} showLabel={t("Show more")} hideLabel={t("Hide")}>
                {t("In the realm of conversational assistant development, a project serves as a consolidated and organized unit that brings together various components essential for creating, training, testing, and deploying conversational assistant models. It encompasses a cohesive ensemble of topics, responses, builds, stories, playgrounds, deployments, and share features, providing a centralized hub for developing and managing the conversational assistant's lifecycle.")}
                <br/>
                <Text mt={12} mb={4} fw={600}>{t("Example")}:</Text>
                <ol>
                    <li>
                        <Text component="span" fw={500}>{t("Create a Project")}</Text>: {t("Developers initiate a new project, defining topics, responses, and stories.")}
                    </li>
                    <li>
                        <Text component="span" fw={500}>{t("Training Builds")}</Text>: {t("Builds are created and refined through iterative training cycles.")}
                    </li>
                    <li>
                        <Text component="span" fw={500}>{t("Interactive Testing")}</Text>: {t("The playground within the project is used for interactive testing and fine-tuning.")}
                    </li>
                    <li>
                        <Text component="span" fw={500}>{t("External Testing")}</Text>: {t("The deployment section is utilized to test the conversational assistant on external devices.")}
                    </li>
                    <li>
                        <Text component="span" fw={500}>{t("Collaboration")}</Text>: {t("The share feature is employed to share the conversational assistant with stakeholders for collaborative testing and feedback.")}
                    </li>
                </ol>
                {t("A project in conversational assistant development acts as a central hub, streamlining the development process and providing a comprehensive framework for managing the various components critical to a conversational assistant's functionality.")}
            </Spoiler>
            <div className={classes.wrapper}>
                <main className={classes.main}>
                    <SimpleGrid cols={2} spacing="xl" verticalSpacing="xl">
                        {stats.map((stat, index) => {
                            if (!explore) {
                                return (
                                    <Paper component={Link} to={stat.to} key={index} className={classes.stat} radius="md" shadow="md" p="xs">
                                        <Flex justify="center" direction="column" align="center" gap={10}>
                                            <stat.icon
                                                size={32}
                                                color={stat.color}
                                                className={classes.icon}
                                                stroke={1.5}
                                            />
                                            <div className={classes.statText}>
                                                <p className={classes.label}>{stat.label}</p>
                                                <p className={classes.value}>{stat.value}</p>
                                            </div>
                                        </Flex>
                                    </Paper>
                                )
                            } else {
                                return (
                                    <Paper key={index} className={classes.stat} radius="md" shadow="md" p="xs">
                                        <Flex justify="center" direction="column" align="center" gap={10}>
                                            <stat.icon
                                                size={32}
                                                color={stat.color}
                                                className={classes.icon}
                                                stroke={1.5}
                                            />
                                            <div className={classes.statText}>
                                                <p className={classes.label}>{stat.label}</p>
                                                <p className={classes.value}>{stat.value}</p>
                                            </div>
                                        </Flex>
                                    </Paper>
                                )
                            }
                            
                        })}
                    </SimpleGrid>
                </main>
                { !explore &&
                    <aside className={classes.aside}>
                        <Title size="h3" mb="md">{t("First steps")}</Title>
                        <List
                            spacing="lg"
                            size="sm"
                            center
                        >
                            {firstSteps.map((step, index) => {
                                let icon, color
                                if (step.status) {
                                    icon = <IconCircleCheck style={{ width: '100%', height: '70%' }} radius="xl"/>
                                    color = "green"
                                } else if (!step.status && !step.required) {
                                    icon = <IconHelpCircle style={{ width: '100%', height: '70%' }} radius="xl"/>
                                    color = "gray"
                                } else if (!step.status && step.required) {
                                    icon = <IconAlertCircle style={{ width: '100%', height: '70%' }} radius="xl"/>
                                    color = "red"
                                } else {
                                    icon = <IconCircleDashed style={{ width: '100%', height: '70%' }} radius="xl"/>
                                    color = "blue"
                                }
                                return (
                                    <List.Item
                                        key={index}
                                        icon={
                                            <ThemeIcon color={color} size={28} radius="xl">
                                                {icon}
                                            </ThemeIcon>
                                        }
                                    >
                                        {step.label}
                                    </List.Item>
                                )
                            })}
                        </List>
                    </aside>
                }
            </div>
        </>
    );
}

export default CustomProjectHome
