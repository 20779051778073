//
//
//  HelpAssistantModal
//
//

import { useState, useEffect } from 'react';
import classes from "./HelpAssistantChatWrapper.module.css"
import { Dialog, Group, Button, Flex, Title, Text, Popover } from '@mantine/core'
import HelpAssistantChat from './HelpAssistantChat.tsx';
import { HelpRequest } from '../interfaces.ts';
import { CloseButton } from '@mantine/core';
import { useTranslation } from "react-i18next";



function HelpAssistantChatWrapper({ opened, onClose }: { opened: boolean, onClose: () => void }) {
    const { t } = useTranslation()
    const [chatMessages, setChatMessages] = useState<any[]>([]);
    const [chatContext, setChatContext] = useState<HelpRequest[]>([]);
    const [restartSessionModalOpened, setRestartSessionModalOpened] = useState(false);

    const initialMessage = {
        "isUser": false,
        "message": {
            "text": t("Welcome to Maia Studio Help Assistant! I'm here to assist you. Feel free to ask me any questions related to MAIA Studio, and I'll provide you with the information you need. You can ask me in any language. Go ahead, ask away!"),
            "datetime": new Date()
        }
    }

    useEffect(() => {
        setChatMessages([initialMessage])
    }, [])

    const handleClose = () => {

        setRestartSessionModalOpened(true)
    }

    const clearSession = () => {
        setChatMessages([initialMessage])
        setRestartSessionModalOpened(false)
        setChatContext([])
        onClose()

    }

    return (
        <Dialog title={t("MAIA Studio Help Assistant")} opened={opened} size="lg" position={{ bottom: 70, right: 20 }} className={classes.headerWrapper} transitionProps={{transition: 'slide-up'}} onClose={handleClose}>
            <Popover width={365} radius={'sm'} onPositionChange={() => setRestartSessionModalOpened(false)} position="bottom-end" arrowPosition={'center'} arrowSize={8} opened={restartSessionModalOpened} withArrow shadow="md" offset={3}>
            <Flex justify={'space-between'} align={'center'} style={{ paddingBottom:'1rem'}}>
                <Title size={'h3'}>{t("MAIA Studio Help Assistant")}</Title>
                <Popover.Target>
                <CloseButton onClick={() => setRestartSessionModalOpened(!restartSessionModalOpened)}/>
                </Popover.Target>
            </Flex>
            <Popover.Dropdown>
                <Text size="sm" style={{marginBottom:10}}>
                    {t("Do you want to finish the conversation?")}
                </Text>
                 <Group justify="flex-end">
                    <Button variant="default" onClick={() => setRestartSessionModalOpened(false)}>{t("No")}</Button>
                    <Button  color="red" onClick={clearSession} >{t("Yes, clear the session")}</Button>
                </Group>
            </Popover.Dropdown>
            </Popover>
            <div style={{ position: 'relative' }}>
            <HelpAssistantChat
                chatMessages={chatMessages}
                setChatMessages={setChatMessages}
                chatContext={chatContext}
                setChatContext={setChatContext}
            />
            </div>
        </Dialog>
    )
}

export default HelpAssistantChatWrapper
