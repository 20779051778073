//
//
//  MAIA Logo
//
//

import logo from "../assets/maia-logo.svg"
import classes from "./MAIALogo.module.css"
import {useNavigate} from "react-router-dom";

function MAIALogo() {
    const navigate = useNavigate()

    return (
        <a onClick={() => navigate("/")} className={classes.wrapper}>
            <img src={logo} alt="MAIA Logo" className={classes.img}/>
        </a>
    )
}

export default MAIALogo
