//
//
//  Admin Home
//
//


import {Flex, rem, Title} from "@mantine/core";
import { useTranslation } from "react-i18next";

function AdminHome() {
    const { t } = useTranslation()

    return (
        <>
            <Flex
                mt={rem(50)}
                mb={rem(30)}
            >
                <Title size="h1">{t("Home")}</Title>
            </Flex>
        </>
    )
}

export default AdminHome
