//
//
//  Navbar link
//
//


import {ReactNode} from "react";
import {Group, Text, ThemeIcon, UnstyledButton} from "@mantine/core";
import {Link} from "react-router-dom";
import cx from "clsx";
import classes from "./NavbarLink.module.css";

function NavbarLink({label, to, color=undefined, icon, active, state={}}: {label: string, to: string, color?: string, icon: ReactNode, active: boolean, state?: any}) {
    return (
        <UnstyledButton
            component={Link}
            to={to}
            className={cx(classes.navbarLink, { [classes.navbarLinkActive]: active})}
            state={state}
        >
            <Group>
                {color == null
                    ? icon
                    : <ThemeIcon color={color} variant="light">
                        {icon}
                    </ThemeIcon>
                }
                <Text size="sm">{label}</Text>
            </Group>
        </UnstyledButton>
    )
}

export default NavbarLink
