//
//
//  Project Builds
//
//

import {Button, Flex, rem, Spoiler, Text, Title, Container} from "@mantine/core";
import {useEffect, useRef, useState} from "react";
import ProjectBuildsDataTable from "../components/ProjectBuildsDataTable.tsx";
import {Build, Project} from "../interfaces.ts";
import {useNavigate, useOutletContext} from "react-router-dom";
import Api from "../api.ts";
import {hasProjectWritePermission, onResourceUpdate, sortByCreatedAt} from "../utils.ts";
import {Socket} from "socket.io-client";
import {useSocket} from "../contexts/SocketContext.tsx";
import PaginationControl from "../components/PaginationControl.tsx";
import { useApiErrorHandler } from "../hooks.ts";
import { useUser } from "../contexts/AuthContext.tsx";
import { useTranslation } from "react-i18next";


function ProjectBuilds() {
    const { t } = useTranslation()
    const pageLength = 5
    const navigate = useNavigate()
    const handleError = useApiErrorHandler()
    const { user } = useUser()
    const {socket}: {socket: Socket} = useSocket()
    const [project]: [Project] = useOutletContext()
    const [builds, setBuilds] = useState<Build[]>([])
    const buildsRef = useRef<Build[]>([])
    const [buildLoading, setBuildLoading] = useState(false)
    const [loading, setLoading] = useState(true)
    const [totalBuilds, setTotalBuilds] = useState(0)
    const [page, setPage] = useState(1)

    useEffect(() => {
        if (!hasProjectWritePermission(project, user)) {
            navigate("*")
        }
    }, [project, user])

    function loadBuildsPage() {
        setLoading(true)
        Api.getBuilds(project.id, page, pageLength)
            .then(pageBuilds => {
                if (pageBuilds["items"].length < 1 && page > 1) {
                    setTotalBuilds(totalBuilds-1)
                } else {
                    buildsRef.current = pageBuilds["items"]
                    setBuilds(sortByCreatedAt(buildsRef.current))
                    setTotalBuilds(pageBuilds["total"])
                    setLoading(false)
                }
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoading(false)
            })
    }

    useEffect(() => {
        loadBuildsPage()
    }, [project.id, page])

    useEffect(() => {
        function onBuildAdd(added: Build) {
            if (buildsRef.current.length == 0) {
                loadBuildsPage()
            } else {
                if (added.project.id === project.id) {
                    const newBuild = {...added, _isNew: true}
                    buildsRef.current = [newBuild, ...buildsRef.current]
                    setBuilds(buildsRef.current)
                }
            }
        }

        function onBuildUpdate(updated: Build) {
            if (updated.project.id === project.id) {
                buildsRef.current = onResourceUpdate(updated, buildsRef.current)
                setBuilds(buildsRef.current)
            }
        }

        function onBuildDelete(deleted: Build) {
            if (deleted.project.id === project.id) {
                loadBuildsPage()
            }
        }

        socket.on("build:add", onBuildAdd)
        socket.on("build:update", onBuildUpdate)
        socket.on("build:delete", onBuildDelete)

        return () => {
            socket.off("build:add", onBuildAdd)
            socket.off("build:update", onBuildUpdate)
            socket.off("build:delete", onBuildDelete)
        }
    }, [socket, project.id, page])

    function onBuild() {
        setBuildLoading(true)
        Api.createBuild(project.id)
            .then(() => {
                setBuildLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setBuildLoading(false)
            })
    }

    return (
        <>
            <Container>
                <Flex
                    justify="space-between"
                    align="center"
                    direction="row"
                    mt={rem(50)}
                    mb={rem(30)}
                >
                    <Title size="h1">{t("Builds")}</Title>

                    <Button
                        loading={buildLoading}
                        onClick={onBuild}
                    >
                        {t("Build")}
                    </Button>
                </Flex>
                <Spoiler mb="xl" maxHeight={50} showLabel={t("Show more")} hideLabel={t("Hide")}>
                    {t("In the context of conversational assistant development, a build refers to the process of both training and deploying a conversational assistant model. This encompasses the training phase, during which the model learns from examples and data, and the subsequent deployment phase, where the trained model is put into operation for real-world interactions.")}
                    <br/>
                    <Text mt={12} mb={4} fw={600}>{t("Example")}:</Text>
                    <ol>
                        <li>
                            <Text component="span" fw={500}>{t("Training")}</Text>: {t(`Developers provide the conversational assistant with examples for topics like "Book a Flight" and "Check Weather."`)}
                        </li>
                        <li>
                            <Text component="span" fw={500}>{t("Deployment")}</Text>: {t(`The trained model is deployed, allowing users to interact with the conversational assistant to book flights or check the weather.`)}
                        </li>
                    </ol>
                    {t("Understanding the build process is fundamental for maintaining an effective and responsive conversational assistant system throughout its lifecycle.")}
                </Spoiler>
                <ProjectBuildsDataTable
                    builds={builds}
                    loading={loading}
                    onBuild={onBuild}
                    buildLoading={buildLoading}
                />
                <PaginationControl 
                    totalElements={totalBuilds}
                    page={page}
                    pageLength={pageLength}
                    onChange={setPage}
                />
            </Container>
        </>
    )
}

export default ProjectBuilds
