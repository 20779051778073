//
//
//  Shared Links Data Table
//
//

import {DataTableEmpty} from "./DataTable.tsx";
import {ActionIcon, Anchor, Group, Text} from "@mantine/core";
import { DataTable } from "mantine-datatable";
import CopyButton from "./CopyButton.tsx";
import {IconTrash} from "@tabler/icons-react";
import {SharedLink} from "../interfaces.ts";
import {modals} from "@mantine/modals";
import {useAutoAnimate} from "@formkit/auto-animate/react";
import { useTranslation } from "react-i18next";


function SharedLinksDataTable({links, loading, onCreate, onDelete, deleteLoading}: {links: SharedLink[], loading: boolean, onCreate: () => void, onDelete: (link: SharedLink) => void, deleteLoading: boolean}) {
    const { t } = useTranslation();
    const [bodyRef] = useAutoAnimate<HTMLTableSectionElement>();

    if (!links.length) {
        return <DataTableEmpty
            title={t("You don't have any shared link")}
            subtitle={t("Let's get started") + " 🚀"}
            action={t("Create your first Shared Link")}
            onAction={onCreate}
        />
    }

    function openDeleteModal(link: SharedLink) {
        modals.openConfirmModal({
            title: t('Delete shared link'),
            centered: true,
            children: (
                <Text size="sm">
                    {t("Are you sure you want to delete your shared link?")}
                </Text>
            ),
            labels: { confirm: t('Delete shared link'), cancel: t("No, don't delete it") },
            confirmProps: { color: 'red' , loading: deleteLoading},
            onConfirm: () => onDelete(link)
        })
    }

    function renderLinkDetails(link: SharedLink) {  

        const url = `/shared?token=${link.token}`
        return (
       
                <Anchor href={url} target="_blank">
                    {link.token}
                </Anchor>
            )
    }

    function renderActions(link: SharedLink) {  

        const url = `/shared?token=${link.token}`
        return (
            <Group gap={4} justify="right" wrap="nowrap">
                <CopyButton
                    value={window.location.origin + url}
                />
                <ActionIcon variant="subtle" color="red" aria-label={t("Delete")} onClick={() => openDeleteModal(link)}>
                    <IconTrash size={16} />
                </ActionIcon>
            </Group>
        )
    }

    return (
        <DataTable
            withTableBorder
            bodyRef={bodyRef}
            fetching={loading}
            columns={[{ accessor: 'url', render: renderLinkDetails }, { accessor: '', render: renderActions }]}
            records={links}
            defaultColumnProps={{
                noWrap: true,
                ellipsis: true,
                cellsStyle: (link: any) => {
                    if (link._isNew) {
                        return {backgroundColor: "#F5FBEF"}
                    }
                }
            }}
        />
    )

}

export default SharedLinksDataTable
