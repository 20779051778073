//
//
//  Login
//
//

import {
    TextInput,
    PasswordInput,
    Checkbox,
    Paper,
    Container,
    Group,
    Button,
    Alert,
    Anchor,
    Flex,
    Title,
    Box
} from '@mantine/core'
import {isEmail, useForm} from '@mantine/form'
import { IconAlertCircle } from '@tabler/icons-react'

import Api, {AuthenticationError, LoginResponse} from "../api.ts"
import {useEffect, useState} from "react"
import {useUser} from "../contexts/AuthContext.tsx";
import {Link, useNavigate, useLocation} from "react-router-dom";
import MAIALogo from "../components/MAIALogo.tsx";
import { useTranslation } from "react-i18next";

interface Login {
    email: string
    password: string
    remember_me: boolean
}


export function Login() {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [authenticationError, setAuthenticationError] = useState(false)
    const [requestError, setRequestError] = useState(false)
    const {user, setUser} = useUser()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (user != null) {
            navigate("/")
        }
    }, [user])

    const form = useForm({
        initialValues: {email: "", password: "", remember_me: false},
        validate: {
            email: isEmail(t('Invalid email')),
        },
    })

    function onSubmit(data: Login) {
        setLoading(true)
        setAuthenticationError(false)
        setRequestError(false)

        Api.login(data.email, data.password)
            .then((response: LoginResponse) => {
                if (data.remember_me) {
                    localStorage.setItem("accessToken", response.access_token)
                } else {
                    sessionStorage.setItem("accessToken", response.access_token)
                }
                return Api.getCurrentUser()
            }).then(user => {
                setUser(user)
                const nextPath = location.state || { from: { pathname: "/" } }
                navigate(nextPath.from)
            }).catch((err: Error) => {
                if (err instanceof AuthenticationError) {
                    setAuthenticationError(true)
                    setRequestError(false)
                    setLoading(false)
                } else {
                    setAuthenticationError(false)
                    setRequestError(true)
                    setLoading(false)
                }
            })
    }

    return (
        <Box style={{width:'100%',height:'100%', background:'#EDF5FD', position: 'absolute'}}>
        <Container size={420} my={40}>
            <Flex justify="center" style={{height: "140px"}}>
                <MAIALogo/>
            </Flex>

            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                <Title ta="center" size="h3" mb="xs">{t("Login")}</Title>
                <form onSubmit={form.onSubmit(onSubmit)}>
                    {authenticationError &&
                        <Alert icon={<IconAlertCircle />} title="Error" color="red" withCloseButton onClose={() => setAuthenticationError(false)}>
                            {t("Incorrect username or password")}
                        </Alert>
                    }
                    {requestError &&
                        <Alert icon={<IconAlertCircle />} title={t("Oops!")} color="red" withCloseButton onClose={() => setRequestError(false)}>
                            {t("It looks like there was an error processing your request. Please try again later.")}
                        </Alert>
                    }
                    <TextInput label={t("Email")} placeholder="you@maiacognitive.com" required {...form.getInputProps("email")} />
                    <PasswordInput label={t("Password")} placeholder={t("Your password")} required mt="md" {...form.getInputProps("password")} />
                    <Group mt="lg" justify="space-between">
                        <Checkbox label={t("Remember me")} {...form.getInputProps("remember_me")}  />
                        <Anchor component={Link} size="sm" to="/forgot-password">
                            {t("Forgot password?")}
                        </Anchor>
                    </Group>
                    <Button loading={loading} fullWidth mt="xl" type="submit">
                        {t("Sign in")}
                    </Button>
                </form>
            </Paper>
        </Container>
        </Box>
    )
}

export default Login
