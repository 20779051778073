//
//
//  Shared
//
//


import classes from "./Shared.module.css";
import {Alert, Container, LoadingOverlay} from "@mantine/core";
import MAIALogo from "../components/MAIALogo.tsx";
import {useEffect, useState} from "react";
import {Project} from "../interfaces.ts";
import Api from "../api.ts";
import ProjectCustomBuildPlayer from "../components/ProjectCustomBuildPlayer.tsx";
import ProjectDocumentBuildPlayer from "../components/ProjectDocumentBuildPlayer.tsx";
import {IconInfoCircle} from "@tabler/icons-react";
import { useApiErrorHandler } from "../hooks.ts";
import { useTranslation } from "react-i18next";

function Shared() {
    const { t } = useTranslation()
    const handleError = useApiErrorHandler()
    const [loading, setLoading] = useState(true)
    const [project, setProject] = useState<Project | null>()

    useEffect(() => {
        const search = window.location.search
        const params = new URLSearchParams(search)

        Api.getSharedLink(params.get("token"))
            .then(link => {
                setProject(link.project)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoading(false)
            })
    }, [])

    if (loading) {
        return (
            <LoadingOverlay visible loaderProps={{size: "xl", variant: "oval"}}/>
        )
    }

    if (project == null) {
        return (
            <Alert variant="filled" color="red" title="Not found" icon={<IconInfoCircle />}>
                {t("No project associated found.")}
            </Alert>
        )
    }

    return (
        <div className={classes.root}>
            <div className={classes.headerWrapper}>
                <Container className={classes.header}>
                    <MAIALogo/>
                </Container>
            </div>
            <Container className={classes.container}>
                {project.type == "CUSTOM" ? <ProjectCustomBuildPlayer project={project}/>
                : <ProjectDocumentBuildPlayer project={project}/>}
            </Container>
        </div>
    )
}

export default Shared
