//
//
//  Settings
//
//

import {useUser} from "../contexts/AuthContext.tsx";
import {User} from "../interfaces.ts";
import {useEffect} from "react";
import {Socket} from "socket.io-client";
import {useSocket} from "../contexts/SocketContext.tsx";
import {Outlet} from "react-router-dom";
import SettingsLayout from "../layouts/SettingsLayout.tsx";


function Settings() {
    const {socket}: {socket: Socket} = useSocket()
    const {user, setUser} = useUser()

    useEffect(() => {
        function onUserUpdate(updated: User) {
            if (updated.id == user?.id) {
                setUser(updated)
            }
        }

        socket.on("user:update", onUserUpdate)

        return () => {
            socket.off("user:update", onUserUpdate)
        }
    }, [user?.id, socket])

    return (
        <SettingsLayout>
            <Outlet></Outlet>
        </SettingsLayout>
    )
}

export default Settings
