//
//
//  Not Found
//
//

import { Container, Title, Text, Button, Group, Flex } from '@mantine/core';
import classes from './NotFound.module.css';
import { useNavigate } from 'react-router-dom';
import MAIALogo from '../components/MAIALogo';
import { useTranslation } from "react-i18next";

function NotFound() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Container className={classes.root}  my={40}>
      <Flex justify="center" style={{height: "100px"}}>
        <MAIALogo/>
      </Flex>
      <div className={classes.label}>404</div>
      <div className={classes.inner}>
        <div className={classes.content}>
          <Title size="h3" className={classes.title}>{t("Nothing to see here")}</Title>
          <Text c="dimmed" size="sm" ta="center" className={classes.description}>
            {t("The page you are trying to open does not exist.")}
          </Text>
          <Group justify="center">
            <Button onClick={() => navigate('/')}>{t("Take me back to home page")}</Button>
          </Group>
        </div>
      </div>
    </Container>
  );
}

export default NotFound