//
//
//  Project Sources
//
//

import {Flex, rem, Title, Button, Group, Modal, TextInput, FileInput, Spoiler} from "@mantine/core";
import ProjectSourcesDataTable from "../components/ProjectSourcesDataTable.tsx";
import {useEffect, useRef, useState} from "react";
import {IconCirclePlus} from "@tabler/icons-react";
import {useForm} from "@mantine/form";
import {Project, Source} from "../interfaces.ts";
import {useNavigate, useOutletContext} from "react-router-dom";
import Api from "../api.ts";
import ProjectSourceDrawer from "../components/ProjectSourceDrawer.tsx";
import {hasProjectWritePermission, onResourceAdd, onResourceDelete, onResourceUpdate, sortByUpdatedAt} from "../utils.ts";
import {Socket} from "socket.io-client";
import {useSocket} from "../contexts/SocketContext.tsx";
import PaginationControl from "../components/PaginationControl.tsx";
import { useApiErrorHandler } from "../hooks.ts";
import { useUser } from "../contexts/AuthContext.tsx";
import { useTranslation } from "react-i18next";

function ProjectSources() {
    const { t } = useTranslation()
    const pageLength = 6
    const navigate = useNavigate()
    const handleError = useApiErrorHandler()
    const { user } = useUser()
    const {socket}: {socket: Socket} = useSocket()
    const sourcesRef = useRef<Source[]>([])
    const [project]: [Project] = useOutletContext()
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [selectedSource, setSelectedSource] = useState<Source | null>(null)
    const [createSourceOpen, setCreateSourceOpen] = useState(false)
    const [sources, setSources] = useState<Source[]>([])
    const [loading, setLoading] = useState(true)
    const [createLoading, setCreateLoading] = useState(false)
    const [totalSources, setTotalSources] = useState(0)
    const [page, setPage] = useState(1)

    useEffect(() => {
        if (!hasProjectWritePermission(project, user)) {
            navigate("*")
        }
    }, [project, user])

    function loadSourcesPage() {
        Api.getSources(project.id, page, pageLength)
            .then(pageSources => {
                sourcesRef.current = pageSources["items"]
                setSources(sortByUpdatedAt(sourcesRef.current))
                setTotalSources(pageSources["total"])
                setLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoading(false)
            })
    }

    useEffect(() => {
        loadSourcesPage()
    }, [project.id, page, totalSources])

    useEffect(() => {
        function onAdd(added: Source) {
            if (added.project.id === project.id) {
                sourcesRef.current = onResourceAdd(added, sourcesRef.current)
                setSources(sortByUpdatedAt(sourcesRef.current))
                setTotalSources(totalSources+1)
            }
        }

        function onUpdate(updated: Source) {
            if (updated.project.id === project.id) {
                sourcesRef.current = onResourceUpdate(updated, sourcesRef.current)
                setSources(sortByUpdatedAt(sourcesRef.current))
            }
            if (updated.id == selectedSource?.id) {
                setSelectedSource({...updated})
            }
        }

        function onDelete(deleted: Source) {
            if (deleted.project.id === project.id) {
                sourcesRef.current = onResourceDelete(deleted, sourcesRef.current)
                setSources(sortByUpdatedAt(sourcesRef.current))
                setTotalSources(totalSources-1)
            }
        }

        socket.on("source:add", onAdd)
        socket.on("source:update", onUpdate)
        socket.on("source:delete", onDelete)

        return () => {
            socket.off("source:add", onAdd)
            socket.off("source:update", onUpdate)
            socket.off("source:delete", onDelete)
        }
    }, [project.id, socket])


    const createSourceForm = useForm({
        initialValues: {
            name: "",
            file: null
        },
        validate: {
            file: (value: File | null) => (
              value?.type && ["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(value.type)
                ? null
                : t('Please, upload a valid PDF or Word document file')
            ),
          },
    })

    function onSubmitCreateSource(values: any) {
        setCreateLoading(true)
        Api.createSource(project.id, values.name)
            .then(source => {
                return Api.putSourceFile(source.id, values.file)
            }).then(source => {
                setCreateLoading(false)
                setCreateSourceOpen(false)
                setSelectedSource(source)
                createSourceForm.reset()
        }).catch((err) => {
            console.error(err);
            handleError(err)
            setCreateLoading(false)
        })
    }

    function onSourceSelection(source: Source) {
        setSelectedSource(source)
    }

    function onDeleteSource(source: Source) {
        setDeleteLoading(true)
        Api.deleteSource(source.id)
            .then(() => {
                setDeleteLoading(false)
                setSelectedSource(null)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setDeleteLoading(false)
            })
    }

    return (
        <>
            <Flex
                justify="space-between"
                align="center"
                direction="row"
                mt={rem(50)}
                mb={rem(30)}
            >
                <Title size="h1">{t("Sources")}</Title>

                <Button
                    onClick={() => setCreateSourceOpen(true)}
                    leftSection={<IconCirclePlus size={16}/>}
                >
                    {t("Create Source")}
                </Button>
            </Flex>
            <Spoiler mb="xl" maxHeight={50} showLabel="Show more" hideLabel="Hide">
                {t(`The "Sources" page is where you add the file that will be analyzed by the conversational assistant. This file serves as a source of information for answering user questions.`)}
                <br/>
                {t(`It allows you to upload PDF documents, which will be used as a source of information for the assistant to answer questions based on their content. You can easily manage and update your sources by adding new ones or deleting existing ones if needed.`)}
            </Spoiler>
            <ProjectSourcesDataTable
                sources={sources}
                loading={loading}
                onCreate={() => setCreateSourceOpen(true)}
                createLoading={createLoading}
                onSelection={onSourceSelection}
            />
            <PaginationControl 
                    totalElements={totalSources}
                    page={page}
                    pageLength={pageLength}
                    onChange={setPage}
                />
            <Modal opened={createSourceOpen} onClose={() => setCreateSourceOpen(false)} title={t("Create Source")}>
                <form onSubmit={createSourceForm.onSubmit(onSubmitCreateSource)}>
                    <TextInput
                      withAsterisk
                      label={t("Name")}
                      required
                      placeholder={t("Super duper source")}
                      {...createSourceForm.getInputProps("name")}
                    />
                    <FileInput
                        label={t("Document")}
                        withAsterisk
                        variant="filled"
                        clearable
                        mt="xs"
                        required
                        accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        description={t("Upload any .pdf or .docx document")}
                        {...createSourceForm.getInputProps("file")}
                    />
                    <Group align="right" mt="md">
                        <Button type="submit" loading={createLoading}>{t("Submit")}</Button>
                    </Group>
                </form>
            </Modal>
            {selectedSource != null &&
                <ProjectSourceDrawer
                    source={selectedSource}
                    opened={true}
                    onClose={() => setSelectedSource(null)}
                    deleteLoading={deleteLoading}
                    onDelete={onDeleteSource}
                />
            }
        </>
    )
}

export default ProjectSources
