//
//
//  Settings Teams
//
//

import {Button, Flex, rem, Title, Text, Badge, Modal, TextInput, Textarea, Group, NumberInput} from "@mantine/core";
import {Link, useNavigate} from "react-router-dom";
import {IconCirclePlus, IconCurrencyEuro} from "@tabler/icons-react";
import {DataTable} from "mantine-datatable";
import {useEffect, useRef, useState} from "react";
import Api from "../api.ts";
import {useUser} from "../contexts/AuthContext.tsx";
import {useAutoAnimate} from "@formkit/auto-animate/react";
import classes from "./SettingsTeam.module.css"
import {modals} from "@mantine/modals";
import {useApiErrorHandler} from "../hooks.ts";
import {useForm} from "@mantine/form";
import {Socket} from "socket.io-client";
import {useSocket} from "../contexts/SocketContext.tsx";
import {Team} from "../interfaces.ts";
import {onResourceDelete, onResourceUpdate, sortByUpdatedAt} from "../utils.ts";
import { useTranslation } from "react-i18next";


function SettingsTeams({pageSize=10}: {pageSize?: number}) {
    const { t } = useTranslation()
    const {user} = useUser()
    const navigate = useNavigate()
    const handleError = useApiErrorHandler()
    const { socket }: { socket: Socket } = useSocket()
    const teamsRef = useRef<Team[]>([])
    const [createTeamLoading, setCreateTeamLoading] = useState(false)
    const [createTeamOpened, setCreateTeamOpened] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [bodyRef] = useAutoAnimate<HTMLTableSectionElement>()
    const [totalTeams, setTotalTeams] = useState(0)
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [teams, setTeams] = useState<Team[]>([])

    const createTeamForm = useForm({
        initialValues: {
            "name": "",
            "description": "",
            "credit_balance": 0.0
        },
    })

    useEffect(() => {
        Api.getTeams(page, pageSize)
            .then(response => {
                teamsRef.current = response["items"]
                setTeams(response["items"])
                setTotalTeams(response["total"])
                setLoading(false)
            })
    }, [page])

    useEffect(() => {
        function onAdd(added: Team) {
            const newTeam = {...added, _isNew: true}
            teamsRef.current = [newTeam, ...teamsRef.current]
            setTeams(teamsRef.current)
        }

        function onUpdate(updated: Team) {
            teamsRef.current = onResourceUpdate(updated, teamsRef.current)
            setTeams(sortByUpdatedAt(teamsRef.current))
        }

        function onDelete(deleted: Team) {
            teamsRef.current = onResourceDelete(deleted, teamsRef.current)
            setTeams(sortByUpdatedAt(teamsRef.current))
            setTotalTeams(totalTeams - 1)
        }

        socket.on("team:add", onAdd)
        socket.on("team:update", onUpdate)
        socket.on("team:delete", onDelete)

        return () => {
            socket.off("team:add", onAdd)
            socket.off("team:update", onUpdate)
            socket.off("team:delete", onDelete)
        }
    }, [socket])

    function onDelete(team: any) {
        const isOwner = user?.id === team.created_by.id
        modals.openConfirmModal({
            title: isOwner ? t("Delete team") : t("Leave team"),
            centered: true,
            children: (
                <Text size="sm">
                    {t("Are you sure you want to")} {isOwner ? t("delete") : t("leave")} {team.name}?
                    <br/>
                    {t("All projects will be removed.")}
                </Text>
            ),
            labels: { confirm: isOwner ? t("Delete") : t("Leave"), cancel: t("No, don't delete it") },
            confirmProps: { color: 'red' },
            onConfirm: () => {
                setDeleteLoading(true)
                const promise = isOwner ? Api.deleteTeam(team.id) : Api.leaveTeam(team.id)
                promise
                    .catch((err: Error) => {
                        console.error(err)
                        handleError(err)
                    }).finally(() => {
                        setDeleteLoading(false)
                    })
            }
        })
    }

    function renderTeam(team: any) {
        const isOwner = user?.id === team.created_by.id

        return (
            <>
                <div className={classes.team}>
                    <div className={classes.teamDescription}>
                        <Flex align="center" gap={8}>
                            <Title size="h4">
                                {team.name}
                            </Title>
                            {isOwner &&
                                <Badge variant="outline" color="indigo">{t("Owner")}</Badge>
                            }
                        </Flex>
                        <Text c="dimmed">
                            {team.description}
                        </Text>
                    </div>
                    <div className={classes.teamSettings}>
                        {isOwner &&
                            <Button variant="outline" color="gray" component={Link} to={`/teams/${team.id}/settings`}>{t("Settings")}</Button>
                        }
                        <Button onClick={() => onDelete(team)} loading={deleteLoading} variant="outline" color="red">{isOwner ? t("Delete") : t("Leave")}</Button>
                    </div>
                </div>
            </>
        )
    }

    function onSubmit(values: any) {
        setCreateTeamLoading(true)

        if (!values["name"]) {
            values["name"] = null
        }

        Api.createTeam(values.name, values.description, values.credit_balance)
            .then(team => {
                navigate(`/teams/${team.id}/settings`)
            }).catch(err => {
                console.error(err)
                handleError(err)
            }).finally(() => {
                setCreateTeamLoading(false)
                setCreateTeamOpened(false)
                createTeamForm.reset()
            })
    }

    return (
        <>
            <Flex
                justify="space-between"
                align="center"
                direction="row"
                mt={rem(50)}
                mb={rem(30)}
            >
                <Title size="h1">{t("Teams")}</Title>
                <Button
                    onClick={() => setCreateTeamOpened(true)}
                    leftSection={<IconCirclePlus size={16}/>}
                >
                    {t("Create Team")}
                </Button>
            </Flex>
            <DataTable
                noHeader
                withRowBorders={false}
                borderColor={"white"}
                bodyRef={bodyRef}
                fetching={loading}
                columns={[
                    {
                        accessor: "",
                        render: renderTeam
                    }
                ]}
                verticalSpacing={8}
                records={teams}
                page={page}
                onPageChange={setPage}
                totalRecords={totalTeams}
                recordsPerPage={pageSize}
                minHeight={teams.length === 0 ? 300 : undefined}
                defaultColumnProps={{
                    noWrap: true,
                    ellipsis: true,
                    cellsStyle: (team: any) => {
                        if (team._isNew) {
                            return {backgroundColor: "#F5FBEF"}
                        }
                    }
                }}
            />
            <Modal opened={createTeamOpened} title={t("Create Team")} centered onClose={() => setCreateTeamOpened(false)}>
                <form onSubmit={createTeamForm.onSubmit(onSubmit)}>
                    <TextInput
                        withAsterisk
                        required
                        label={t("Name")}
                        {...createTeamForm.getInputProps('name')}
                    />
                    <Textarea
                        mt="md"
                        label={t("Description")}
                        {...createTeamForm.getInputProps('description')}
                    />

                    <NumberInput
                        mt="md"
                        label={t("Credit Balance")}
                        allowNegative={false}
                        rightSection={<IconCurrencyEuro/>}
                        {...createTeamForm.getInputProps('credit_balance')}
                    />
                    <Group justify="flex-start" mt="xl">
                        <Button loading={createTeamLoading} type="submit">{t("Create")}</Button>
                    </Group>
                </form>
            </Modal>
        </>
    )
}

export default SettingsTeams
