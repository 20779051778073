//
//
//  Project Sniffer
//
//

import {Flex, rem, Title, Spoiler, Container} from "@mantine/core";
import ProjectConversationsDataTable from "../components/ProjectConversationsDataTable.tsx";
import {useEffect, useRef, useState} from "react";
import {Conversation, Project} from "../interfaces.ts";
import {useOutletContext} from "react-router-dom";
import Api from "../api.ts";
import ProjectConversationDrawer from "../components/ProjectConversationDrawer.tsx";
import {onResourceUpdate, sortByUpdatedAt} from "../utils.ts";
import {Socket} from "socket.io-client";
import {useSocket} from "../contexts/SocketContext.tsx";
import PaginationControl from "../components/PaginationControl.tsx";
import { useApiErrorHandler } from "../hooks.ts";
import { useTranslation } from "react-i18next";

function ProjectSniffer() {
    const { t } = useTranslation();
    const pageLength = 5
    const handleError = useApiErrorHandler()
    const {socket}: {socket: Socket} = useSocket()
    const [project]: [Project] = useOutletContext()
    const selectedConversationRef = useRef<Conversation | null>(null)
    const conversationsRef = useRef<Conversation[]>([])
    const [selectedConversation, setSelectedConversation] = useState<Conversation | null>(null)
    const [loading, setLoading] = useState(true)
    const [conversations, setConversations] = useState<Conversation[]>([])
    const [totalConversations, setTotalConversations] = useState(0)
    const [page, setPage] = useState(1)

    function loadConversationsPage() {
        setLoading(true)
        Api.getProjectConversations(project.id, page, pageLength)
            .then(pageConversations => {
                if (pageConversations["items"].length < 1 && page > 1) {
                    setTotalConversations(totalConversations-1)
                } else {
                    conversationsRef.current = pageConversations["items"]
                    setConversations(sortByUpdatedAt(conversationsRef.current))
                    setTotalConversations(pageConversations["total"])
                    setPage(pageConversations["page"])
                    setLoading(false)
                }
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoading(false)
            })
    }

    useEffect(() => {
        loadConversationsPage()
    }, [project.id, page])

    useEffect(() => {
        function onAdd(added: Conversation) {
            if (added.project.id === project.id) {
                const newResponse = {...added, _isNew: true}
                conversationsRef.current = [newResponse, ...conversationsRef.current]
                setConversations(sortByUpdatedAt(conversationsRef.current))
            }
        }

        function onUpdate(updated: Conversation) {
            if (updated.project.id === project.id) {
                conversationsRef.current = onResourceUpdate(updated, conversationsRef.current)
                loadConversationsPage()
            }
            if (updated.id == selectedConversationRef.current?.id) {
                setSelectedConversation({ ...updated })
            }
        }

        // noinspection DuplicatedCode
        socket.on("conversation:add", onAdd)
        socket.on("conversation:update", onUpdate)

        return () => {
            socket.off("conversation:add", onAdd)
            socket.off("conversation:update", onUpdate)
        }
    }, [project.id, socket])

    function onSelection(conversation: Conversation) {
        setSelectedConversation(conversation)
        selectedConversationRef.current = conversation
    }

    function onCloseConversation() {
        setSelectedConversation(null)
        selectedConversationRef.current = null
    }

    return (
        <>
            <Container>
                <Flex
                    justify="space-between"
                    align="center"
                    direction="row"
                    mt={rem(50)}
                    mb={rem(30)}
                >
                    <Title size="h1">{t("Conversations")}</Title>
                </Flex>
                <Spoiler mb="xl" maxHeight={50} showLabel={t("Show more")} hideLabel={t("Hide")}>
                    {t("The Conversations (Sniffer) Section is a dedicated space that provides users with real-time access to the current and previous conversations involving the conversational assistant. ")}
                    {t("This feature allows users to monitor, analyze, and gain insights into the ongoing interactions, enabling a deeper understanding of the conversational assistant's performance and user engagement.")}
                    <br/>
                    {t("The Conversations Section offers users a valuable tool for real-time monitoring of conversational assistant interactions, enabling quality assessment, user engagement insights, and data-driven conversational assistant refinement.")}
                </Spoiler>
                <ProjectConversationsDataTable
                    conversations={conversations}
                    loading={loading}
                    onSelection={onSelection}
                />
                <PaginationControl 
                    totalElements={totalConversations}
                    page={page}
                    pageLength={pageLength}
                    onChange={setPage}
                />
            </Container>
            {selectedConversation != null &&
                <ProjectConversationDrawer
                    conversation={selectedConversation}
                    opened={true}
                    onClose={onCloseConversation}
                />
            }
        </>
    )
}

export default ProjectSniffer
