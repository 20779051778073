//
//
//  Intent Drawer
//
//


import {Intent, Utterance} from "../interfaces.ts"
import {
    ActionIcon,
    Button,
    Divider,
    Drawer,
    Table,
    TextInput,
    Title,
    UnstyledButton,
    Text,
    Flex, LoadingOverlay
} from "@mantine/core"
import classes from "./ProjectIntentDrawer.module.css"
import {IconBrandOpenai, IconCirclePlus, IconTrash} from "@tabler/icons-react";
import {useEffect, useRef, useState} from "react";
import {useForm} from "@mantine/form";
import Api from "../api.ts";
import {onResourceAdd, onResourceDelete, onResourceUpdate, reverse} from "../utils.ts";
import ProjectIntentSuggestionsModal from "./ProjectIntentSuggestionsModal.tsx";
import {Socket} from "socket.io-client";
import {useSocket} from "../contexts/SocketContext.tsx";
import {useAutoAnimate} from "@formkit/auto-animate/react";
import { useApiErrorHandler } from "../hooks.ts";
import { useTranslation } from "react-i18next";


interface UtteranceForm {
    utterance: string
}


function ProjectIntentDrawer({intent, opened, onClose, onDelete, onUtteranceAdd, deleteLoading}:
                      {intent: Intent, opened: boolean, onClose: () => void,
                       onDelete: (intent: Intent) => void, onUtteranceAdd: (utterance: Utterance, intent: Intent) => void,
                       onUtteranceDelete: (utterance: Utterance, intent: Intent) => void, deleteLoading: boolean}) {
    const { t } = useTranslation()
    const [parent] = useAutoAnimate()
    const handleError = useApiErrorHandler()
    const [loading, setLoading] = useState(true)
    const {socket}: {socket: Socket} = useSocket()
    const [updateLoading, setUpdateLoading] = useState(false)
    const utterancesRef = useRef<Utterance[]>([])
    const [utterances, setUtterances] = useState<Utterance[]>([])
    const [suggestionsOpened, setSuggestionsOpened] = useState<boolean>(false)

    useEffect(() => {
        Api.getIntentUtterances(intent.id)
            .then(listUtterances => {
                utterancesRef.current = listUtterances
                setUtterances(listUtterances)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoading(false)
            })
    }, [intent.id])

    useEffect(() => {
        function onAdd(added: Utterance) {
            if (added.intent.id === intent.id) {
                utterancesRef.current = onResourceAdd(added, utterancesRef.current)
                setUtterances(utterancesRef.current)
            }
        }

        function onUpdate(updated: Utterance) {
            if (updated.intent.id === intent.id) {
                utterancesRef.current = onResourceUpdate(updated, utterancesRef.current)
                setUtterances(utterancesRef.current)
            }
        }

        function onDelete(deleted: Utterance) {
            if (deleted.intent.id === intent.id) {
                utterancesRef.current = onResourceDelete(deleted, utterancesRef.current)
                setUtterances(utterancesRef.current)
            }
        }

        socket.on("intent_utterance:add", onAdd)
        socket.on("intent_utterance:update", onUpdate)
        socket.on("intent_utterance:delete", onDelete)

        return () => {
            socket.off("intent_utterance:add", onAdd)
            socket.off("intent_utterance:update", onUpdate)
            socket.off("intent_utterance:delete", onDelete)
        }
    }, [intent.id, socket]);

    const createUtteranceform = useForm({
        initialValues: {
            utterance: "",
        },
        validate: {
            utterance: value => value.length < 1
        }
    })

    const updateIntentForm = useForm({
        initialValues: {
            name: intent.name
        }
    })

    function onSubmitCreateUtterance(values: UtteranceForm) {
        Api.createIntentUtterance(intent.id, values.utterance)
            .then(utterance => {
                createUtteranceform.reset()
                onUtteranceAdd(utterance, intent)
            }).catch((err) => {
                console.error(err);
                handleError(err)
            })
    }

    function deleteUtterance(utteranceId: number) {
        Api.deleteIntentUtterance(utteranceId)
            .catch(console.error)

    }

    function onSubmitUpdateIntent(values: any) {
        setUpdateLoading(true)
        Api.updateIntent(intent.id, values)
            .then(() => {
                setUpdateLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setUpdateLoading(false)
            })
    }

    return (
        <Drawer
            opened={opened}
            onClose={onClose}
            title={intent.name}
            position="right"
            styles={{
                content: {
                    display: "flex",
                    flexDirection: "column"
                },
                body: {
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1
                }
            }}
        >
            <form onSubmit={updateIntentForm.onSubmit(onSubmitUpdateIntent)}>
                <TextInput
                  withAsterisk
                  required
                  label={t("Name")}
                  placeholder={t("Super duper topic")}
                  {...updateIntentForm.getInputProps("name")}
                />
                <Button mt="md" type="submit" loading={updateLoading}>{t("Save")}</Button>
            </form>
            <Title size="h3" mt="lg" mb="md">{t("Sentences")}</Title>
            <LoadingOverlay visible={loading}/>
            <form onSubmit={createUtteranceform.onSubmit(onSubmitCreateUtterance)}>
                <Flex justify="flex-end" mb={6} gap={8}>
                    <Button color="#64a092d9" onClick={() => setSuggestionsOpened(true)} leftSection={<IconBrandOpenai size={18} />} size="xs">
                        {t("Suggest")}
                    </Button>
                </Flex>
                <TextInput
                  withAsterisk
                  required
                  placeholder={t("What might a user say to invoke this topic?")}
                  {...createUtteranceform.getInputProps("utterance")}
                  rightSection={<UnstyledButton type="submit"><IconCirclePlus size={16}/></UnstyledButton>}
                />
            </form>
            <Divider my="sm" />
            <div className={classes.content}>
                <Table mt="md" withTableBorder>
                    <Table.Tbody ref={parent}>
                        {reverse(utterances).map(utterance => {
                            return (
                                <Table.Tr key={utterance.id} className={classes.formRow}>
                                    <Table.Td>
                                        <Text size="sm">{utterance.utterance}</Text>
                                    </Table.Td>
                                    <Table.Td>
                                        <ActionIcon variant="subtle" color="red" aria-label={t("Delete")} onClick={() => deleteUtterance(utterance.id)}>
                                            <IconTrash size={16} />
                                        </ActionIcon>
                                    </Table.Td>
                                </Table.Tr>
                            )
                        })}
                    </Table.Tbody>
                </Table>
                <div className={classes.footer}>
                    <Divider mt="xl" mb="xl"/>
                    <Button loading={deleteLoading} color="red" leftSection={<IconTrash size={16}/>} fullWidth onClick={() => onDelete(intent)}>
                        {t("Delete topic")}
                    </Button>
                </div>
            </div>
            <ProjectIntentSuggestionsModal
                intent={intent}
                utterances={utterances}
                onUtterance={(utterance: string) => onSubmitCreateUtterance({"utterance": utterance})}
                opened={suggestionsOpened}
                onClose={() => setSuggestionsOpened(false)}
            />
        </Drawer>
    )
}

export default ProjectIntentDrawer
