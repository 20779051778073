//
//
//  Project Sources Data Table
//
//

import {Source} from "../interfaces.ts";
import {useAutoAnimate} from "@formkit/auto-animate/react";
import {DataTableEmpty, DataTableLoading} from "./DataTable.tsx";
import {SimpleGrid} from "@mantine/core";
import {Anchor, Card, Title} from "@mantine/core";
import classes from "./SourceCard.module.css";
import DocumentViewer from "./DocumentViewer.tsx";
import { useTranslation } from "react-i18next";

function ProjectSourcesDataTable({sources, loading, onCreate, createLoading, onSelection}: {sources: Source[], loading: boolean, onCreate: () => void, createLoading: boolean, onSelection: (source: Source) => void}) {
    const { t } = useTranslation()
    const [parent] = useAutoAnimate()

    if (loading) {
        return <DataTableLoading/>
    }

    if (!sources.length) {
        return <DataTableEmpty
            title={t("You don't have any source")}
            subtitle={t("Let's get started") + " 🚀"}
            action={t("Create your first source")}
            onAction={onCreate}
            actionLoading={createLoading}
        />
    }

    return (
        <SimpleGrid cols={3} ref={parent}>
            {sources.map(source => {
                return (
                    <Card key={source.id} shadow="sm" padding="lg" radius="md" withBorder>
                        <Anchor onClick={() => onSelection(source)} mb="sm">
                            <Title size="h3" ta="center" className={classes.rowTitle}>
                                {source.name}
                            </Title>
                        </Anchor>
                        <DocumentViewer file={source.file}/>
                    </Card>
                )
            })}
        </SimpleGrid>
    )
}

export default ProjectSourcesDataTable
