//
//
//  Project Test
//
//

import {
    ActionIcon,
    Anchor,
    Flex,
    LoadingOverlay,
    rem,
    Spoiler,
    Table,
    Text,
    TextInput,
    Title
} from "@mantine/core";
import {useForm} from "@mantine/form";
import {IconArrowRight} from "@tabler/icons-react";
import {useState} from "react";
import {useAutoAnimate} from "@formkit/auto-animate/react";
import {Project} from "../interfaces.ts";
import {Link, useOutletContext} from "react-router-dom";
import ProjectBuildRetriever from "../components/ProjectBuildRetriever.tsx";
import {toJsonOrRaise} from "../utils.ts";
import Api from "../api.ts";
import { useApiErrorHandler } from "../hooks.ts";
import { useTranslation } from "react-i18next";

function ProjectTest() {
    const { t } = useTranslation()
    const [project]: [Project] = useOutletContext()
    const handleError = useApiErrorHandler()
    const [parent] = useAutoAnimate()
    const [loading, setLoading] = useState(false)
    const [intentRanking, setIntentRanking] = useState([])

    function onSubmit(values: any) {
        setLoading(true)
        setIntentRanking([])
        fetch(project.rasa_service_url + "/model/parse", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "text": values["text"]
            })
        }).then(toJsonOrRaise)
        .then(response => {
            const intentsPromise = Promise.all(response["intent_ranking"].map(async (rank_intent: any) => {
                let intentName
                if (rank_intent["name"].startsWith("intent_")) {
                    const intent = await Api.getIntent(rank_intent["name"].replace("intent_", ""))
                    intentName = <Anchor component={Link} to={`/projects/${project.id}/intents`} state={{selected: intent}}>{intent["name"]}</Anchor>
                } else {
                    intentName = rank_intent["name"]
                }
                return {
                    "intent": intentName,
                    "confidence": rank_intent["confidence"],
                }
            }))
            intentsPromise.then((intents: any) => {
                setIntentRanking(intents)
                setLoading(false)
            })
        }).catch((err) => {
            console.error(err);
            handleError(err)
            setLoading(false)
        })
    }

    const form = useForm({
        initialValues: {
            text: ""
        },
    })

    return (
        <>
            <Flex
                justify="space-between"
                align="center"
                direction="row"
                mt={rem(50)}
                mb={rem(30)}
            >
                <Title size="h1">{t("Test")}</Title>
            </Flex>
            <Spoiler mb="xl" maxHeight={50} showLabel={t("Show more")} hideLabel={t("Hide")}>
                {t("The Test Section is a specialized area designed for meticulous evaluation of a conversational assistant model's performance in recognizing and ranking topics based on user input. Users can input sentences, and the system provides a detailed breakdown, including a ranked list of detected topics and corresponding confidence scores.")}
                <br/>
                <Text mt={12} mb={4} fw={600}>{t("Example")}:</Text>
                <ul>
                    <li>
                        <Text fw={500} component="span">{t("User Input")}</Text>: {t(`"What's the weather like in London tomorrow?"`)}
                    </li>
                    <li>
                        <Text fw={500} component="span">{t("Topic Recognition")}</Text>
                        <ol>
                            <li>
                                {t(`"Check Weather"`)}
                            </li>
                            <li>
                                {t(`"Location Inquiry"`)}
                            </li>
                            <li>
                                {t(`"Time-related Query"`)}
                            </li>
                        </ol>
                    </li>
                    <li>
                        <Text fw={500} component="span">{t("Confidence Scores")}</Text>
                        <ol>
                            <li>
                                {t(`"Check Weather"`)} - 0.85
                            </li>
                            <li>
                                {t(`"Location Inquiry"`)} - 0.75
                            </li>
                            <li>
                                {t(`"Time-related Query"`)} - 0.68
                            </li>
                        </ol>
                    </li>
                </ul>
                {t("In this example, the Test Section helps assess the model's proficiency in recognizing topics, offering a transparent view of the conversational assistant's performance and aiding developers in refining its capabilities.")}
            </Spoiler>
            <ProjectBuildRetriever project={project}>
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <TextInput
                        placeholder={t("I want to book a flight to Paris...")}
                        label={t("Sentence")}
                        rightSectionWidth={42}
                        rightSection={
                            <ActionIcon size={24} radius="xl" color="blue" variant="filled" type="submit">
                                <IconArrowRight style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
                            </ActionIcon>
                            }
                        {...form.getInputProps('text')}
                    />
                </form>
                <Flex mt="lg">
                    <LoadingOverlay visible={loading}/>
                    {intentRanking.length > 0 && <Table withTableBorder withRowBorders withColumnBorders captionSide="top">
                        <Table.Caption>{t("Results")}</Table.Caption>
                        <Table.Thead>
                            <Table.Tr>
                                <Table.Th>{t("Rank")}</Table.Th>
                                <Table.Th>{t("Topic")}</Table.Th>
                                <Table.Th>{t("Confidence")}</Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody ref={parent}>
                            {intentRanking.map((intent: any, index) => {
                                return (
                                    <Table.Tr key={index} >
                                        <Table.Td width={40} align="center">{index + 1}</Table.Td>
                                        <Table.Td align="center">
                                            {intent["intent"]}
                                        </Table.Td>
                                        <Table.Td width={40} align="center">{(intent["confidence"] * 100).toFixed(0)}%</Table.Td>
                                    </Table.Tr>
                                )
                            })}
                        </Table.Tbody>
                    </Table>}
                </Flex>
            </ProjectBuildRetriever>
        </>
    )
}

export default ProjectTest
