//
//
//  Admin
//
//

import {useUser} from "../contexts/AuthContext.tsx";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import AdminLayout from "../layouts/AdminLayout.tsx";

function Admin() {
    const {user} = useUser()
    const location = useLocation()

    if (!user?.is_admin) {
        return <Navigate to="/login" state={{ from: location }} />
    }

    return (
        <AdminLayout>
            <Outlet/>
        </AdminLayout>
    )
}

export default Admin
