//
//
//  Documents Project Home
//
//

import { Flex, LoadingOverlay, rem, Title, Spoiler, Text, List, SimpleGrid, Paper, ThemeIcon } from "@mantine/core";
import { useSocket } from "../../contexts/SocketContext";
import classes from "./Home.module.css"
import { useEffect, useState, useRef } from "react";
import {Socket} from "socket.io-client";
import {Link} from "react-router-dom";
import Api from "../../api.ts";
import { Build, Source, DocumentsProject, DocumentsProjectDocumentsInfo } from "../../interfaces.ts";
import { useApiErrorHandler } from "../../hooks.ts";
import {
    IconAlertCircle,
    IconFiles,
    IconRocket,
    IconCircleCheck,
    IconCircleDashed, IconHelpCircle,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

function DocumentsProjectHome({project, explore}: {project: DocumentsProject, explore: boolean}) {
    const handleError = useApiErrorHandler()
    const { t } = useTranslation();
    const {socket}: {socket: Socket} = useSocket()
    const [loading, setLoading] = useState(true)
    const [numSources, setNumSources] = useState<number>(NaN)
    const [numBuilds, setNumBuilds] = useState<number>(NaN)
    const [atLeast1Source, setAtLeast1Source] = useState(false)
    const [createYourFirstSuccessfulBuild, setCreateYourFirstSuccessfulBuild] = useState<boolean>(false)
    const numSourcesRef = useRef<number>(NaN)
    const numBuildsRef = useRef<number>(NaN)

    useEffect(() => {
        Api.getInfoProject(project.id)
            .then((info: DocumentsProjectDocumentsInfo) => {
                setNumSources(info.sources.total)
                setNumBuilds(info.builds.total)
                numSourcesRef.current = info.sources.total
                numBuildsRef.current = info.builds.total
                setAtLeast1Source(info.sources.total >= 1)
                setCreateYourFirstSuccessfulBuild(info.builds.success > 0)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoading(false)
            })
    }, [project.id])


    useEffect(() => {
        function onSourceAdd(added: Source) {
            console.log('source added', added)
            if (added.project.id === project.id) {
                numSourcesRef.current += 1
                setNumSources(numSourcesRef.current)
            }
        }

        function onSourceDelete(deleted: Source) {
            if (deleted.project.id === project.id) {
                numSourcesRef.current -= 1
                setNumSources(numSourcesRef.current)

            }
        }

        function onBuildAdd(added: Build) {
            if (added.project.id === project.id) {
                numBuildsRef.current += 1
                setNumBuilds(numBuildsRef.current)
            }
        }

        function onBuildDelete(deleted: Build) {
            if (deleted.project.id === project.id) {
                numBuildsRef.current -= 1
                setNumBuilds(numBuildsRef.current)

            }
        }

        // noinspection DuplicatedCode
        socket.on("source:add", onSourceAdd)
        socket.on("build:add", onBuildAdd)

        socket.on("source:delete", onSourceDelete)
        socket.on("build:delete", onBuildDelete)

        return () => {
            socket.off("source:add", onSourceAdd)
            socket.off("build:add", onBuildAdd)

            socket.off("source:delete", onSourceDelete)
            socket.off("build:delete", onBuildDelete)
        }
    }, [project.id, socket])

    const stats = [
        {icon: IconFiles, color: 'blue', label: t("Sources"), value: numSources, to: "sources"},
        {icon: IconRocket, color: 'orange', label: t("Builds"), value: numBuilds, to: "builds"},
    ]

    const firstSteps = [
        {label: t("Add at least 1 source"), status: atLeast1Source, required: true},
        {label: t("Create your first successful build"), status: createYourFirstSuccessfulBuild, required: true},
    ]

    if (loading) {
        return (
            <LoadingOverlay visible loaderProps={{size: "xl", variant: "oval"}}/>
        )
    }

    return (
        <>
            <Flex
                justify="space-between"
                align="center"
                direction="row"
                mt={rem(50)}
                mb={rem(30)}
            >
                <Title size="h1">{t("Home")}</Title>
            </Flex>
            <Spoiler mb="xl" maxHeight={50} showLabel={t("Show more")} hideLabel={t("Hide")}>
                {t("In the context of document projects in MAIA Studio, a project serves as an organized unit that allows you to upload and analyze documents using conversational AI technology. It provides features such as adding sources (documents), building models, and managing the lifecycle of your document-based conversational assistant. This centralized hub helps streamline the process of creating, training, testing, and deploying your assistant for answering questions related to the content within those documents.")}
                <br/>
                <Text mt={12} mb={4} fw={600}>{t("Example")}:</Text>
                <ol>
                    <li>
                        <Text component="span" fw={500}>{t("Create a Project")}</Text>: {t("Developers initiate a new project, defining the sources of informations they are going to train the model with.")}
                    </li>
                    <li>
                        <Text component="span" fw={500}>{t("Training Builds")}</Text>: {t("Builds are created and refined through iterative training cycles.")}
                    </li>
                    <li>
                        <Text component="span" fw={500}>{t("Interactive Testing")}</Text>: {t("The playground within the project is used for interactive testing and fine-tuning.")}
                    </li>
                    <li>
                        <Text component="span" fw={500}>{t("Collaboration")}</Text>: {t("The share feature is employed to share the conversational assistant with stakeholders for collaborative testing and feedback.")}
                    </li>
                </ol>
                {t("A project in conversational assistant development acts as a central hub, streamlining the development process and providing a comprehensive framework for managing the various components critical to a conversational assistant's functionality.")}
            </Spoiler>
            <div className={classes.wrapper}>
                <main className={classes.main}>
                    <SimpleGrid cols={2} spacing="xl" verticalSpacing="xl">
                        {stats.map((stat, index) => {
                            if (!explore) {
                                return (
                                    <Paper component={Link} to={stat.to} key={index} className={classes.stat} radius="md" shadow="md" p="xs">
                                        <Flex justify="center" direction="column" align="center" gap={10}>
                                            <stat.icon
                                                size={32}
                                                color={stat.color}
                                                className={classes.icon}
                                                stroke={1.5}
                                            />
                                            <div className={classes.statText}>
                                                <p className={classes.label}>{stat.label}</p>
                                                <p className={classes.value}>{stat.value}</p>
                                            </div>
                                        </Flex>
                                    </Paper>
                                )
                            } else {
                                return (
                                    <Paper key={index} className={classes.stat} radius="md" shadow="md" p="xs">
                                        <Flex justify="center" direction="column" align="center" gap={10}>
                                            <stat.icon
                                                size={32}
                                                color={stat.color}
                                                className={classes.icon}
                                                stroke={1.5}
                                            />
                                            <div className={classes.statText}>
                                                <p className={classes.label}>{stat.label}</p>
                                                <p className={classes.value}>{stat.value}</p>
                                            </div>
                                        </Flex>
                                    </Paper>
                                )
                            }
                        })}
                    </SimpleGrid>
                </main>
                {!explore &&
                    <aside className={classes.aside}>
                        <Title size="h3" mb="md">{t("First steps")}</Title>
                        <List
                            spacing="lg"
                            size="sm"
                            center
                        >
                            {firstSteps.map((step, index) => {
                                let icon, color
                                if (step.status) {
                                    icon = <IconCircleCheck style={{ width: '100%', height: '70%' }} radius="xl"/>
                                    color = "green"
                                } else if (!step.status && !step.required) {
                                    icon = <IconHelpCircle style={{ width: '100%', height: '70%' }} radius="xl"/>
                                    color = "gray"
                                } else if (!step.status && step.required) {
                                    icon = <IconAlertCircle style={{ width: '100%', height: '70%' }} radius="xl"/>
                                    color = "red"
                                } else {
                                    icon = <IconCircleDashed style={{ width: '100%', height: '70%' }} radius="xl"/>
                                    color = "blue"
                                }
                                return (
                                    <List.Item
                                        key={index}
                                        icon={
                                            <ThemeIcon color={color} size={28} radius="xl">
                                                {icon}
                                            </ThemeIcon>
                                        }
                                    >
                                        {step.label}
                                    </List.Item>
                                )
                            })}
                        </List>
                    </aside>
                }
            </div>
        </>
    )
}

export default DocumentsProjectHome
