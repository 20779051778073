//
//
//  Project Response Audio Player
//
//


import {useRef, useState} from "react";
import {Project} from "../interfaces.ts";
import Api from "../api.ts";
import {Button} from "@mantine/core";
import {IconPlayerPlayFilled, IconPlayerStopFilled} from "@tabler/icons-react";
import { useApiErrorHandler } from "../hooks.ts";
import { useTranslation } from "react-i18next";

function ProjectResponseAudioPlayer({text, project}: {text: string, project: Project}) {
    const { t } = useTranslation();
    const [playLoading, setPlayLoading] = useState(false)
    const [audioPlaying, setAudioPlaying] = useState(false)
    const handleError = useApiErrorHandler()
    const audioRef = useRef(null)

    function onClickPlay() {
        const audioElem: HTMLAudioElement = audioRef.current!

        if (audioPlaying) {
            audioElem.src = ""
            audioElem.pause()
            setAudioPlaying(false)
        } else {
            setPlayLoading(true)

            Api.createProjectTTS(project.id, text)
                .then(response => {
                    audioElem.src = response["audio_url"]
                    audioElem.play()
                        .then(() => {
                            setPlayLoading(false)
                            setAudioPlaying(true)
                        })
                        .catch((err) => {
                            console.error(err);
                            handleError(err)
                            setPlayLoading(false)
                        })
                }).catch((err) => {
                    console.error(err);
                    handleError(err)
                })
        }
    }

    function onAudioEnded() {
        setAudioPlaying(false)
    }

    return (
        <>
            <Button loading={playLoading} onClick={onClickPlay} variant="light" leftSection={audioPlaying ? <IconPlayerStopFilled size={16}/>: <IconPlayerPlayFilled size={16} />} size="xs">
                {audioPlaying
                    ? t("Stop")
                    : t("Play")
                }
            </Button>
            <audio ref={audioRef} onEnded={onAudioEnded}></audio>
        </>
    )
}

export default ProjectResponseAudioPlayer
