//
//
//  Project Settings
//
//

import {Project} from "../../interfaces.ts";
import {useOutletContext} from "react-router-dom";
import ProjectSettingsCustom from "./Custom.tsx";
import ProjectSettingsDocuments from "./Documents.tsx";
import { useTranslation } from "react-i18next";

function ProjectSettings() {
    const { t } = useTranslation()
    const [project]: [Project] = useOutletContext()

    if (project.type === "CUSTOM") {
        return <ProjectSettingsCustom/>
    } else if (project.type === "DOCUMENTS") {
        return <ProjectSettingsDocuments/>
    } else {
        throw new Error(t(`Unexpected project type`) + `: ${project.type}`)
    }
}

export default ProjectSettings
