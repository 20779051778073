//
//
//  Settings Account
//
//

import {Button, Checkbox, Flex, Group, PasswordInput, rem, Stack, Text, TextInput, Title} from "@mantine/core";
import MediaUpload from "../components/MediaUpload.tsx";
import {megabytes} from "../utils.ts";
import {useUser} from "../contexts/AuthContext.tsx";
import {useEffect, useState} from "react";
import {hasLength, matchesField, useForm} from "@mantine/form";
import Api, {RequestError} from "../api.ts";
import {notifications} from "@mantine/notifications";
import {IconX} from "@tabler/icons-react";
import { useApiErrorHandler } from "../hooks.ts";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../components/LanguageSwitcher.tsx";

function SettingsAccount() {
    const { t } = useTranslation()
    const {user} = useUser()
    const handleError = useApiErrorHandler()
    const [submitPasswordLoading, setSubmitPasswordLoading] = useState(false)
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [uploadAvatarLoading, setUploadAvatarLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)

    const form = useForm({
        initialValues: {
            name: user!.name,
            email: user!.email,
            email_notification_build_success: user!.email_notification_build_success,
            email_notification_build_error: user!.email_notification_build_error,
        },
        validate: {
            name: value => (value.length < 2 ? t('Name must have at least 2 letters') : null),
            email: value => (/^\S+@\S+$/.test(value) ? null : t('Invalid email')),
        },
    })

    const passwordForm = useForm({
        initialValues: {
            current_password: "",
            new_password: "",
            confirm_new_password: ""
        },
        validate: {
            confirm_new_password: matchesField('new_password', t('Passwords are not the same')),
            new_password: hasLength({ min: 8 }, t('Password must have 8 or more characters'))
        }
    })

    useEffect(() => {
        form.setValues({
            name: user?.name,
            email: user?.email,
            email_notification_build_success: user!.email_notification_build_success,
            email_notification_build_error: user!.email_notification_build_error,
        })
        passwordForm.setValues({
            current_password: "",
            new_password: "",
            confirm_new_password: ""
        })
    }, [user])

    function onSubmit(values: any) {
        setSubmitLoading(true)

        Api.updateCurrentUser(values)
            .then(() => {
                setSubmitLoading(false)
                notifications.show({
                    title: t("User updated"),
                    message: t('User updated successfully'),
                    color: "green"
                })
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setSubmitLoading(false)
            })
    }

    function onDropAvatar(file: File) {
        setUploadAvatarLoading(true)
        Api.createUserAvatar(file)
            .then(() => {
                setUploadAvatarLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setUploadAvatarLoading(false)
            })
    }

    function onDeleteAvatar() {
        setUploadAvatarLoading(true)
        Api.deleteUserAvatar()
            .then(() => {
                setUploadAvatarLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setUploadAvatarLoading(false)
            })
    }

    function onPasswordSubmit(values: any) {
        setSubmitPasswordLoading(true)
        Api.updatePassword(values["current_password"], values["new_password"])
            .then(() => {
                setSubmitPasswordLoading(false)
                notifications.show({
                    title: t("Password updated"),
                    message: t('Password updated successfully'),
                    color: "green"
                })
            }).catch(err => {
                if (err instanceof RequestError && err.response.status === 409) {
                    notifications.show({
                        title: t("Wrong password"),
                        message: t("Current password does not match"),
                        color: "red",
                        icon: <IconX style={{ width: rem(20), height: rem(20) }} />
                    })
                    setSubmitPasswordLoading(false)
                    handleError(err)
                }
        })
    }

    return (
        <>
            <Flex
                justify="space-between"
                align="center"
                direction="row"
                mt={rem(30)}
                mb={rem(30)}
            >
                <Title size="h1">{t("Account")}</Title>
            </Flex>
            <Flex direction="column" style={{width: "25%"}} mb="md">
                <Title size="h6" mb={6}>{t("Avatar")}</Title>
                <MediaUpload
                    loading={uploadAvatarLoading}
                    onDrop={onDropAvatar}
                    onDelete={onDeleteAvatar}
                    maxSize={megabytes(10)}
                    acceptImages={true}
                    acceptVideos={false}
                    image={user?.avatar}
                />
            </Flex>

            <form onSubmit={form.onSubmit(onSubmit)}>
                <TextInput
                    withAsterisk
                    required
                    label={t("Name")}
                    {...form.getInputProps('name')}
                />
                <TextInput
                    mt="md"
                    required
                    type="email"
                    withAsterisk
                    label={t("Email")}
                    placeholder="your@email.com"
                    {...form.getInputProps('email')}
                />

                
                <Title size="h4" mt="lg">{t("Email Notifications")}</Title>
                <Text mt="xs" mb="sm" size="sm">{t("Email me when")}:</Text>
                <Stack>
                    <Checkbox
                        label={t("Build succeed")}
                        {...form.getInputProps('email_notification_build_success', { type: "checkbox" })}
                    />
                    <Checkbox
                        label={t("Build fails")}
                        {...form.getInputProps('email_notification_build_error', { type: "checkbox" })}
                    />
                </Stack>
                <Group justify="flex-start" mt="xl">
                    <Button loading={submitLoading} type="submit">{t("Save")}</Button>
                </Group>
            </form>

            <Title size="h4" mt="lg">{t("Appearance")}</Title>
            <LanguageSwitcher/>
            
            <Title size="h4" mt="lg">{t("Password")}</Title>
            <form onSubmit={passwordForm.onSubmit(onPasswordSubmit)}>
                <Stack>
                    <PasswordInput
                        label={t("Current Password")}
                        required
                        {...passwordForm.getInputProps('current_password')}
                    />
                    <PasswordInput
                        label={t("New password")}
                        required
                        visible={passwordVisible}
                        onVisibilityChange={() => setPasswordVisible(!passwordVisible)}
                        {...passwordForm.getInputProps('new_password')}
                    />
                    <PasswordInput
                        label={t("Confirm new password")}
                        required
                        visible={passwordVisible}
                        onVisibilityChange={() => setPasswordVisible(!passwordVisible)}
                        {...passwordForm.getInputProps('confirm_new_password')}
                    />
                </Stack>
                <Group justify="flex-start" mt="xl">
                    <Button loading={submitPasswordLoading} type="submit">{t("Update Password")}</Button>
                </Group>
            </form>
        </>
    )
}

export default SettingsAccount
