//
//
//  User Menu
//
//

import {Avatar, Group, Menu, UnstyledButton, Text} from "@mantine/core"
import {
    IconChevronDown, IconTelescope,
    IconLogout, IconNotebook, IconSettings, IconShieldLock,
} from "@tabler/icons-react"
import cx from 'clsx';
import {useState} from "react"
import {Link, useNavigate} from "react-router-dom";
import {useUser} from "../contexts/AuthContext.tsx";
import classes from "./UserMenu.module.css";
import { useTranslation } from "react-i18next";


function UserMenu() {
    const { t } = useTranslation()
    const {user, setUser} = useUser()
    const navigate = useNavigate()
    const [userMenuOpened, setUserMenuOpened] = useState(false)

    function onLogout() {
        sessionStorage.removeItem("accessToken")
        localStorage.removeItem("accessToken")
        setUser(null)
        navigate("/login")
    }

    return (
        <Menu
            shadow="md"
            width={260}
            position="bottom-end"
            transitionProps={{ transition: 'pop-top-right' }}
            onClose={() => setUserMenuOpened(false)}
            onOpen={() => setUserMenuOpened(true)}
        >
            <Menu.Target>
                <UnstyledButton
                    className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
                >
                    <Group>
                        <Avatar src={user?.avatar} alt="Avatar" radius="xl" />
                        <Text className={classes.userName} size="sm" mr={3}>
                            {user?.name || ""}
                        </Text>
                        <IconChevronDown size={12} stroke={1.5} />
                    </Group>
                </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Item leftSection={<IconNotebook  size={14} stroke={1.5} />} component={Link} to="/projects">{t("My Projects")}</Menu.Item>
                <Menu.Item leftSection={<IconTelescope  size={14} stroke={1.5} />} component={Link} to="/projects/explore">{t("Explore Projects")}</Menu.Item>
                {user?.is_admin && <Menu.Item leftSection={<IconShieldLock  size={14} stroke={1.5} />} component={Link} to="/admin">{t("Admin")}</Menu.Item>}
                <Menu.Item leftSection={<IconSettings  size={14} stroke={1.5} />} component={Link} to="/settings">{t("Settings")}</Menu.Item>
                <Menu.Divider />
                <Menu.Item leftSection={<IconLogout  size={14} stroke={1.5} />} onClick={onLogout}>{t("Logout")}</Menu.Item>
            </Menu.Dropdown>
        </Menu>
    )
}

export default UserMenu
