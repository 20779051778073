//
//
//  Project Responses
//
//

import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Button, Flex, Modal, rem, Spoiler, Textarea, TextInput, Title, Container } from "@mantine/core";
import { IconCirclePlus } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import Api from "../api.ts";
import { Project, Response } from "../interfaces.ts";
import ProjectResponseDrawer from "../components/ProjectResponseDrawer.tsx";
import ProjectResponsesDataTable from "../components/ProjectResponsesDataTable.tsx";
import {hasProjectWritePermission, onResourceUpdate, sortByUpdatedAt } from "../utils.ts";
import { Socket } from "socket.io-client";
import { useSocket } from "../contexts/SocketContext.tsx";
import ProjectResponseAudioPlayer from "../components/ProjectResponseAudioPlayer.tsx";
import { notifications } from "@mantine/notifications";
import PaginationControl from "../components/PaginationControl.tsx";
import { useApiErrorHandler } from "../hooks.ts";
import { useUser } from "../contexts/AuthContext.tsx";
import { useTranslation } from "react-i18next";

interface ResponseForm {
    name: string
    response: string
}

function ProjectResponses() {
    const { t } = useTranslation();
    const pageLength = 5
    const navigate = useNavigate()
    const handleError = useApiErrorHandler()
    const { user } = useUser()
    const textRef = useRef(null)
    const { socket }: { socket: Socket } = useSocket()
    const location = useLocation()
    const [loading, setLoading] = useState(true)
    const [updateLoading, setUpdateLoading] = useState(false)
    const [project]: [Project] = useOutletContext()
    const [deleteLoading, setDeleteLoading] = useState(false)
    const responsesRef = useRef<Response[]>([])
    const [responses, setResponses] = useState<Response[]>([])
    const [createResponseOpen, setCreateResponseOpen] = useState(false)
    const [createResponseLoading, setCreateResponseLoading] = useState(false)
    const [selectedResponse, setSelectedResponse] = useState<Response | null>(location.state?.selected)
    const [totalResponses, setTotalResponses] = useState(0)
    const [page, setPage] = useState(1)

    useEffect(() => {
        if (!hasProjectWritePermission(project, user)) {
            navigate("*")
        }
    }, [project, user])

    function loadResponsesPage() {
        setLoading(true)
        Api.getResponsesPage(project.id, page, pageLength)
            .then(pageResponses => {
                if (pageResponses["items"].length < 1 && page > 1) {
                    setTotalResponses(totalResponses-1)
                } else {
                    responsesRef.current = pageResponses["items"]
                    setResponses(sortByUpdatedAt(responsesRef.current))
                    setTotalResponses(pageResponses["total"])
                    setLoading(false)
                }
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoading(false)
            })
    }

    useEffect(() => {
        loadResponsesPage()
    }, [project.id, page])

    useEffect(() => {
        function onAdd(added: Response) {
            const newResponse = {...added, _isNew: true}
            responsesRef.current = [newResponse, ...responsesRef.current]
            setResponses(responsesRef.current)
        }

        function onUpdate(updated: Response) {
            if (updated.project.id === project.id) {
                responsesRef.current = onResourceUpdate(updated, responsesRef.current)
                setResponses(responsesRef.current)

                if (selectedResponse?.id === updated.id) {
                    setSelectedResponse(updated)
                }
            }
        }

        function onDelete(deleted: Response) {
            if (deleted.project.id === project.id) {
                loadResponsesPage()
            }
            if (deleted.id === selectedResponse?.id) {
                setSelectedResponse(null)
            }
        }

        // noinspection DuplicatedCode
        socket.on("response:add", onAdd)
        socket.on("response:update", onUpdate)
        socket.on("response:delete", onDelete)

        return () => {
            socket.off("response:add", onAdd)
            socket.off("response:update", onUpdate)
            socket.off("response:delete", onDelete)
        }
    }, [project.id, socket, page])

    const createResponseForm = useForm({
        initialValues: {
            name: "",
            response: ""
        }
    })

    function onSubmitCreateResponse(values: ResponseForm) {
        setCreateResponseLoading(true)
        Api.createResponse(project.id, values.name, values.response)
            .then(response => {
                setCreateResponseLoading(false)
                setCreateResponseOpen(false)
                createResponseForm.reset()
                setSelectedResponse(response)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setCreateResponseLoading(false)
            })
    }

    function onCloseCreateResponse() {
        setCreateResponseOpen(false)
        createResponseForm.reset()
    }

    function onDeleteResponse(response: Response) {
        setDeleteLoading(true)
        Api.deleteResponse(response.id)
            .then(() => {
                setDeleteLoading(false)
                setSelectedResponse(null)
            }).catch(err => {
                if (err.response.status == 409) {
                    // Response used in story
                    err.response.json()
                        .then((data: any) => {
                            if (data["detail"]["code"] === "used_by_story") {
                                notifications.show({
                                    title: t("Error"),
                                    message: t('Response is in use by story') + ` ${data["detail"]["story"]["name"]}. ` + t('Please delete story before.'),
                                    color: "red"
                                })
                                setDeleteLoading(false)
                            }
                        })
                } else {
                    console.error(err)
                    handleError(err)
                    setDeleteLoading(false)
                }
            })
    }

    function onUpdateResponse(response: Response, newValues: any) {
        setUpdateLoading(true)
        Api.updateResponse(response.id, newValues)
            .then(updatedResponse => {
                const newResponses = responses.map((res: Response) => {
                    if (response.id === res.id) {
                        return updatedResponse
                    } else {
                        return res
                    }
                })
                setResponses(sortByUpdatedAt(newResponses))
                setUpdateLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setUpdateLoading(false)
            })
    }

    function onResponseSelection(response: Response) {
        setSelectedResponse(response)
    }

    function onCloseResponseSelection() {
        setSelectedResponse(null)
    }

    const textValue = (textRef.current as (HTMLTextAreaElement | null))?.value || ""

    return (
        <>
            <Container>
                <Flex
                    justify="space-between"
                    align="center"
                    direction="row"
                    mt={rem(50)}
                    mb={rem(30)}
                >
                    <Title size="h1">{t("Responses")}</Title>

                    <Button
                        onClick={() => setCreateResponseOpen(true)}
                        leftSection={<IconCirclePlus size={16} />}
                    >
                        {t("Create Response")}
                    </Button>
                </Flex>
                <Spoiler mb="xl" maxHeight={50} showLabel={t("Show more")} hideLabel={t("Hide")}>
                    {t("In conversational assistant development, responses are the replies generated by the conversational assistant in response to user inputs. Crafted during training, these responses are tailored to match identified topics, ensuring the conversational assistant provides helpful and contextually appropriate information.")}
                    <br />
                    {t("They are mapped to specific topics during the training phase. Understanding response generation is crucial for building conversational assistants that effectively communicate and assist users.")}
                </Spoiler>
                <ProjectResponsesDataTable
                    responses={responses}
                    loading={loading}
                    onCreate={() => setCreateResponseOpen(true)}
                    onSelection={onResponseSelection}
                />
                <PaginationControl
                    totalElements={totalResponses}
                    page={page}
                    pageLength={pageLength}
                    onChange={setPage}
                />
            </Container>
            <Modal opened={createResponseOpen} onClose={onCloseCreateResponse} title={t("Create Response")}>
                <form onSubmit={createResponseForm.onSubmit(onSubmitCreateResponse)}>
                    <TextInput
                        withAsterisk
                        label={t("Name")}
                        required
                        placeholder={t("Super duper response")}
                        mb="sm"
                        {...createResponseForm.getInputProps("name")}
                    />
                    <Textarea
                        label={t("Response")}
                        placeholder={t("What should I say?")}
                        required
                        autosize
                        ref={textRef}
                        withAsterisk
                        {...createResponseForm.getInputProps("response")}
                    />
                    <Flex justify="space-between" mt="sm">
                        <Button mt="lg" type="submit" loading={createResponseLoading}>{t("Submit")}</Button>
                        <ProjectResponseAudioPlayer
                            project={project}
                            text={textValue}
                        />
                    </Flex>
                </form>
            </Modal>
            {selectedResponse != null &&
                <ProjectResponseDrawer
                    response={selectedResponse}
                    opened={true}
                    onClose={onCloseResponseSelection}
                    onDelete={onDeleteResponse}
                    deleteLoading={deleteLoading}
                    onUpdate={onUpdateResponse}
                    updateLoading={updateLoading}
                />
            }
        </>
    )
}

export default ProjectResponses
