//
//
//  Common components for Data Tables
//
//

import {Button, Skeleton, Text, Title} from "@mantine/core";
import classes from "./DataTable.module.css";

export function DataTableLoading() {
    return (
        <>
            <Skeleton height={18} radius="xl" />
            <Skeleton height={18} mt={18} radius="xl" />
            <Skeleton height={18} mt={18} width="70%" radius="xl" />
        </>
    )
}

export function DataTableEmpty({title, subtitle, action, onAction, actionLoading=false}: {onAction?: () => void, title: string, subtitle: string, action?: string, actionLoading?: boolean}) {
    return (
        <div className={classes.emptyWrapper}>
            <Title size="h2" mb="xs">{title}</Title>
            <Text mb="lg">{subtitle}</Text>
            {action && <div>
                <Button loading={actionLoading} onClick={onAction}>{action}</Button>
            </div>}
        </div>
    )
}
