//
//
//  Project Deployments
//
//

import phoneUrl from "../assets/phone.svg"
import alexaUrl from "../assets/alexa.png"
import telegramUrl from "../assets/telegram.svg"
import {Button, Card, Flex, rem, SimpleGrid, Title, Image, Text, Spoiler, Anchor} from "@mantine/core";
import {useEffect, useState} from "react";
import {Project} from "../interfaces.ts";
import {useOutletContext} from "react-router-dom";
import Api from "../api.ts";
import {notifications} from "@mantine/notifications";
import ProjectBuildRetriever from "../components/ProjectBuildRetriever.tsx";
import Countdown from "react-countdown";
import { useApiErrorHandler } from "../hooks.ts";

function ProjectDeployments() {
    const [project]: [Project] = useOutletContext()
    const handleError = useApiErrorHandler()
    const [telegramBotName, setTelegramBotName] = useState(null)
    const [alexaLoading, setAlexaLoading] = useState(true)
    const [phoneLoading, setPhoneLoading] = useState(true)
    const [telegramLoading, setTelegramLoading] = useState(true)
    const [alexaInUse, setAlexaInUse] = useState(false)
    const [phoneInUse, setPhoneInUse] = useState(false)
    const [telegramInUse, setTelegramInUse] = useState(false)
    const [alexaAvailableAt, setAlexaAvailableAt] = useState<Date | null>(null)
    const [phoneAvailableAt, setPhoneAvailableAt] = useState<Date | null>(null)
    const [telegramAvailableAt, setTelegramAvailableAt] = useState<Date | null>(null)

    useEffect(() => {
        Api.aboutPhoneDeployment(project.id)
            .then(deployment => {
                setPhoneInUse(deployment.available ? false : deployment.in_use_by.id === project.id)
                setPhoneAvailableAt(deployment.available ? null : new Date(deployment.available_at))
                setPhoneLoading(false)
            })
        Api.aboutTelegramDeployment(project.id)
            .then(deployment => {
                setTelegramInUse(deployment.available ? false : deployment.in_use_by.id === project.id)
                setTelegramAvailableAt(deployment.available ? null : new Date(deployment.available_at))
                setTelegramLoading(false)
            })
        Api.aboutAlexaDeployment(project.id)
            .then(deployment => {
                setAlexaInUse(deployment.available ? false : deployment.in_use_by.id === project.id)
                setAlexaAvailableAt(deployment.available ? null : new Date(deployment.available_at))
                setAlexaLoading(false)
            })
    }, [project.id])

    function deployToPhone() {
        setPhoneLoading(true)
        Api.createPhoneDeployment(project.id)
            .then(data => {
                setPhoneAvailableAt(new Date(data["available_at"]))
                setPhoneInUse(true)
                setPhoneLoading(false)
                notifications.show({
                    title: 'Project deployed',
                    message: 'Call 914 231 874 to interact with your model',
                    color: "green"
                })
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setPhoneLoading(false)
            })
    }

    function deployToAlexa() {
        setAlexaLoading(true)
        Api.createAlexaDeployment(project.id)
            .then(data => {
                setAlexaLoading(false)
                setAlexaAvailableAt(new Date(data["available_at"]))
                setAlexaInUse(true)
                notifications.show({
                    title: 'Project deployed',
                    message: 'Just wait a few minutes and say "Alexa abre MAIA" in your Echo Show 10',
                    color: "green"
                })
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setAlexaLoading(false)
            })
    }

    function deployToTelegram() {
        setTelegramLoading(true)
        Api.createTelegramDeployment(project.id)
            .then(data => {
                setTelegramBotName(data["meta"]["bot_name"])
                setTelegramAvailableAt(new Date(data["available_at"]))
                setTelegramInUse(true)
                setTelegramLoading(false)
                notifications.show({
                    title: 'Project deployed',
                    message: getTelegramDescription(data["meta"]["bot_name"], true),
                    color: "green"
                })
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setTelegramLoading(false)
            })
    }

    function getTelegramDescription(botName: string | null, notification: boolean = false) {
        if (botName == null) {
            return "Deploy to Telegram. Create a Telegram bot and interact with it."
        } else if (notification) {
            return <>
                You will find the bot at <Anchor href={`https://t.me/${botName}`}>{`t.me/${botName}`}</Anchor>
            </>
        } else {
            return <>
                Deploy to Telegram. You will find the bot at <Anchor href={`https://t.me/${botName}`}>{`t.me/${botName}`}</Anchor>
            </>
        }
    }

    function isDeploymentDisabled(inUse: boolean, availableAt: Date | null) {
        if (project.current_build == null) {
            return true
        }
        if (inUse) {
            return true
        }

        return availableAt != null;
    }

    function deploymentNode(inUse: boolean, availableAt: Date | null, setAvailableAt: (value: Date | null) => void, setInUse: (value: boolean) => void) {
        function onComplete() {
            setAvailableAt(null)
            setInUse(false)
        }

        if (availableAt == null) {
            return <span>Deploy</span>
        } else if (inUse) {
            return (
                <>
                    <span>Deployed until&nbsp;</span>
                    <Countdown date={availableAt} onComplete={onComplete} zeroPadTime={0} zeroPadDays={0} daysInHours={true}/>
                </>
            )
        } else {
            return (
                <>
                    <span>Available in&nbsp;</span>
                    <Countdown date={availableAt} onComplete={onComplete} zeroPadTime={0} zeroPadDays={0} daysInHours={true}/>
                </>
            )
        }

    }

    return (
        <>
            <Flex
                justify="space-between"
                align="center"
                direction="row"
                mt={rem(50)}
                mb={rem(30)}
            >
                <Title size="h1">Deployments</Title>
            </Flex>
            <Spoiler mb="xl" maxHeight={50} showLabel="Show more" hideLabel="Hide">
                In the landscape of conversational assistants development, the deployment section serves as a gateway to extend the reach of a trained model beyond the development environment. This section empowers developers to test and integrate their conversational assistants with external devices, such as smartphones or voice-enabled platforms like Alexa.
            </Spoiler>
            <ProjectBuildRetriever project={project}>
                <SimpleGrid cols={3}>
                    <Card withBorder radius="md" padding="md">
                        <Card.Section>
                            <Image
                                src={telegramUrl}
                                height={160}
                                alt="Deploy on Telegram"
                            />
                        </Card.Section>
                        <Title size="h2" mt="md" mb="sm">Telegram</Title>

                        <Text size="sm" c="dimmed">
                            {getTelegramDescription(telegramBotName)}
                        </Text>

                        <Button disabled={isDeploymentDisabled(telegramInUse, telegramAvailableAt)} loading={telegramLoading} onClick={deployToTelegram} variant="light" color="blue" fullWidth mt="md" radius="md">
                            {deploymentNode(telegramInUse, telegramAvailableAt, setTelegramAvailableAt, setTelegramInUse)}
                        </Button>
                    </Card>
                    <Card withBorder radius="md" padding="md">
                        <Card.Section>
                            <Image
                                src={phoneUrl}
                                height={160}
                                alt="Deploy on Phone"
                            />
                        </Card.Section>
                        <Title size="h2" mt="md" mb="sm">Phone</Title>

                        <Text size="sm" c="dimmed">
                            Deploy to Phone Number. Just call <span style={{whiteSpace: "nowrap"}}>914 231 874</span>.
                        </Text>

                        <Button disabled={isDeploymentDisabled(phoneInUse, phoneAvailableAt)} loading={phoneLoading} onClick={deployToPhone} variant="light" color="blue" fullWidth mt="md" radius="md">
                            {deploymentNode(phoneInUse, phoneAvailableAt, setPhoneAvailableAt, setPhoneInUse)}
                        </Button>
                    </Card>
                    <Card withBorder radius="md" padding="md">
                        <Card.Section>
                            <Image
                                src={alexaUrl}
                                height={160}
                                alt="Deploy on Alexa"
                            />
                        </Card.Section>
                        <Title size="h2" mt="md" mb="sm">Alexa</Title>

                        <Text size="sm" c="dimmed">
                            Deploy to Echo Show 10. Just say <span style={{whiteSpace: "nowrap"}}>"Alexa abre MAIA"</span>.
                        </Text>

                        <Button disabled={isDeploymentDisabled(alexaInUse, alexaAvailableAt) || !project.current_build?.capabilities_videochat} onClick={deployToAlexa} loading={alexaLoading} variant="light" color="blue" fullWidth mt="md" radius="md">
                            {deploymentNode(alexaInUse, alexaAvailableAt, setAlexaAvailableAt, setAlexaInUse)}
                        </Button>
                    </Card>
                </SimpleGrid>
            </ProjectBuildRetriever>
        </>
    )
}

export default ProjectDeployments
