//
//
//  App Copy Button
//
//

import {ActionIcon, CopyButton as MantineCopyButton, Tooltip} from "@mantine/core";
import {IconCheck, IconCopy} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

function CopyButton({value, ...props}: {value: string}) {
    const { t } = useTranslation();

    return (
        <MantineCopyButton value={value} timeout={2000} {...props}>
            {({ copied, copy }) => (
            <Tooltip label={copied ? t('Copied') : t('Copy')} withArrow position="right">
                <ActionIcon color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
                    {copied ? (
                        <IconCheck size={16} />
                    ) : (
                        <IconCopy size={16} />
                    )}
                </ActionIcon>
            </Tooltip>
            )}
        </MantineCopyButton>
    )
}

export default CopyButton
