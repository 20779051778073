import { Flex, Input, Menu, Button, Loader, rem } from '@mantine/core';
import { useListState } from '@mantine/hooks';
import { IconCheck, IconChevronDown, IconSearch } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { Team } from '../interfaces';
import { useTranslation } from "react-i18next";


function ProjectSearchBarAndFilter({ teams, onSearch, onChangeOwner, onChangeType, onChangeSort, loading }: { teams: Team[], onSearch: (search: string | undefined) => void, onChangeOwner: (owner: string | undefined, teamId?: number) => void,  onChangeType: (type: string | undefined) => void, onChangeSort: (type: string | undefined) => void, loading: boolean }) {
  const { t } = useTranslation()
  const [ownerValues, setOwnerValues] = useState<{label: string, key: string, id: number | undefined}[]>([
    { label: 'All', key: 'ALL', id: undefined},
    { label: 'Me', key: 'ME', id: undefined},
  ]);

  
  const [typeValues, typeHandlers] = useListState([
    { label: t('All'), key: 'ALL' },
    { label: t('Custom'), key: 'CUSTOM' },
    { label: t('Documents'), key: 'DOCUMENTS' },
  ]);

  const [sortValues, sortHandlers] = useListState([
    { label: t('Last updated'), key: 'LAST_UPDATED' },
    { label: t('Last created'), key: 'LAST_CREATED' },
    { label: 'Name', key: 'NAME' },
  ]);

  useEffect(() => {
    const updatedOwnerValues = [
      { label: t('All'), key: 'ALL', id: undefined },
      { label: t('Me'), key: 'ME', id: undefined },
      ...teams.map((team) => ({ label: team.name, key: 'TEAM', id: team.id !== undefined ? team.id : undefined })),
    ];
    setOwnerValues(updatedOwnerValues);
  }, [teams]);


  const [selectedType, setSelectedType] = useState<string | undefined>('ALL')
  const [selectedSort, setSelectedSort] = useState<string | undefined>('LAST_UPDATED')
  const [selectedOwner, setSelectedOwner] = useState<string | undefined>('ALL')
  const [selectedTeamId, setSelectedTeamId] = useState<number | undefined>(undefined)
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  return (
    <Flex mb={10} align={'center'} gap={10} justify={'space-between'}>
      <Flex mb={10} align={'center'}  justify={'center'} style={{ width:'100%'}}>
        <Input placeholder={t("Find a project...")}
          style={{ width:'100%'}}
          leftSection={
            loading ? (
              <Loader size={24} />
            ) : (
              <IconSearch />
            )
          }
          onChange={handleInputChange}
        />
      </Flex>
      <Flex mb={10} align={'center'} gap={10} justify={'center'}>
        <Menu shadow="md" width={200} position="bottom-end">
          <Menu.Target>
            <Button variant="outline" leftSection={<IconChevronDown/>}>
                {t('Owner')}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {ownerValues.map((value) => (
              <>
              <Menu.Item
                key={value.key}
                leftSection={
                  value.key === selectedOwner ? (
                    value.id == selectedTeamId && (
                    <IconCheck style={{ width: rem(14), height: rem(14) }} />
                  )) : null
                }
                onClick={() => {
                  setSelectedOwner(value.key)
                  setSelectedTeamId(value.id)
                  if (value.key == 'ALL') {
                    onChangeOwner(undefined)
                  } else if (value.id != undefined){
                    onChangeOwner(value.key, value.id);
                  } else {
                    onChangeOwner(value.key);
                  }
                }}
              >
                {value.label}
              </Menu.Item>
              {value.key === 'ME' && <Menu.Divider />}
              </>
            ))}
          </Menu.Dropdown>
        </Menu>
        <Menu shadow="md" width={200} position="bottom-end">
          <Menu.Target>
            <Button variant="outline" leftSection={<IconChevronDown/>}>
              {t('Type')}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {typeValues.map((value) => (
              <Menu.Item
                key={value.key}
                leftSection={
                  value.key === selectedType ? (
                    <IconCheck style={{ width: rem(14), height: rem(14) }} />
                  ) : null
                }
                onClick={() => {
                  typeHandlers.setState((current) =>
                    current.map((v) => ({ ...v, checked: v.key === value.key }))
                  );
                  setSelectedType(value.key)
                  if (value.key == 'ALL') {
                    onChangeType(undefined)
                  } else {
                    onChangeType(value.key);
                  }
                }}
              >
                {value.label}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
        <Menu shadow="md" width={200} position="bottom-end">
          <Menu.Target>
            <Button variant="outline" leftSection={<IconChevronDown/>}>
              {t('Sort')}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {sortValues.map((value) => (
              <Menu.Item
                key={value.key}
                leftSection={
                  value.key === selectedSort ? (
                    <IconCheck style={{ width: rem(14), height: rem(14) }} />
                  ) : null
                }
                onClick={() => {
                  sortHandlers.setState((current) =>
                    current.map((v) => ({ ...v, checked: v.key === value.key }))
                  );
                  setSelectedSort(value.key)
                  if (value.key == 'LAST_UPDATED') {
                    onChangeSort(undefined)
                  } else {
                    onChangeSort(value.key);
                  }
                }}
              >
                {value.label}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      </Flex>
    </Flex>
  )
}


export default ProjectSearchBarAndFilter