//
//
//  Project Stories Data Table
//
//


import {Story} from "../interfaces.ts";
import {DataTableEmpty} from "./DataTable.tsx";
import classes from "./DataTable.module.css";
import {Anchor, Flex, Text, Title} from "@mantine/core";
import { DataTable } from "mantine-datatable";
import {IconEdit} from "@tabler/icons-react";
import TimeAgoLocale from "./TimeAgoLocale.tsx";
import {useAutoAnimate} from "@formkit/auto-animate/react";
import { useTranslation } from "react-i18next";


function ProjectStoriesDataTable({stories, loading, onCreate, onSelection}: {stories: Story[], loading: boolean, onCreate: () => void, onSelection: (story: Story) => void}) {
    const { t, i18n } = useTranslation()
    const [bodyRef] = useAutoAnimate<HTMLTableSectionElement>();

    function renderStoryDetails(story: Story) {

        const isNew = (story as any)._isNew == true
        const itemStyle = isNew ? { backgroundColor: "#F5FBEF", marginBottom:'1rem'} : {marginBottom:'1rem'};


        return (
            <div className={classes.row} key={story.id}  style={itemStyle}>
                <div className={classes.rowContent}>
                    <Anchor onClick={() => onSelection(story)}>
                        <Title size="h3" className={classes.rowTitle}>
                            {story.name}
                        </Title>
                    </Anchor>
                    <Flex className={classes.rowFooter}>
                        <Flex align="center" columnGap={6}>
                            <IconEdit size={12} color={"gray"}/>
                            <Text c="dimmed" size="xs">
                                <TimeAgoLocale date={new Date(story.updated_at)} language={i18n.language}/>
                            </Text>
                        </Flex>
                    </Flex>
                </div>
            </div>
        )
    }

    return (
        <div className={classes.wrapper}>
        <DataTable
            noHeader
            withRowBorders={false}
            borderColor={'white'}
            rowClassName={classes.rowTitle}
            fetching={loading}
            columns={[{ accessor: 'stories', render: renderStoryDetails }]}
            records={stories}
            bodyRef={bodyRef}
            verticalSpacing=""
            emptyState={
                stories.length === 0 && (
                <div style={{ pointerEvents: 'all', width:'100%'}}>
                <DataTableEmpty
                    title={t("You don't have any story")}
                    subtitle={t("Let's get started") + " 🚀"}
                    action={t("Create your first Story")}
                    onAction={onCreate}
                />
                </div>)
            }
            minHeight={300}
            defaultColumnProps={{
                noWrap: true,
                ellipsis: true,
            }}
        />
        </div>
    )
}

export default ProjectStoriesDataTable
