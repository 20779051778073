//
//
//  Team Settings Layout
//
//

import {Link, useLocation, useNavigate} from "react-router-dom";
import {NavbarSection, NavbarSectionItem, Team} from "../interfaces.ts";
import {IconArrowLeft, IconCreditCard, IconSettings, IconUsersGroup} from "@tabler/icons-react";
import {Anchor, AppShell, Box, Center, Container, Flex, rem, Text, Title, Button} from "@mantine/core";
import LoggedHeader from "../components/LoggedHeader.tsx";
import classes from "./SettingsLayout.module.css"
import NavbarLink from "../components/NavbarLink.tsx";
import {ReactNode, useState} from "react";
import {modals} from "@mantine/modals";
import Api from "../api.ts";
import {useApiErrorHandler} from "../hooks.ts";
import { useTranslation } from "react-i18next";


function TeamSettingsLayout({team, children}: {team: Team, children: ReactNode}) {
    const { t } = useTranslation()
    const [deleteLoading, setDeleteLoading] = useState(false)
    const {pathname} = useLocation()
    const handleError = useApiErrorHandler()
    const navigate = useNavigate()

    const pathparts = /\/teams\/\d+\/settings\/(\w*)\/?/.exec(pathname)
    let active: string
    if (!pathparts) {
        active = "team"
    } else {
        active = pathparts[1]
    }

    const sections: NavbarSection[] = [
        {
            label: undefined,
            items: [
                { icon: <IconSettings size={16} />, color: 'teal', label: t("Team"), to: `/teams/${team.id}/settings`, active: "team"},
                { icon: <IconUsersGroup size={16} />, color: 'yellow', label: t("Members"), to: `/teams/${team.id}/settings/members`, active: "members"},
                { icon: <IconCreditCard size={16} />, color: 'blue', label: t("Billing"), to: `/teams/${team.id}/settings/billing`, active: "billing"},
            ]
        }
    ]

    function deleteTeam() {
        modals.openConfirmModal({
            title: t("Delete team"),
            centered: true,
            children: (
                <Text size="sm">
                    Are you sure you want to delete {team.name}?
                    <br/>
                    All projects will be removed.
                </Text>
            ),
            labels: { confirm: t("Delete"), cancel: t("No, don't delete it") },
            confirmProps: { color: 'red' },
            onConfirm: () => {
                setDeleteLoading(true)
                Api.deleteTeam(team.id)
                    .then(() => {
                        navigate("/settings/teams")
                    }).catch((err: Error) => {
                        console.error(err)
                        handleError(err)
                        setDeleteLoading(false)
                    })
            }
        })
    }

    return (
        <AppShell
            header={{height: 60}}
            navbar={{width: 300, breakpoint: 0}}
        >
            <AppShell.Header>
                <LoggedHeader/>
            </AppShell.Header>
            <AppShell.Navbar>
                <div className={classes.header}>
                    <Flex direction="column">
                        <Flex direction="column">
                            <Title size="h3" c="dimmed">{t("Team")}</Title>
                            <Title size="h1">
                                {team.name}
                            </Title>
                        </Flex>
                    </Flex>
                    <Anchor c="dimmed" size="sm" component={Link} to="/settings/teams">
                        <Center inline>
                            <IconArrowLeft style={{width: rem(12), height: rem(12)}} stroke={1.5}/>
                            <Box ml={5}>{t("Back to teams")}</Box>
                        </Center>
                    </Anchor>
                </div>
                <Flex direction="column" mt="md" className={classes.navbarMainSection} align="space-between"
                      style={{height: "100%"}}>
                    {sections.map((section: NavbarSection, sectionIndex: number) => {
                        return (
                            <Flex direction="column" key={sectionIndex}>
                                {section.label != null &&
                                    <Text size="xs" w={500} c="dimmed" className={classes.navbarSectionHeader}>
                                        {section.label}
                                    </Text>
                                }
                                {section.items.map((item: NavbarSectionItem, itemIndex: number) => {
                                    return (
                                        <NavbarLink {...item} active={active === item.active} key={itemIndex}/>
                                    )
                                })}
                            </Flex>
                        )
                    })}
                </Flex>
                <div className={classes.navbarFooter}>
                    <Button loading={deleteLoading} color="red" onClick={deleteTeam}>Delete</Button>
                </div>
            </AppShell.Navbar>
            <AppShell.Main className={classes.main}>
                <Container className={classes.container}>
                    {children}
                </Container>
            </AppShell.Main>
        </AppShell>
    )
}

export default TeamSettingsLayout
