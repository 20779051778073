//
//
//  Documents
//
//

import { useNavigate, useOutletContext } from "react-router-dom"
import { Flex, rem, Button, Divider, Title, Text, Spoiler, TextInput, Textarea, SimpleGrid, ComboboxItem, Select } from "@mantine/core";
import { useEffect, useState } from "react";
import Api from "../../api.ts";
import { DocumentsProject, Team } from "../../interfaces.ts";
import { useForm } from "@mantine/form"
import classes from "./Custom.module.css"
import { modals } from '@mantine/modals'
import { IconEye, IconTrash } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import MediaUpload from "../../components/MediaUpload.tsx";
import { megabytes } from "../../utils.ts";
import IconCheckbox from "../../components/IconCheckbox.tsx";
import { useTranslation } from "react-i18next";
import { useUser } from "../../contexts/AuthContext.tsx";
import { useApiErrorHandler } from "../../hooks.ts";


function ProjectSettingsDocuments() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const handleError = useApiErrorHandler()
    const { user } = useUser()
    const [uploadLogoLoading, setUploadLogoLoading] = useState(false)
    const [project, setProject]: [DocumentsProject, (project: DocumentsProject) => void] = useOutletContext()
    const [updateLoading, setUpdateLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [userTeams, setUserTeams] = useState<ComboboxItem[]>([])

    const form = useForm({
        initialValues: {
            name: project.name,
            description: project.description || undefined,
            is_public: project.is_public,
            team_id: project.team?.id.toString() || undefined
        },
        validate: {
            name: (value: string) => value.length < 2 ? t("At least 2 characters") : null
        }
    })

    useEffect(() => {
        form.setValues({
            name: project.name,
            description: project.description || undefined,
            is_public: project.is_public,
            team_id: project.team?.id.toString() || undefined
        })
    }, [project])

    useEffect(() => {
        setUpdateLoading(true)
        Api.getTeams(1, 100)
            .then(teams => {
                const aux = teams.items.map((team: Team) => {
                    return {"label": team.name, "value": team.id.toString()} as ComboboxItem
                })
                if (aux !== undefined) {
                    setUserTeams(aux)
                }
                setUpdateLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setUpdateLoading(false)
            })
    }, [user])

    function onSubmit(values: any) {

        setUpdateLoading(true)
        Api.updateProject(project.id, values)
            .then(updatedProject => {
                setProject(updatedProject)
                setUpdateLoading(false)
                notifications.show({
                    title: t('Project updated'),
                    message: t('Project updated successfully'),
                    color: "green"
                })
            })
    }

    const openDeleteModal = () =>
        modals.openConfirmModal({
            title: t('Delete project'),
            centered: true,
            children: (
                <Text size="sm">
                    {t("Are you sure you want to delete your project?")}
                </Text>
            ),
            labels: { confirm: t('Delete project'), cancel: t("No, don't delete it") },
            confirmProps: { color: 'red' },
            onConfirm: () => {
                setDeleteLoading(true)
                Api.deleteProject(project.id)
                    .then(() => {
                        navigate("/projects")
                    })
            }
        })

    function onDropLogo(file: File) {
        setUploadLogoLoading(true)
        Api.createProjectLogo(project.id, file)
            .then(() => {
                setUploadLogoLoading(false)
            })
    }

    function onDeleteLogo() {
        setUploadLogoLoading(true)
        Api.deleteProjectLogo(project.id)
            .then(() => {
                setUploadLogoLoading(false)
            })
    }
    return (
        <>
            <Title size="h1" mb={rem(20)} mt={rem(50)}>{t("Settings")}</Title>
            <Spoiler mb="xl" maxHeight={50} showLabel={t("Show more")} hideLabel={t("Hide")}>
                {t("The Settings page is a dedicated space within each conversational assistant project that empowers users to customize and configure various aspects to suit their specific needs. It serves as a control center for managing project-specific parameters, fine-tuning behaviors, and ensuring the conversational assistant aligns with desired specifications.")}
            </Spoiler>
            <Flex direction="column" style={{ width: "25%" }} mb="sm">
                <Title size="h6" mb={6}>{t("Logo")}</Title>
                <MediaUpload
                    loading={uploadLogoLoading}
                    onDrop={onDropLogo}
                    onDelete={onDeleteLogo}
                    maxSize={megabytes(10)}
                    acceptImages={true}
                    acceptVideos={false}
                    image={project?.logo}
                />
            </Flex>
            <form onSubmit={form.onSubmit(onSubmit)} className={classes.form}>
                <TextInput
                    placeholder={t("Name")}
                    label={t("Name")}
                    withAsterisk
                    required {...form.getInputProps("name")}
                />
                <Textarea
                    label={t("Description")}
                    autosize
                    mt="sm"
                    minRows={2}
                    placeholder={t("Description of super duper project")}
                    {...form.getInputProps("description")}
                />
                <SimpleGrid cols={1} mt={32} spacing="xl">
                    <IconCheckbox
                        enabled={true}
                        defaultChecked={false}
                        title={t("Set as a public project")}
                        description={t("Everyone can interact with it, but only you can modify it")}
                        icon={<IconEye/>}
                        {...form.getInputProps("is_public", { type: 'checkbox' })}
                    />
                </SimpleGrid>

                <Title mb="sm" mt="xl" size="h4">{t("Teams")}</Title>
                <Select
                    mt="xs"
                    label={t("Assigned team")}
                    placeholder={t("Select team")}
                    data={userTeams}
                    allowDeselect={true}
                    disabled={project.team !== null}
                    {...form.getInputProps("team_id")}
                />
                
                <Button type="submit" mt="xl" loading={updateLoading}>
                    {t("Save")}
                </Button>
            </form>
            <Divider mt="xl" mb="xl" />
            <Flex>
                <Button fullWidth color="red" leftSection={<IconTrash size={16} />} onClick={openDeleteModal} loading={deleteLoading}>
                    {t("Delete project")}
                </Button>
            </Flex>
        </>
    )
}

export default ProjectSettingsDocuments
