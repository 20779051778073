//
//
//   Project Home
//
//

import {Project} from "../../interfaces.ts";
import {useOutletContext} from "react-router-dom";
import CustomProjectHome from "./Custom.tsx";
import DocumentsProjectHome from "./Documents.tsx";
import { useUser } from "../../contexts/AuthContext.tsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function ProjectHome() {
    const { t } = useTranslation()
    const [project]: [Project] = useOutletContext()
    const { user } = useUser()
    const [explore, setExplore] = useState(false)

    useEffect(() => {
        if (project.created_by === user || user?.is_admin === true) {
            setExplore(false)
        } else if (project.is_public === true) {
            setExplore(true)
        }
    }, [project, user])

    if (project.type === "CUSTOM") {
        return <CustomProjectHome project={project} explore={explore}/>
    } else if (project.type === "DOCUMENTS") {
        return <DocumentsProjectHome project={project} explore={explore}/>
    } else {
        throw new Error(t(`Unexpected project type`) + `: ${project.type}`)
    }
}

export default ProjectHome
