//
//
//  Admin Users
//
//

import {Button, Checkbox, Flex, NumberInput, PasswordInput, rem, Title} from "@mantine/core";
import {IconCirclePlus, IconCurrencyEuro} from "@tabler/icons-react";
import {useEffect, useRef, useState} from "react";
import {DataTable} from "mantine-datatable";
import Api from "../api.ts";
import {User} from "../interfaces.ts"
import {formatDate, onResourceDelete, onResourceUpdate} from "../utils.ts";
import {Group, Modal, TextInput} from "@mantine/core";
import {hasLength, isEmail, useForm} from "@mantine/form";
import {Socket} from "socket.io-client";
import {useSocket} from "../contexts/SocketContext.tsx";
import UserDrawer from "../components/UserDrawer.tsx";
import { useApiErrorHandler } from "../hooks.ts";
import { useTranslation } from "react-i18next";

function AdminUsers() {
    const { t } = useTranslation()
    const pageLength = 25
    const handleError = useApiErrorHandler()
    const {socket}: {socket: Socket} = useSocket()
    const [totalUsers, setTotalUsers] = useState(0)
    const [loading, setLoading] = useState(true)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [createLoading, setCreateLoading] = useState(false)
    const [page, setPage] = useState(1)
    const usersRef = useRef<User[]>([])
    const [users, setUsers] = useState<User[]>([])
    const [createUserOpen, setCreateUserOpen] = useState(false)
    const [selectedUser, setSelectedUser] = useState<User | null>(null)


    const createUserForm = useForm({
        initialValues: {
            name: "",
            email: "",
            password: "",
            is_admin: false,
            credit_balance: 0.0
        },
        validate: {
            email: isEmail(t('Invalid email')),
            password: hasLength({ min: 8 })
        }
    })

    function loadUsersPage() {
        setLoading(true)
        Api.getUsers(page, pageLength)
            .then(pageUsers => {
                if (pageUsers["items"].length < 1 && page > 1) {
                    setPage(page-1)
                } else {
                    usersRef.current = pageUsers["items"]
                    setUsers(usersRef.current)
                    setTotalUsers(pageUsers["total"])
                    setLoading(false)
                }
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoading(false)
            })
    }

    useEffect(() => {
        loadUsersPage()
    }, [page])

    useEffect(() => {
        function onAdd(added: User) {
            const newUser = {...added, _isNew: true}
            usersRef.current = [newUser, ...usersRef.current]
            setUsers(usersRef.current)
        }

        function onUpdate(updated: User) {
            usersRef.current = onResourceUpdate(updated, users)
            setUsers(usersRef.current)
        }

        function onDelete(deleted: User) {
            usersRef.current = onResourceDelete(deleted, users)
            setUsers(usersRef.current)
        }

        socket.on("user:add", onAdd)
        socket.on("user:update", onUpdate)
        socket.on("user:delete", onDelete)

        return () => {
            socket.off("user:add", onAdd)
            socket.off("user:update", onUpdate)
            socket.off("user:delete", onDelete)
        }
    }, [socket, users])

    function onSubmitCreateUser(values: any) {
        setCreateLoading(true)
        Api.createUser(values.name, values.email, values.password, values.is_admin, values.credit_balance)
            .then(() => {
                setCreateLoading(false)
                setCreateUserOpen(false)
                setTotalUsers(totalUsers+1)
                createUserForm.reset()
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setCreateLoading(false)
            })
    }
    
    function onSelectUser({ record }: {record: User}) {
        setSelectedUser(record)
    }

    function onDeleteUser(user: User) {
        setDeleteLoading(true)
        Api.deleteUser(user.id)
            .then(() => {
                setDeleteLoading(false)
                setSelectedUser(null)
                setTotalUsers(totalUsers-1)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setDeleteLoading(false)
            })
    }

    return (
        <>
            <Flex
                justify="space-between"
                align="center"
                direction="row"
                mt={rem(50)}
                mb={rem(30)}
            >
                <Title size="h1">{t("Users")}</Title>
                <Button
                    onClick={() => setCreateUserOpen(true)}
                    leftSection={<IconCirclePlus size={16}/>}
                >
                    {t("Create User")}
                </Button>
            </Flex>
            <DataTable
                horizontalSpacing="md"
                verticalSpacing="md"
                withTableBorder
                withColumnBorders
                records={users}
                borderRadius="sm"
                highlightOnHover
                fetching={loading}
                totalRecords={totalUsers}
                recordsPerPage={pageLength}
                page={page}
                onPageChange={(p) => setPage(p)}
                onRowClick={onSelectUser}
                columns={[
                    {
                        accessor: "id",
                        title: "ID"
                    },
                    {
                        accessor: "name",
                        title: t("Name")
                    },
                    {
                        accessor: "email",
                        title: t("Email")
                    },
                    {
                        accessor: "password",
                        title: t("Password"),
                        render: () => {
                            return "****"
                        }
                    },
                    {
                        accessor: "credit_balance",
                        title: t("Credit balance")
                    },
                    {
                        accessor: "is_admin",
                        title: t("Is admin?"),
                        render: ({is_admin}) => {
                            return is_admin ? t("Yes") : t("No")
                        }
                    },
                    {
                        accessor: "created_at",
                        title: t("Created at"),
                        render: ({created_at}) => {
                            return formatDate(new Date(created_at))
                        }
                    },
                    {
                        accessor: "updated_at",
                        title: t("Updated at"),
                        render: ({updated_at}) => {
                            return formatDate(new Date(updated_at))
                        }
                    },
                ]}
                defaultColumnProps={{
                    noWrap: true,
                    ellipsis: true,
                    cellsStyle: (user: any) => {
                        if (user._isNew) {
                            return {backgroundColor: "#F5FBEF"}
                        }
                    }
                }}
            />
            <Modal opened={createUserOpen} onClose={() => setCreateUserOpen(false)} title={t("Create User")}>
                <form onSubmit={createUserForm.onSubmit(onSubmitCreateUser)}>
                    <TextInput
                      withAsterisk
                      label={t("Name")}
                      required
                      mt="sm"
                      placeholder="John Doe"
                      {...createUserForm.getInputProps("name")}
                    />
                    <TextInput
                      withAsterisk
                      label={t("Email")}
                      required
                      mt="sm"
                      placeholder="mail@maiastudio.com"
                      {...createUserForm.getInputProps("email")}
                    />
                    <PasswordInput
                      withAsterisk
                      label={t("Password")}
                      required
                      mt="sm"
                      {...createUserForm.getInputProps("password")}
                    />
                    <NumberInput
                        mt="sm"
                        label={t("Credit Balance")}
                        allowNegative={false}
                        rightSection={<IconCurrencyEuro/>}
                        {...createUserForm.getInputProps("credit_balance")}
                    />
                    <Checkbox
                        mt="sm"
                        label={t("Is Admin?")}
                        {...createUserForm.getInputProps('is_admin', { type: 'checkbox' })}
                    />
                    <Group align="right" mt="md">
                        <Button type="submit" loading={createLoading}>{t("Submit")}</Button>
                    </Group>
                </form>
            </Modal>

            {selectedUser != null &&
                <UserDrawer
                    user={selectedUser}
                    opened={true}
                    onClose={() => setSelectedUser(null)}
                    deleteLoading={deleteLoading}
                    onDelete={onDeleteUser}
                />
            }
        </>
    )
}

export default AdminUsers
