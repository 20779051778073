//
//
//  Project Build Player
//
//

import {Project} from "../interfaces.ts";
import ProjectBuildRetriever from "./ProjectBuildRetriever.tsx";
import {Flex, SegmentedControl} from "@mantine/core";
import React, {useState} from "react";

function ProjectCustomBuildPlayer({project}: {project: Project}) {
    const currentBuild = project.current_build

    let defaultCapability = undefined
    if (currentBuild?.capabilities_chat && currentBuild?.capabilities_videochat) {
        defaultCapability = "videochat"
    } else if (currentBuild?.capabilities_videochat) {
        defaultCapability = "videochat"
    } else if (currentBuild?.capabilities_chat) {
        defaultCapability = "chat"
    }

    const availableCapabilities = []
    if (currentBuild?.capabilities_videochat) {
        availableCapabilities.push({label: "Video-Chat", value: "videochat"})
    }
    if (currentBuild?.capabilities_chat) {
        availableCapabilities.push({label: "Chat", value: "chat"})
    }

    const [currentCapability, setCurrentCapability] = useState(defaultCapability)

    const capabilityStyles: Record<string, string> = {}
    if (project.videochat_background_color != null) {
        capabilityStyles["--maia-videochat-background"] = project.videochat_background_color
    }
    if (project.videochat_toolbar_color != null) {
        capabilityStyles["--maia-videochat-toolbar-background"] = project.videochat_toolbar_color
    }

    if (project.chat_widget_hover_background_color != null) {
        capabilityStyles["--maia-chat-widget-hover-background"] = project.chat_widget_hover_background_color
    }

    let capabilityHtml
    if (currentCapability === "chat") {
        capabilityHtml = (
            <maia-chat-widget
                initial-message="/EXTERNAL_welcome"
                rasa-domain={project.rasa_service_url}
                logo={project.logo}
            >
            </maia-chat-widget>
        )
    } else if (currentCapability == "videochat") {
        capabilityHtml = (
            <maia-videochat
                rasa-url={project.rasa_service_url}
                idle-avatar-url="https://storage.googleapis.com/maia-videochat/idle.mp4"
            ></maia-videochat>
        )
    }

    return (
        <ProjectBuildRetriever project={project}>
            <Flex align="center" direction="column" gap={42}>
                <SegmentedControl
                    data={availableCapabilities}
                    onChange={setCurrentCapability}
                    defaultValue={currentCapability}
                />
                <Flex align="center" justify="center" style={capabilityStyles as React.CSSProperties}>
                    {capabilityHtml}
                </Flex>
            </Flex>
        </ProjectBuildRetriever>
    )
}

export default ProjectCustomBuildPlayer
