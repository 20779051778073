//
//
//  Api
//
//

import {getAccessToken, raiseForStatus, toJsonOrRaise} from "./utils.ts";
import {Deployment, HelpRequest, Intent, Project, Story, User} from "./interfaces.ts";

export interface LoginResponse {
    access_token: string
    token_type: string
}

export class AuthenticationError extends Error {}

export class RequestError extends Error {

    response: Response

    constructor(response: Response) {
        super()
        this.response = response
    }
}


class Api {

    static async login(email: string, password: string): Promise<LoginResponse> {
        const response = await fetch(import.meta.env.VITE_API_URL + "/api/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email, password
            })
        })
        if (response.status === 401) {
            throw new AuthenticationError()
        } else if (!response.ok) {
            throw new RequestError(response)
        } else {
            return response.json()
        }
    }

    static async getCurrentUser(): Promise<User | null> {
        const accessToken = getAccessToken()
        if (accessToken == null) {
            return null
        }

        const response = await fetch(import.meta.env.VITE_API_URL + "/api/user", {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })

        return toJsonOrRaise(response)
    }

    static async getProjects(page: number, size: number=10, is_public=false, owner?: string, project_type?: string, team_id?: number, search?: string, sort?: string) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const queryParams = new URLSearchParams({
            page: page.toString(),
            size: size.toString(),
            is_public: is_public.toString(),
            ...(owner && { owner }),
            ...(team_id !== undefined && { team_id: team_id.toString() }),
            ...(project_type && { project_type }),
            ...(search && { search }),
            ...(sort && { sort }),
        });

        const response = await fetch(
            `${import.meta.env.VITE_API_URL}/api/projects?${queryParams}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

    static async getExploreProjects(page: number, size: number=10, project_type?: string, search?: string, sort?: string) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const queryParams = new URLSearchParams({
            page: page.toString(),
            size: size.toString(),
            ...(project_type && { project_type }),
            ...(search && { search }),
            ...(sort && { sort }),
        });

        const response = await fetch(
            `${import.meta.env.VITE_API_URL}/api/projects/explore?${queryParams}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

    static async createCustomProject(
        name: string,
        language: string,
        description?: string,
        capabilities_videochat: boolean = false,
        capabilities_chat: boolean = false,
        teamId?: number | null
    ): Promise<Project> {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + "/api/projects", {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name,
                language,
                description,
                capabilities_videochat,
                capabilities_chat,
                type: "CUSTOM",
                team_id: teamId
            })
        })
        return toJsonOrRaise(response)
    }

    static async createDocumentsProject(name: string, description?: string, teamId?: number | null) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + "/api/projects", {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name,
                description,
                type: "DOCUMENTS",
                team_id: teamId
            })
        })
        return toJsonOrRaise(response)
    }

    static async getProject(projectId: NonNullable<unknown>): Promise<Project> {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

    static async updateProject(projectId: NonNullable<unknown>, values: NonNullable<unknown>) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}`, {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values)
        })
        return toJsonOrRaise(response)
    }

    static async deleteProject(projectId: NonNullable<unknown>) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return raiseForStatus(response)
    }

    static async createIntent(projectId: NonNullable<unknown>, name: string) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/intents`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name
            })
        })
        return toJsonOrRaise(response)
    }

    static async getIntents(projectId: NonNullable<unknown>) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/intents?`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

    static async getIntentsPage(projectId: NonNullable<unknown>, page: number=1, size: number=10) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/intents?` + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

    static async getIntentUtterances(intentId: NonNullable<unknown>) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/intents/${intentId}/utterances`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

    static async createIntentUtterance(intentId: NonNullable<unknown>, utterance: string) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/intents/${intentId}/utterances`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                utterance
            })
        })
        return toJsonOrRaise(response)
    }

    static async deleteIntent(intentId: NonNullable<unknown>) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/intents/${intentId}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return raiseForStatus(response)
    }

    static async deleteIntentUtterance(utteranceId: NonNullable<unknown>) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/utterances/${utteranceId}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return raiseForStatus(response)
    }

    static async createResponse(projectId: NonNullable<unknown>, name: string, response: string) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const fetchResponse = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/responses`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name,
                response
            })
        })

        return toJsonOrRaise(fetchResponse)
    }

    static async getResponses(projectId: NonNullable<unknown>) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/responses`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })

        return toJsonOrRaise(response)
    }

    static async getResponsesPage(projectId: NonNullable<unknown>, page: number=1, size: number=10) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/responses?` + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

    static async deleteResponse(responseId: NonNullable<unknown>) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/responses/${responseId}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return raiseForStatus(response)
    }

    static async updateResponse(responseId: NonNullable<unknown>, values: NonNullable<unknown>) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/responses/${responseId}`, {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values)
        })
        return toJsonOrRaise(response)
    }

    static async createBuild(projectId: NonNullable<unknown>) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const fetchResponse = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/builds`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            }
        })
        return toJsonOrRaise(fetchResponse)
    }

    static async getBuilds(projectId: NonNullable<unknown>, page: number=1, size: number=10) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/builds?` + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

    static async getStories(projectId: NonNullable<unknown>, page: number=1, size: number=10) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/stories?` + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

    static async getSystemStories(projectId: NonNullable<unknown>) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/stories/system`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

    static async createStory(projectId: NonNullable<unknown>, name?: string, type?: string) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const fetchResponse = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/stories`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name,
                type
            })
        })
        return toJsonOrRaise(fetchResponse)
    }

    static async getStorySteps(storyId: NonNullable<unknown>) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/stories/${storyId}/steps`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })

        return toJsonOrRaise(response)
    }

    static async createStoryStep(storyId: NonNullable<unknown>, resourceId: number | string, resourceType: string) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const fetchResponse = await fetch(import.meta.env.VITE_API_URL + `/api/stories/${storyId}/steps`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "resource_id": resourceId,
                "resource_type": resourceType
            })
        })
        return toJsonOrRaise(fetchResponse)
    }

    static async deleteStoryStep(storyId: any) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/stories/${storyId}/steps`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return raiseForStatus(response)
    }

    static async deleteStory(storyId: any) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/stories/${storyId}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return raiseForStatus(response)
    }

    static async getSharedLinks(projectId: NonNullable<unknown>, page: number=1, size: number=50) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/shared?` + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })

        return toJsonOrRaise(response)
    }

    static async createSharedLink(projectId: NonNullable<unknown>) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const fetchResponse = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/shared`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
            })
        })

        return toJsonOrRaise(fetchResponse)
    }

    static async deleteSharedLink(token: any) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/shared/${token}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return raiseForStatus(response)
    }

    static async getSharedLink(token: any) {
        const response = await fetch(import.meta.env.VITE_API_URL + `/api/shared/${token}`, {
            method: "GET"
        })
        return toJsonOrRaise(response)
    }

    static async createUtteranceSuggestion(intentName: string, utterances: string[], language: string) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const fetchResponse = await fetch(import.meta.env.VITE_API_URL + `/api/utterances/suggestions`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "intent_name": intentName,
                "utterances": utterances,
                "language": language
            })
        })

        return toJsonOrRaise(fetchResponse)
    }

    static async createPhoneDeployment(projectId: number) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const fetchResponse = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/deployments/phone`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
            })
        })

        return toJsonOrRaise(fetchResponse)
    }


    static async createProjectTTS(projectId: number, text: string) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const fetchResponse = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/tts`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "text": text,
            })
        })
        return toJsonOrRaise(fetchResponse)
    }

    static async getInfoProject(projectId: number) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/about`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        })
        return toJsonOrRaise(response)
    }

    static async createAlexaDeployment(projectId: number) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/deployments/alexa`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
            })
        })
        return toJsonOrRaise(response)
    }

    static async rebuildProject(projectId: number) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/rebuild`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            }
        })
        return raiseForStatus(response)
    }

    static async getUser(userId: number): Promise<User> {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/users/${userId}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

    static async updateUser(userId: number, values: Record<string, any>): Promise<User> {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/users/${userId}`, {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values)
        })
        return toJsonOrRaise(response)
    }

    static async deleteUser(userId: number) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/users/${userId}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return raiseForStatus(response)
    }

    static async updateCurrentUser(values: Record<string, any>): Promise<User> {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/user`, {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values)
        })
        return toJsonOrRaise(response)
    }

    static async getIntent(intentID: NonNullable<unknown>) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/intents/${intentID}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

    static async updateIntent(intentID: number, values: Record<string, any>): Promise<Intent> {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/intents/${intentID}`, {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values)
        })
        return toJsonOrRaise(response)
    }

    static async updateStory(storyID: number, values: Record<string, any>): Promise<Story> {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/stories/${storyID}`, {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values)
        })
        return toJsonOrRaise(response)
    }

    static async deleteResponseBackground(responseId: number) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/responses/${responseId}/background`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return raiseForStatus(response)
    }

    static async createResponseBackground(responseId: number, background: File) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const formData = new FormData()
        formData.append("background", background)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/responses/${responseId}/background`, {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            },
            body: formData
        })
        return toJsonOrRaise(response)
    }

    static async deleteUserAvatar() {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + "/api/user/avatar", {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return raiseForStatus(response)
    }

    static async createUserAvatar(avatar: File) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const formData = new FormData()
        formData.append("avatar", avatar)

        const response = await fetch(import.meta.env.VITE_API_URL + "/api/user/avatar", {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            },
            body: formData
        })
        return toJsonOrRaise(response)
    }

    static async deleteProjectLogo(projectId: number) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/logo`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return raiseForStatus(response)
    }

    static async createProjectLogo(projectId: number, logo: File) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const formData = new FormData()
        formData.append("logo", logo)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/logo`, {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            },
            body: formData
        })
        return toJsonOrRaise(response)
    }

    static async updatePassword(userId: number, newPassword: string) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/users/${userId}/password`, {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                new_password: newPassword
            })
        })
        return raiseForStatus(response)
    }

    static async updateCurrentUserPassword(currentPassword: string, newPassword: string) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + "/api/user/password", {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                current_password: currentPassword,
                new_password: newPassword
            })
        })
        return raiseForStatus(response)
    }

    static async forgotPassword(email: string) {
        const response = await fetch(import.meta.env.VITE_API_URL + "/api/user/password/forgot", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email
            })
        })
        return raiseForStatus(response)
    }

    static async resetPassword(password: string, token: string) {
        const response = await fetch(import.meta.env.VITE_API_URL + "/api/user/password/reset", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                password,
                token
            })
        })
        return raiseForStatus(response)
    }

    static async getProjectConversations(projectId: NonNullable<unknown>, page: number=1, size: number=10) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/conversations?` + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })

        return toJsonOrRaise(response)
    }

    static async createTelegramDeployment(projectId: number) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/deployments/telegram`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            },
            body: JSON.stringify({
            })
        })
        return toJsonOrRaise(response)
    }

    static async getSources(projectId: number, page: number, size: number=10) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)
        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/sources?` + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })

        return toJsonOrRaise(response)
    }

    static async createSource(projectId: NonNullable<unknown>, name: string) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const fetchResponse = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/sources`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name,
            })
        })
        return toJsonOrRaise(fetchResponse)
    }

    static async putSourceFile(sourceId: number, file: File) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const formData = new FormData()
        formData.append("file", file)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/sources/${sourceId}/file`, {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            },
            body: formData
        })
        return toJsonOrRaise(response)
    }

    static async updateSource(sourceId: number, values: any) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/sources/${sourceId}`, {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values)
        })
        return toJsonOrRaise(response)
    }

    static async deleteSource(sourceId: number) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/sources/${sourceId}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return raiseForStatus(response)
    }

    static async chat(projectId: number, text: string) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/chat`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                text
            })
        })

        return raiseForStatus(response)
    }

    static async help(data: HelpRequest[]) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/help`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        })

        return raiseForStatus(response)
    }

    static async aboutPhoneDeployment(projectId: number): Promise<Deployment> {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/deployments/phone`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })

        return toJsonOrRaise(response)
    }

    static async aboutAlexaDeployment(projectId: number): Promise<Deployment>  {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/deployments/alexa`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })

        return toJsonOrRaise(response)
    }

    static async aboutTelegramDeployment(projectId: number): Promise<Deployment>  {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/deployments/telegram`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })

        return toJsonOrRaise(response)
    }

    static async getUsers(page: number, size: number=50): Promise<any> {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + "/api/users?" + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })

        return toJsonOrRaise(response)
    }

    static async createUser(name: string, email: string, password: string, is_admin: boolean, credit_balance:  number): Promise<User> {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + "/api/users", {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name,
                email,
                password,
                is_admin,
                credit_balance
            })
        })
        return toJsonOrRaise(response)
    }

    static async getTeams(page: number, size: number=50) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + "/api/teams?" + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })

        return toJsonOrRaise(response)
    }

    static async deleteTeam(teamId: number) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/teams/${teamId}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })

        return raiseForStatus(response)
    }

    static async getTeam(teamId: number | string) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/teams/${teamId}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })


        return toJsonOrRaise(response)
    }

    static async updateTeam(teamId: number | string, values: any) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/teams/${teamId}`, {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values)
        })
        return toJsonOrRaise(response)
    }

    static async getTeamMembers(teamId: number | string, page: number, size: number=50) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/teams/${teamId}/users?` + + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

    static async deleteTeamMember(teamId: number | string, userId: number | string) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/teams/${teamId}/users/${userId}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return raiseForStatus(response)
    }

    static async createTeamInvitation(teamId: number | string, email: string) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/teams/${teamId}/invitations`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email,
            })
        })
        return raiseForStatus(response)
    }

    static async joinTeam(token: string) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/user/teams`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token,
            })
        })
        return toJsonOrRaise(response)
    }

    static async leaveTeam(teamId: number | string) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/user/teams/${teamId}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return raiseForStatus(response)
    }

    static async createTeam(name: string, description: string, creditBalance: number) {
        const accessToken = getAccessToken()
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/teams`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "name": name,
                "description": description,
                "credit_balance": creditBalance
            })
        })
        return toJsonOrRaise(response)
    }

}

export default Api
