//
//
//  Project User Drawer
//
//

import {User} from "../interfaces.ts";
import {Drawer} from "@mantine/core";
import {useForm, isEmail} from "@mantine/form";
import {Button, TextInput, Checkbox, PasswordInput} from "@mantine/core";
import {useState} from "react";

import classes from "./UserDrawer.module.css"
import {Divider} from "@mantine/core";
import {IconTrash} from "@tabler/icons-react";
import Api from "../api.ts";
import { useApiErrorHandler } from "../hooks.ts";
import {notifications} from "@mantine/notifications";
import { useTranslation } from "react-i18next";

interface FormValues {
    name: string,
    email: string,
    password: string,
    is_admin: boolean
}


function UserDrawer({user, opened, onClose, onDelete, deleteLoading}: {user: User, opened: boolean, onClose: () => void, onDelete: (user: User) => void, deleteLoading: boolean}) {
    const { t } = useTranslation()
    const [updateLoading, setUpdateLoading] = useState(false)
    const handleError = useApiErrorHandler()
    const userForm = useForm<FormValues>({
        initialValues: {
            name: user.name,
            email: user.email,
            password: "",
            is_admin: user.is_admin
        },
        validate: {
            name: (value) => (value.length < 2 ? t('Name must have at least 2 letters') : null),
            email: isEmail('Invalid email'),
            password: (value) => (value !== "" && value!.length < 8 ? t('Password must be at least 8 characters long') :  null),
        },
    })

    function onSubmitUpdateUser(values: any) {
        const data = {...values}
        setUpdateLoading(true)

        if (!data.password) {
            delete data.password
        }

        Api.updateUser(user.id, data)
            .then(() => {
                setUpdateLoading(false)
                userForm.setValues({"password": ""})
                notifications.show({
                    title: t("User updated"),
                    message: t("User updated successfully"),
                    color: "green",
                })
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setUpdateLoading(false)
            })
    }

    return (
        <Drawer
            opened={opened}
            onClose={onClose}
            title={user.email}
            position="right"
            className={classes.drawer}
            withinPortal={true}
            styles={{
                content: {
                    display: "flex",
                    flexDirection: "column"
                },
                body: {
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1
                }
            }}
        >
            <form onSubmit={userForm.onSubmit(onSubmitUpdateUser)}>
                <TextInput
                    label={t("Name")}
                    mb="md"
                    {...userForm.getInputProps("name")}
                />
                <TextInput
                    label={t("Email")}
                    mb="md"
                    {...userForm.getInputProps("email")}
                />
                <PasswordInput
                    label={t("New password")}
                    mb="md"
                    {...userForm.getInputProps("password")}
                />
                <Checkbox
                    label={t("Is admin?")}
                    {...userForm.getInputProps("is_admin" , { type: 'checkbox' })}
                />
                <Button mt="md" type="submit" loading={updateLoading}>{t("Save")}</Button>
            </form>
            <div className={classes.content}>
                <div className={classes.footer}>
                    <Divider mt="xl" mb="xl"/>
                    <Button loading={deleteLoading} color="red" leftSection={<IconTrash size={16}/>} fullWidth onClick={() => onDelete(user)}>
                        {t("Delete user")}
                    </Button>
                </div>
            </div>
        </Drawer>
    )
}

export default UserDrawer
