//
//
//  Team Settings
//
//

import {Outlet, useNavigate, useParams} from "react-router-dom";
import TeamSettingsLayout from "../layouts/TeamSettingsLayout.tsx";
import {Team} from "../interfaces.ts";
import {useEffect, useState} from "react";
import Api from "../api.ts";
import {LoadingOverlay} from "@mantine/core";
import {useApiErrorHandler} from "../hooks.ts";
import NotFound from "./NotFound.tsx";
import {Socket} from "socket.io-client";
import {useSocket} from "../contexts/SocketContext.tsx";


function TeamSettings() {
    const navigate = useNavigate()
    const { socket }: { socket: Socket } = useSocket()
    const handleError = useApiErrorHandler()
    const [loading, setLoading] = useState(true)
    const [team, setTeam] = useState<Team | null>(null)
    const { teamId } = useParams()

    useEffect(() => {
        Api.getTeam(teamId!)
            .then(response => {
                setTeam(response)
                setLoading(false)
            }).catch(err => {
                console.error(err)
                handleError(err)
                setLoading(false)
            })
    }, [teamId])

    useEffect(() => {
        if (team == null) {
            return
        }

        function onDelete(deleted: Team) {
            if (deleted.id === team!.id) {
                navigate("/settings/teams")
            }
        }

        function onUpdate(updated: Team) {
            if (updated.id == team!.id) {
                setTeam(updated)
            }
        }

        socket.on("team:update", onUpdate)
        socket.on("team:delete", onDelete)

        return () => {
            socket.off("team:update", onUpdate)
            socket.off("team:delete", onDelete)
        }
    }, [socket, team])

    if (loading) {
        return <LoadingOverlay/>
    }

    if (team == null) {
        return <NotFound/>
    }

    return (
        <TeamSettingsLayout team={team}>
            <Outlet context={[team, setTeam]}></Outlet>
        </TeamSettingsLayout>
    )
}

export default TeamSettings
