//
//
//  Story Drawer
//
//

import {Intent, Response, Story, StoryStep} from "../interfaces.ts";
import classes from "./ProjectStoryDrawer.module.css"
import {
    Button,
    Drawer,
    Flex,
    Title,
    Text,
    Divider, LoadingOverlay, Select, ActionIcon, TextInput, Timeline, Anchor, Spoiler, SegmentedControl, Center, Box
} from "@mantine/core";
import {useForm} from "@mantine/form";
import {IconBolt, IconMessage2, IconPlus, IconRobot, IconTargetArrow, IconTrash, IconUser} from "@tabler/icons-react";
import {useEffect, useRef, useState} from "react";
import Api from "../api.ts";
import {onResourceAdd, onResourceDelete, onResourceUpdate, sortByCreatedAt} from "../utils.ts";
import {Socket} from "socket.io-client";
import {useSocket} from "../contexts/SocketContext.tsx";
import {useAutoAnimate} from "@formkit/auto-animate/react";
import {Link} from "react-router-dom";
import { useApiErrorHandler } from "../hooks.ts";
import { useTranslation } from "react-i18next";


function ProjectStoryDrawer({story, opened, onClose, onDelete, deleteLoading}: {story: Story, opened: boolean, onClose: () => void, onDelete: (story: Story) => void, deleteLoading: boolean}) {
    const { t } = useTranslation()
    const [parent] = useAutoAnimate()
    const handleError = useApiErrorHandler()
    const {socket}: {socket: Socket} = useSocket()
    const [updateLoading, setUpdateLoading] = useState(false)
    const [deleteStepLoading, setDeleteStepLoading] = useState(false)
    const [addLoading, setAddLoading] = useState(false)
    const intentsRef = useRef<Intent[]>([])
    const [intents, setIntents] = useState<Intent[]>([])
    const responsesRef = useRef<Response[]>([])
    const [responses, setResponses] = useState<Response[]>([])
    const [resourceType, setResourceType] = useState(story.type == null ? "INTENT" : "RESPONSE")
    const stepsRef = useRef<StoryStep[]>([])
    const [steps, setSteps] = useState<StoryStep[]>([])
    const [loading, setLoading] = useState(true)

    const createStoryForm = useForm<{value: string | null}>({
        initialValues: {
            "value": null
        }
    })

    const updateStoryForm = useForm({
        initialValues: {
            name: story.name
        }
    })

    useEffect(() => {
        const getSteps = Api.getStorySteps(story.id)
            .then(stepsList => {
                stepsRef.current = stepsList
                setSteps(stepsList)
            }).catch(console.error)
        const getResponses = Api.getResponses(story.project.id)
            .then(pageResponses => {
                responsesRef.current = pageResponses["items"]
                setResponses(pageResponses["items"])
            }).catch(console.error)
        const getIntents = Api.getIntents(story.project.id)
            .then(pageIntents => {
                intentsRef.current = pageIntents["items"]
                setIntents(pageIntents["items"])
            }).catch(console.error)
        Promise.all([getSteps, getResponses, getIntents])
            .then(() => {
                setLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoading(false)
            })
    }, [story])

    useEffect(() => {
        function onStepAdd(added: StoryStep) {
            if (added.story.id === story.id) {
                stepsRef.current = onResourceAdd(added, stepsRef.current)
                setSteps(sortByCreatedAt(stepsRef.current))
            }
        }

        function onStepUpdate(updated: StoryStep) {
            if (updated.story.id === story.id) {
                stepsRef.current = onResourceUpdate(updated, stepsRef.current)
                setSteps(sortByCreatedAt(stepsRef.current))
            }
        }

        function onStepDelete(deleted: StoryStep) {
            if (deleted.story.id === story.id) {
                stepsRef.current = onResourceDelete(deleted, stepsRef.current)
                setSteps(sortByCreatedAt(stepsRef.current))
            }
        }

        socket.on("story_step:add", onStepAdd)
        socket.on("story_step:update", onStepUpdate)
        socket.on("story_step:delete", onStepDelete)

        return () => {
            socket.off("story_step:add", onStepAdd)
            socket.off("story_step:add", onStepUpdate)
            socket.off("story_step:delete", onStepDelete)
        }
    }, [story, socket])

    useEffect(() => {
        function onAdd(added: Response) {
            if (added.project.id === story.project.id) {
                responsesRef.current = onResourceAdd(added, responsesRef.current)
                setResponses(sortByCreatedAt(responsesRef.current))
            }
        }

        function onUpdate(updated: Response) {
            if (updated.project.id === story.project.id) {
                responsesRef.current = onResourceUpdate(updated, responsesRef.current)
                setResponses(sortByCreatedAt(responsesRef.current))
            }
        }

        function onDelete(deleted: Response) {
            if (deleted.project.id === story.project.id) {
                responsesRef.current = onResourceDelete(deleted, responsesRef.current)
                setResponses(sortByCreatedAt(responsesRef.current))
            }
        }

        // noinspection DuplicatedCode
        socket.on("response:add", onAdd)
        socket.on("response:update", onUpdate)
        socket.on("response:delete", onDelete)

        return () => {
            socket.off("response:add", onAdd)
            socket.off("response:update", onUpdate)
            socket.off("response:delete", onDelete)
        }
    }, [socket, story.project.id])

    useEffect(() => {
        function onAdd(added: Intent) {
            if (added.project.id === story.project.id) {
                intentsRef.current = onResourceAdd(added, intentsRef.current)
                setIntents(sortByCreatedAt(intentsRef.current))
            }
        }

        function onUpdate(updated: Intent) {
            if (updated.project.id === story.project.id) {
                intentsRef.current = onResourceUpdate(updated, intentsRef.current)
                setIntents(sortByCreatedAt(intentsRef.current))
            }
        }

        function onDelete(deleted: Intent) {
            if (deleted.project.id === story.project.id) {
                intentsRef.current = onResourceDelete(deleted, intentsRef.current)
                setIntents(sortByCreatedAt(intentsRef.current))
            }
        }

        // noinspection DuplicatedCode
        socket.on("intent:add", onAdd)
        socket.on("intent:update", onUpdate)
        socket.on("intent:delete", onDelete)

        return () => {
            socket.off("intent:add", onAdd)
            socket.off("intent:update", onUpdate)
            socket.off("intent:delete", onDelete)
        }
    }, [socket, story.project.id]);

    async function onSubmitCreateStoryStep(values: any) {
        let resourceValue = values.value
        if (resourceType === "Intent" || resourceType === "Response") {
            resourceValue = parseInt(resourceValue)
        }
        setAddLoading(true)

        Api.createStoryStep(story.id, resourceValue, resourceType.toUpperCase())
            .then(() => {
                createStoryForm.reset()
                createStoryForm.setValues({
                    value: null
                })
                setAddLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setAddLoading(false)
            })
    }

    let autoCompleteData
    if (resourceType == "INTENT") {
        autoCompleteData = intents.map(intent => {
            return {
                "label": intent.name,
                "value": intent.id.toString(),
            }
        })
    } else if (resourceType == "RESPONSE") {
        autoCompleteData = responses.map(response => {
            return {
                "label": response.name,
                "value": response.id.toString(),
            }
        })
    } else {
        throw new Error(`Unexpected resourceType: ${resourceType}`)
    }

    function onResourceTypeChange(newValue: string) {
        setResourceType(newValue)
        createStoryForm.reset()
        createStoryForm.setValues({
            value: null
        })
    }

    function onDeleteStep() {
        setDeleteStepLoading(true)
        Api.deleteStoryStep(story.id)
            .then(() => {
                setSteps(steps.slice(0, steps.length-1))
                setDeleteStepLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setDeleteStepLoading(false)
            })
    }

    function onSubmitUpdateStory(values: any) {
        setUpdateLoading(true)
        Api.updateStory(story.id, values)
            .then(() => {
                setUpdateLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setUpdateLoading(false)
            })
    }

    let storyName: string
    let storyTypeDescription: string | undefined = undefined
    if (story.type == null) {
        storyName = story.name
    } else if (story.type === "SYSTEM_FALLBACK") {
        storyName = t("Fallback")
        storyTypeDescription = t("Conversational Assistant doesn't recognize the user's message")
    } else if (story.type === "SYSTEM_WELCOME") {
        storyName = t("Welcome")
        storyTypeDescription = t("User starts a conversation")
    } else if (story.type === "SYSTEM_VIDEOCHAT_INTRODUCTION") {
        storyName = t("Who is MAIA?")
        storyTypeDescription = t("User clicks on Who is MAIA? or open Alexa Skill")
    } else if (story.type === "SYSTEM_WELCOME_MAIACOGNITIVE") {
        storyName = t("Welcome - maiacognitive.com")
        storyTypeDescription = t("User opens www.maiacognitive.com/demo")
    } else {
        throw new Error(t(`Unknown story type`) + `: ${story.type}`)
    }

    let selectPlaceholder = ""
    if (resourceType === "INTENT") {
        selectPlaceholder = t("Select Next Topic")
    } else if (resourceType === "RESPONSE") {
        selectPlaceholder = t("Select Next Response")
    } else {
        throw new Error(t("Unexpected select"))
    }


    return (
        <Drawer
            opened={opened}
            onClose={onClose}
            title={storyName}
            position="right"
            className={classes.drawer}
            withinPortal={true}
            styles={{
                content: {
                    display: "flex",
                    flexDirection: "column"
                },
                body: {
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1
                }
            }}
        >
            {story.type == null && <form onSubmit={updateStoryForm.onSubmit(onSubmitUpdateStory)}>
                <TextInput
                  withAsterisk
                  required
                  label={t("Name")}
                  placeholder={t("Super duper story")}
                  {...updateStoryForm.getInputProps("name")}
                />
                <Button mt="md" type="submit" loading={updateLoading}>{t("Save")}</Button>
            </form>}
            <Title mb="md" mt="lg" size="h3">{t("Flow")}</Title>
            <LoadingOverlay visible={loading}/>
            <div className={classes.content}>
                <div>
                    <Timeline bulletSize={24} lineWidth={2} mb="md" ref={parent} active={story.type == null ? steps.length - 1 : steps.length}>
                        {storyTypeDescription != null &&
                            <Timeline.Item bullet={<IconBolt size={16}/>} title={storyName}>
                                <Text c="dimmed" size="sm">
                                    {storyTypeDescription}
                                </Text>
                            </Timeline.Item>
                        }
                        {steps.map((step: StoryStep, index: number) => {
                            if (step.resource_type === "INTENT") {
                                const intent = (step.resource as Intent)
                                return (
                                    <Timeline.Item key={index} bullet={<IconUser size={16}/>} title={t("User says")}>
                                        <Flex align="center" gap={20}>
                                            <Flex direction="column" style={{flexGrow: 1}}>
                                                <Text c="dimmed" size="sm" mt={4}>
                                                    <Anchor component={Link} to={`/projects/${intent.project.id}/intents`} state={{selected: intent}}>{intent.name}</Anchor>
                                                </Text>
                                            </Flex>

                                            {index === (steps.length - 1) && <ActionIcon variant="light" color="red" aria-label={t("Delete")} onClick={onDeleteStep} loading={deleteStepLoading}>
                                                <IconTrash size={14}/>
                                            </ActionIcon>}
                                        </Flex>
                                    </Timeline.Item>
                                )
                            } else if (step.resource_type === "RESPONSE") {
                                const response = (step.resource as Response)
                                return (
                                    <Timeline.Item key={index} bullet={<IconRobot size={16}/>} title={t("AI says")}>
                                        <Flex align="center" gap={20}>
                                            <Flex direction="column" style={{flexGrow: 1}}>
                                                <Spoiler maxHeight={60} showLabel={<Text size="sm">{t("Show more")}</Text>} hideLabel={t("Hide")}>
                                                    <Text c="dimmed" size="sm">
                                                        {response.response}
                                                    </Text>
                                                </Spoiler>
                                                <Text size="xs" mt={4}>
                                                    <Anchor component={Link} to={`/projects/${response.project.id}/responses`} state={{selected: response}}>{response.name}</Anchor>
                                                </Text>
                                            </Flex>

                                            {index === (steps.length - 1) && <ActionIcon variant="light" color="red" aria-label={t("Delete")} onClick={onDeleteStep} loading={deleteStepLoading}>
                                                <IconTrash size={14}/>
                                            </ActionIcon>}
                                        </Flex>
                                    </Timeline.Item>
                                )
                            }
                        })}
                        <Timeline.Item title={t("Add Next")} bullet={<IconPlus size={16}/>}>
                            <form onSubmit={createStoryForm.onSubmit(onSubmitCreateStoryStep)}>
                                <Flex gap={16} align="center">
                                    <Flex direction="column" style={{flexGrow: 1}} mt="sm">
                                        <SegmentedControl
                                            radius="xl"
                                            fullWidth
                                            onChange={onResourceTypeChange}
                                            value={resourceType}
                                            data={[
                                                {
                                                    "value": "INTENT",
                                                    "label": (
                                                        <Center>
                                                             <IconTargetArrow size={16} />
                                                            <Box ml={10}>{t("Topic")}</Box>
                                                        </Center>
                                                    )
                                                },
                                                {
                                                    "value": "RESPONSE",
                                                    "label": (
                                                        <Center>
                                                             <IconMessage2 size={16} />
                                                            <Box ml={10}>{t("Response")}</Box>
                                                        </Center>
                                                    )
                                                },
                                            ]}
                                            size="xs"
                                            mb="xs"
                                        />
                                        <Select
                                            clearable
                                            searchable
                                            placeholder={selectPlaceholder}
                                            style={{flexGrow: 1}}
                                            data={autoCompleteData}
                                            {...createStoryForm.getInputProps("value")}
                                        />
                                    </Flex>
                                    <ActionIcon loading={addLoading} variant="light" size={32} radius="xs" type="submit">
                                        <IconPlus size={20}/>
                                    </ActionIcon>
                                </Flex>
                            </form>
                        </Timeline.Item>
                    </Timeline>
                </div>
                <div className={classes.footer}>
                    <Divider mt="xl" mb="xl"/>
                    <Button disabled={story.type != null} loading={deleteLoading} color="red" leftSection={<IconTrash size={16}/>} fullWidth onClick={() => onDelete((story as Story))}>
                        {t("Delete story")}
                    </Button>
                </div>
            </div>
        </Drawer>
    )
}


export default ProjectStoryDrawer
