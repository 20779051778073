//
//
//  Project Conversation Drawer
//
//

import {Conversation, ConversationMessage} from "../interfaces.ts";
import {Drawer} from "@mantine/core";
// import "maia-chat-widget"  FIXME: NotSupportedError: Cannot define multiple custom elements with the same tag name

function ProjectConversationDrawer({conversation, onClose, opened}: {conversation: Conversation, onClose: () => void, opened: boolean}) {
    function parseChatMessages(listMessages: ConversationMessage[]) {
        return listMessages.flatMap(message => {
            return {
                isUser: message["is_user"],
                message: {
                    text: message["text"],
                    datetime: new Date(message["created_at"])
                }
            }
        })
    }

    return (
        <Drawer
            opened={opened}
            onClose={onClose}
            title={conversation.id_chat}
            position="right"
            styles={{
                content: {
                    display: "flex",
                    flexDirection: "column"
                },
                body: {
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1
                }
            }}
        >
            <maia-message-list
                style={{height: "100%"}}
                messages={JSON.stringify(parseChatMessages(conversation.messages))}
            ></maia-message-list>
        </Drawer>
    )
}

export default ProjectConversationDrawer
