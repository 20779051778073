//
//
//  Project Create
//
//

import LoggedHeader from "../components/LoggedHeader.tsx";
import {
    Anchor, Box,
    Button, Center,
    Container,
    Flex,
    Group, LoadingOverlay,
    rem,
    Select,
    SimpleGrid,
    Stepper,
    Textarea,
    TextInput,
    Title,
} from "@mantine/core";
import {ReactNode, useEffect, useState} from "react";
import {
    IconArrowLeft,
    IconBrandOpenai,
    IconCircleCheck,
    IconFileText,
    IconPencil,
    IconMessage,
    IconVideo,
    IconPhone,
    IconHexagon, IconHexagons
} from "@tabler/icons-react";
import IconRadio from "../components/IconRadio.tsx";
import {Link, useNavigate} from "react-router-dom";
import {useForm} from "@mantine/form";
import Api from "../api.ts";
import IconCheckbox from "../components/IconCheckbox.tsx";
import { useApiErrorHandler } from "../hooks.ts";
import { useTranslation } from "react-i18next";
import {Team} from "../interfaces.ts";

function ProjectCreate() {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const handleError = useApiErrorHandler()
    const [ownerTeams, setOwnerTeams] = useState<Array<any>>([])
    const [selectedTeamId, setSelectedTeamId] = useState<string | null>(null)
    const [owner, setOwner] = useState<string>("ME")
    const [type, setType] = useState<string>("CUSTOM")
    const [active, setActive] = useState(0)
    const [createLoading, setCreateLoading] = useState(false)
    const [highestStepVisited, setHighestStepVisited] = useState(active)

    useEffect(() => {
        Api.getTeams(1, 100)
            .then(response => {
                setOwnerTeams(response["items"].map((team: Team) => {
                    return {
                        "label": team["name"],
                        "value": team["id"].toString()
                    }
                }))
            })
    }, [])

    const customProjectMetadataForm = useForm({
        initialValues: {
            name: "",
            language: "Spanish",
            description: undefined,
            capabilities_videochat: true,
            capabilities_chat: true
        }
    })

    const documentsProjectMetadataForm = useForm({
        initialValues: {
            name: "",
            description: undefined
        }
    })

    const finetuningProjectMetadataForm = useForm({
        initialValues: {
            name: "",
            description: undefined
        }
    })

    function onChangeType(changedType: string) {
        setType(changedType)
    }

    function goToNextStep(nextStep: number) {
        setActive((current) => (current < 3 ? current + 1 : current))
        setHighestStepVisited((hSC) => Math.max(hSC, nextStep))
    }

    function goToPrevStep() {
        setActive((current) => (current > 0 ? current - 1 : current))
    }

    const shouldAllowSelectStep = (step: number) => highestStepVisited >= step && active !== step

    function onSubmitMetadataCustomProject(values: any) {
        let teamId: number | null = null
        if (owner === "TEAM" && selectedTeamId != null) {
            teamId = parseInt(selectedTeamId)
        }

        setCreateLoading(true)
        Api.createCustomProject(
            values.name,
            values.language.toUpperCase(),
            values.description,
            values.capabilities_videochat,
            values.capabilities_chat,
            teamId
        )
            .then(project => {
                navigate(`/projects/${project.id}`)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setCreateLoading(false)
            })
    }

    function onSubmitMetadataDocumentsProject(values: any) {
        let teamId: number | null = null
        if (owner === "TEAM" && selectedTeamId != null) {
            teamId = parseInt(selectedTeamId)
        }

        setCreateLoading(true)
        Api.createDocumentsProject(values.name, values.description, teamId)
            .then(project => {
                navigate(`/projects/${project.id}`)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setCreateLoading(false)
            })
    }

    function onSubmitMetadataFinetuningProject(values: any) {
        console.log(values)
    }

    let typeForm: ReactNode
    if (type === "CUSTOM") {
        typeForm = (
            <form onSubmit={customProjectMetadataForm.onSubmit(onSubmitMetadataCustomProject)}>
                <TextInput
                    withAsterisk
                    label={t("Name")}
                    required
                    placeholder={t("Super duper project")}
                    {...customProjectMetadataForm.getInputProps("name")}
                />
                <Textarea
                    label={t("Description")}
                    autosize
                    minRows={2}
                    placeholder={t("Description of super duper project")}
                    {...customProjectMetadataForm.getInputProps("description")}
                />
                <Select
                    mt="xs"
                    withAsterisk
                    description={t("You will NOT be able to change it later.")}
                    label={t("Language")}
                    placeholder={t("Select language")}
                    data={[
                            {"value": "Spanish", "label": t("Spanish")},
                            {"value": "English", "label": t("English")},
                            {"value": "French", "label": t("French")},
                            {"value": "Portuguese", "label": t("Portuguese")},
                            {"value": "German", "label": t("German")},
                            {"value": "Italian", "label": t("Italian")}
                    ]}
                    allowDeselect={false}
                    {...customProjectMetadataForm.getInputProps("language")}
                />
                <SimpleGrid cols={3} mt={32} spacing="xl">
                    <IconCheckbox
                        enabled={true}
                        defaultChecked={false}
                        title={t("Videochat")}
                        description={t("Enable interaction via videochat")}
                        icon={<IconVideo/>}
                        {...customProjectMetadataForm.getInputProps("capabilities_videochat", { type: 'checkbox' })}
                    />
                    <IconCheckbox
                        enabled={true}
                        defaultChecked={false}
                        title={"Chat"}
                        description={t("Enable interaction via text chat")}
                        icon={<IconMessage/>}
                        {...customProjectMetadataForm.getInputProps("capabilities_chat", { type: 'checkbox' })}
                    />
                    <IconCheckbox
                        enabled={true}
                        defaultChecked={true}
                        title={"Audiochat"}
                        description={t("Enable interaction via audiochat")}
                        icon={<IconPhone/>}
                    />
                </SimpleGrid>
                <Group justify="center" mt={48}>
                    <Button type="submit" variant="default" onClick={goToPrevStep}>
                        {t("Back")}
                    </Button>
                    <Button type="submit">{t("Create")}</Button>
                </Group>
            </form>
        )
    } else if (type === "DOCUMENTS") {
        typeForm = (
            <form onSubmit={documentsProjectMetadataForm.onSubmit(onSubmitMetadataDocumentsProject)}>
                <TextInput
                    withAsterisk
                    label={t("Name")}
                    required
                    placeholder={t("Super duper project")}
                    {...documentsProjectMetadataForm.getInputProps("name")}
                />
                <Textarea
                    label={t("Description")}
                    autosize
                    minRows={2}
                    placeholder={t("Description of super duper project")}
                    {...documentsProjectMetadataForm.getInputProps("description")}
                />
                <Group justify="center" mt={48}>
                    <Button type="submit" variant="default" onClick={goToPrevStep}>
                        {t("Back")}
                    </Button>
                    <Button type="submit">{t("Create")}</Button>
                </Group>
            </form>
        )
    } else if (type === "FINE_TUNING") {
        typeForm = (
            <form onSubmit={finetuningProjectMetadataForm.onSubmit(onSubmitMetadataFinetuningProject)}/>

        )
    } else {
        throw new Error(`Type not implemented: ${type}`)
    }

    function onChangeOwner(newOwner: any) {
        setOwner(newOwner)
    }

    const humanReadableType = {
        "DOCUMENTS": t("Documents"),
        "CUSTOM": t("Custom"),
        "FINE_TUNING": t("Fine-Tuning")
    }

    return (
        <>
            <header style={{height: 60}}>
                <LoggedHeader/>
            </header>
            <main>
                <Container>
                    <Flex
                        direction="column"
                        mt={rem(50)}
                        mb={rem(50)}
                    >
                        <Title size="h1">{t("Create Project")}</Title>
                        <Anchor c="dimmed" size="sm" mt="md" component={Link} to="/projects">
                            <Center inline>
                                <IconArrowLeft style={{width: rem(12), height: rem(12)}} stroke={1.5}/>
                                <Box ml={5}>{t("Back to projects")}</Box>
                            </Center>
                        </Anchor>
                    </Flex>
                    <LoadingOverlay visible={createLoading}/>
                    <Stepper
                        active={active}
                        onStepClick={setActive}
                        completedIcon={<IconCircleCheck style={{width: rem(18), height: rem(18)}}/>}
                    >
                        <Stepper.Step
                            label="Owner"
                            description={"Define the owner"}
                            allowStepSelect={shouldAllowSelectStep(0)}
                        >
                            <SimpleGrid cols={2} mt={64} spacing="xl">
                                <IconRadio
                                    enabled={true}
                                    title="Me"
                                    checked={owner === "ME"}
                                    defaultChecked={true}
                                    onChange={() => onChangeOwner("ME")}
                                    description="This project belongs to you and only you can modify it"
                                    icon={<IconHexagon/>}
                                />
                                <Flex direction="column" gap={16}>
                                    <IconRadio
                                        enabled={ownerTeams.length > 0}
                                        title="Team"
                                        checked={owner === "TEAM"}
                                        defaultChecked={false}
                                        onChange={() => onChangeOwner("TEAM")}
                                        description="This project belongs to a team and all members can modify it"
                                        icon={<IconHexagons/>}
                                    />
                                    <Select
                                        placeholder="Select Team"
                                        allowDeselect={false}
                                        data={ownerTeams}
                                        onChange={(value) => setSelectedTeamId(value)}
                                    />
                                </Flex>
                            </SimpleGrid>
                            <Group justify="center" mt={48}>
                                <Button onClick={() => goToNextStep(active + 1)} disabled={owner === "TEAM" && selectedTeamId == null}>Next</Button>
                            </Group>
                        </Stepper.Step>
                        <Stepper.Step
                            label="Type"
                            description={(type == null || active === 0) ? t("Define the type of project") : humanReadableType[type]}
                            allowStepSelect={shouldAllowSelectStep(1)}
                        >
                            <SimpleGrid cols={3} mt={64} spacing="xl">
                                <IconRadio
                                    enabled={true}
                                    checked={type === "CUSTOM"}
                                    defaultChecked={true}
                                    onChange={() => onChangeType("CUSTOM")}
                                    title={humanReadableType["CUSTOM"]}
                                    description={t("Build your own AI by customizing topics, responses and stories")}
                                    icon={<IconPencil/>}
                                />
                                <IconRadio
                                    enabled={true}
                                    checked={type == "DOCUMENTS"}
                                    defaultChecked={false}
                                    onChange={() => onChangeType("DOCUMENTS")}
                                    title={humanReadableType["DOCUMENTS"]}
                                    description={t("Upload any document and ask questions about it")}
                                    icon={<IconFileText/>}
                                />
                                <IconRadio
                                    enabled={false}
                                    checked={type === "FINE_TUNING"}
                                    defaultChecked={false}
                                    onChange={() => onChangeType("FINE_TUNING")}
                                    title={humanReadableType["FINE_TUNING"]}
                                    description={t("Leverage the power of ChatGPT to build your AI")}
                                    icon={<IconBrandOpenai/>}
                                />
                            </SimpleGrid>
                            <Group justify="center" mt={48}>
                                <Button onClick={() => goToNextStep(active + 1)}>Next</Button>
                            </Group>
                        </Stepper.Step>
                        <Stepper.Step
                            label={t("Metadata")}
                            description={t("Define metadata")}
                            allowStepSelect={shouldAllowSelectStep(2)}
                        >
                            {typeForm}
                        </Stepper.Step>
                    </Stepper>
                </Container>
            </main>
        </>
    )
}

export default ProjectCreate
