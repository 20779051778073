import {UnstyledButton, Text, Badge, Checkbox} from '@mantine/core';
import {useUncontrolled} from '@mantine/hooks';
import classes from './IconRadio.module.css';
import React from "react";
import { useTranslation } from "react-i18next";

interface ImageCheckboxProps {
    checked?: boolean;
    defaultChecked?: boolean;

    onChange?(checked: boolean): void;

    title: string;
    description: string;
    icon: React.ReactNode;
    enabled: boolean;
}

function IconCheckbox({checked, defaultChecked, onChange, title, description, icon, enabled, ...others}: ImageCheckboxProps & Omit<React.ComponentPropsWithoutRef<'button'>, keyof ImageCheckboxProps>) {
    const { t } = useTranslation();
    const [value, handleChange] = useUncontrolled({
        value: checked,
        defaultValue: defaultChecked,
        finalValue: false,
        onChange,
    })

    return (
        <UnstyledButton
            {...others}
            disabled={!enabled}
            onClick={() => handleChange(!value)}
            data-checked={value || undefined}
            className={classes.button}
        >
            {icon}

            <div className={classes.body}>
                <Text fw={500} size="md" lh={1} mb={8}>
                    {title}
                </Text>
                <Text c="dimmed" size="xs" lh={1}>
                    {description}
                </Text>
                {!enabled &&
                    <Badge mt="xs" color="red">{t("Coming Soon")}</Badge>
                }
            </div>

            <Checkbox
                ml="sm"
                checked={value}
                onChange={() => {
                }}
                tabIndex={-1}
            />
        </UnstyledButton>
    )
}

export default IconCheckbox
