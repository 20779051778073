//
//
//  Projects Data Table
//
//

import { Project } from "../interfaces.ts";
import classes from "./DataTable.module.css"
import { Anchor, Avatar, Title, Text, Flex, useMantineTheme, Badge, Box } from "@mantine/core";
import { IconNotebook, IconPointFilled } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { DataTableEmpty } from "./DataTable.tsx";
import TimeAgoLocale from "./TimeAgoLocale.tsx";
import React from "react";
import { DataTable } from "mantine-datatable";
import {useAutoAnimate} from "@formkit/auto-animate/react";
import { useTranslation } from "react-i18next";


function ProjectsDataTable({ projects, loading = false, onCreate, explore = false }: { projects: Project[], loading: boolean, onCreate: () => void, explore: boolean }) {
    const { t, i18n } = useTranslation();
    const theme = useMantineTheme()
    const [bodyRef] = useAutoAnimate<HTMLTableSectionElement>();

    const humanReadableProjectType: Record<string, string> = {
        "DOCUMENTS": t("Documents"),
        "CUSTOM": t("Custom")
    }

    const ProjectColor: Record<string, string> = {
        "DOCUMENTS": theme.colors.violet[6],
        "CUSTOM": theme.colors.pink[6]
    }

    function renderProjectDetails(project: Project) {

        const isNew = (project as any)._isNew == true
        const itemStyle = isNew ? { backgroundColor: "#F5FBEF", marginBottom:'1rem'} : {marginBottom:'1rem'};
        const teamInfo =  project.team


        let lastBuild: string | React.ReactNode = <Text span fs="italic">{t("never")}</Text>
        if (project.current_build != null) {
            lastBuild = <TimeAgoLocale date={new Date(project.current_build?.ended_at)} language={i18n.language}/>
        }
        return (
            <div className={classes.row} style={itemStyle}>
                <Avatar size="lg" mr="lg" src={project.logo} radius="sm">
                    <IconNotebook size={28} />
                </Avatar>
                <div className={classes.rowContent}>
                    <Flex justify={'start'} align={'center'} gap={10}>
                    <Anchor component={Link} to={`/projects/${project.id}`}>
                        <Title size="h2" className={classes.rowTitle}>
                            {project.name}
                        </Title>
                    </Anchor>
                    {teamInfo && (
                        <Badge variant="outline" color="gray">{teamInfo?.name}</Badge>
                    )}
                    </Flex>
                    <Text  w={700} truncate="end" fs={project.description ? "bold" : "italic"} fw={project.description ? 400 : 300} >
                        {project.description || t("No description")}
                    </Text>
                    <Flex gap={10} align="center" mt={4}>
                        <IconPointFilled size={24} style={{ marginRight: '-10px', color: ProjectColor[project.type] }} />
                        <Text c="dimmed" size="sm" mt={0}>{humanReadableProjectType[project.type]}</Text>
                        <IconPointFilled size={6} />
                        <Text c="dimmed" size="sm" mt={0}>{t("Last successful build")}: {lastBuild}</Text>
                        <Box>
                        </Box>
                    </Flex>
                </div>
            </div>
        );
    }

    return (
        <DataTable
            noHeader
            withRowBorders={false}
            borderColor={'white'}
            rowClassName={classes.rowTitle}
            fetching={loading}
            columns={[{ accessor: 'projects', render: renderProjectDetails }]}
            records={projects}
            bodyRef={bodyRef}
            verticalSpacing=""
            emptyState={
                !explore && projects.length === 0 && (
                <div style={{ pointerEvents: 'all', width:'100%'}}>
                    <DataTableEmpty
                        title={t("You don't have any project")}
                        subtitle={t("Let's get started") + " 🚀"}
                        action={t("Create your first Project")}
                        onAction={onCreate}
                    />
                </div>)
            }
            minHeight={300}
            defaultColumnProps={{
                noWrap: true,
                ellipsis: true,
            }}
        />
    )
}

export default ProjectsDataTable
