//
//
//  Project Navbar Header
//
//

import { Project } from "../interfaces";
import {Anchor, Badge, Box, Breadcrumbs, Button, Flex, Image, Spoiler, Text, Title} from "@mantine/core";
import {Link} from "react-router-dom";
import classes from "./ProjectNavbarHeader.module.css";
import {formatDate} from "../utils.ts";
import {ReactNode} from "react";
import {IconFileText, IconPencil} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

function ProjectNavbarHeader({project, onBuild, buildLoading, explore}: {project: Project, onBuild: () => void, buildLoading: boolean, explore: boolean}) {
    const { t } = useTranslation();
    const teamInfo =  project.team
    let currentBuildDate = "-"
    if (project.current_build != null) {
        const date = new Date(project.current_build.ended_at)
        currentBuildDate = formatDate(date)
    }

    const projectTypeToBadge: Record<string, ReactNode> = {
        "DOCUMENTS": <Badge mt="sm" variant="light" color="violet" leftSection={<IconFileText size={12}/>} radius="sm">{t("Documents")}</Badge>,
        "CUSTOM": <Badge mt="sm" variant="light" color="pink" leftSection={<IconPencil size={12}/>} radius="sm">{t("Custom")}</Badge>
    }

    return (
        <>
                {!explore ?
                    <Breadcrumbs ml="lg" mt="xs">
                        <Anchor component={Link} to="/projects">
                        {t("Projects")}
                        </Anchor>
                        <Anchor component={Link} to={`/projects/${project.id}`}>
                            <Box w={180}>
                                <Text truncate="end">
                                    {project.name}
                                </Text>
                            </Box>
                        </Anchor>
                    </Breadcrumbs>
                :
                    <Breadcrumbs ml="lg" mt="xs">
                        <Anchor component={Link} to="/projects/explore">
                        {t("Projects")}
                        </Anchor>
                        <Anchor component={Link} to={`/projects/explore/${project.id}`}>
                            <Box w={180}>
                                <Text truncate="end">
                                    {project.name}
                                </Text>
                            </Box>
                        </Anchor>
                    </Breadcrumbs>
                }
            {project.logo && <Image
                mt="lg"
                radius="md"
                h={100}
                w="auto"
                fit="contain"
                src={project.logo}
            />}
            <Box className={classes.navbarHeaderTitle}>
                <Text size="lg" c="dimmed">{teamInfo?.name}</Text>
                <Title size="h1" ta="center">{project.name}</Title>
                <Flex align={'center'} justify={'center'} gap={10}>
                {projectTypeToBadge[project.type]}
                
                </Flex>
                {project.description &&
                    <Spoiler mb="lg" maxHeight={50} showLabel="Show more" hideLabel="Hide">
                        <Text className={classes.navbarHeaderDescription} fw={500} size="sm" mt="md">
                            {project.description}
                        </Text>
                    </Spoiler>
                }
            </Box>
            <Box className={classes.navbarHeaderBuild}>
                <Text className={classes.navbarBuild}>
                    <span className={classes.navbarBuildDescription}>{t("Last successful build")}:</span>
                    <span className={classes.navbarBuildDate}>{currentBuildDate}</span>
                </Text>
                {!explore &&
                    <Button loading={buildLoading} onClick={onBuild}>
                        {t("Build")}
                    </Button>
                }
            </Box>
        </>
    )
}

export default ProjectNavbarHeader
