//
//
//  Project Build Alert
//
//

import {Project} from "../interfaces.ts";
import {Alert, Anchor} from "@mantine/core";
import {IconInfoCircle} from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";

function ProjectBuildRetriever({project, children}: {project: Project, children: React.ReactNode}) {
    const { t } = useTranslation()

    let ia_error_flag = false;
    if (project.type == "CUSTOM") {
        if (project.rasa_service_url == null) {
            ia_error_flag = true;
        }
    } else if (project.type == "DOCUMENT") {
        if (project.lancedb_table_name == null) {
            ia_error_flag = true;
        }
    }

    if (ia_error_flag == true) {
        return (
            <Alert mt="xl" variant="filled" color="red" title="Error" icon={<IconInfoCircle/>}>
                {t("Error retrieving AI server. This may happen because you've just created the project or because there was an internal error. Please try again in a few minutes or contact")}
                &nbsp;<Anchor underline="always" href="mailto:miguel.castanedo@majorel.com">Miguel Castanedo</Anchor> {t("if the problem persists.")}
            </Alert>
        )
    }
    
    if (project.current_build == null) {
        return (
            <Alert variant="filled" color="orange" title={t("No build")} icon={<IconInfoCircle/>}>
                {t("No build executed yet.")}
            </Alert>
        )
    } else if (project.type == "CUSTOM" && !project.current_build.capabilities_videochat && !project.current_build.capabilities_chat) {
        return (
            <Alert mt="xl" variant="filled" color="red" title="Error" icon={<IconInfoCircle />}>
                {t("Current build has not any capability available. Select any capability and rebuild model.")}
            </Alert>
        )
    } else {
        return children
    }
}

export default ProjectBuildRetriever
