//
//
//  Settings Billing
//
//

import {Alert, Button, Flex, Modal, rem, Title} from "@mantine/core";
import {useUser} from "../contexts/AuthContext.tsx";
import {ReactNode, useState} from "react";
import {Group, TextInput} from "@mantine/core";
import {IconCurrencyEuro} from "@tabler/icons-react";
import {getAccessToken} from "../utils.ts";
import {useQuery} from "../hooks.ts";
import {useNavigate} from "react-router-dom";
import { NumberFormatter } from '@mantine/core';
import { useTranslation } from "react-i18next";

function SettingsBilling() {
    const { t } = useTranslation()
    const {user} = useUser()
    const query = useQuery()
    const navigate = useNavigate()
    const [addFundsOpen, setAddFundsOpen] = useState(false)

    const accessToken = getAccessToken()
    if (accessToken == null) {
        throw new Error(t("Access token is null"))
    }

    function onCloseStatusAlert() {
        navigate("/settings/billing")
    }

    let alert: ReactNode = undefined
    if (query.has("status") && query.has("amount")) {
        const status = query.get("status")
        const amount = parseInt(query.get("amount")!) / 100

        if (status === "success") {
            alert = (
                <Alert
                    variant="light"
                    color="green"
                    withCloseButton
                    title={t("Success!")}
                    mb="sm"
                    onClose={onCloseStatusAlert}
                >
                    {t("Congratulations, you have successfully added")} {amount}€ {t("to your credit balance.")}
                </Alert>
            )
        } else if (status === "cancelled") {
            alert = (
                <Alert
                    variant="light"
                    color="red"
                    withCloseButton
                    title={t("Cancelled!")}
                    mb="sm"
                    onClose={onCloseStatusAlert}
                >
                    {t("You have cancelled the transaction.")}
                </Alert>
            )
        }
    }

    return (
        <>
            <Flex
                mt={rem(30)}
                mb={rem(30)}
            >
                <Title size="h1">{t("Billing")}</Title>
            </Flex>
            <Title size="h2" mb="xs">{t("Credit Balance")}</Title>
            {alert}
            <Title size="h3">
                <NumberFormatter thousandSeparator=" " decimalSeparator="." value={user?.credit_balance} decimalScale={2} prefix="€ "/>
            </Title>
            <Flex mt="sm">
                <Button type="submit" variant="light" onClick={() => setAddFundsOpen(true)}>{t("Add to credit balance")}</Button>
            </Flex>
            <Modal opened={addFundsOpen} onClose={() => setAddFundsOpen(false)} title={t("Add to credit balance")}>
                <form action={import.meta.env.VITE_API_URL + "/credits/checkout"} method="POST">
                    <TextInput
                      withAsterisk
                      label={t("Amount")}
                      required
                      mt="sm"
                      defaultValue={10}
                      name="amount"
                      leftSection={<IconCurrencyEuro style={{ width: rem(18), height: rem(18) }} />}
                    />
                    <input type="hidden" name="token" value={accessToken}/>
                    <Group align="right" mt="md">
                        <Button type="submit">{t("Continue")}</Button>
                    </Group>
                </form>
            </Modal>
        </>
    )
}

export default SettingsBilling
