//
//
//  Documents Project Layout
//
//

import {DocumentsProject} from "../interfaces.ts";
import {ReactNode} from "react";
import {
    IconDeviceGamepad,
    IconFiles,
    IconHome2,
    IconRocket,
    IconShare2
} from "@tabler/icons-react";
import ProjectLayout from "./ProjectLayout.tsx";
import { useTranslation } from "react-i18next";

function DocumentsProjectLayout({project, children, explore}: {project: DocumentsProject, children: ReactNode, explore: boolean}) {
    const { t } = useTranslation();

    const sections = [
        {
            label: undefined,
            items: [
                { icon: <IconHome2 size={16} />, color: 'teal', label: t("Home"), to: `/projects/${project.id}`, active: "home"},
            ]
        },
        {
            label: t("Conversational Assistant"),
            items: [
                { icon: <IconFiles size={16} />, color: 'blue', label: t("Sources"), to: "sources" , active: "sources"},
                { icon: <IconRocket size={16} />, color: 'orange', label: t("Builds"), to: "builds", active: "builds" },
            ]
        },
        {
            label: t("Connect"),
            items: [
                {icon: <IconDeviceGamepad size={16}/>, color: "lime", label: t("Playground"), to: "playground" , active: "playground"},
                {icon: <IconShare2 size={16}/>, color: "blue", label: t("Share"), to: "share" , active: "share"},
            ]
        }
    ]

    if (explore) {
        sections.splice(1, 1)
    }

    return (
        <ProjectLayout
            project={project}
            sections={sections}
            children={children}
            explore={explore}
        />
    )
}

export default DocumentsProjectLayout
