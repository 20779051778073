//
//
//  App
//
//

import {Navigate, Route, Routes} from "react-router-dom";
import Login from "./routes/Login.tsx";
import ProjectList from "./routes/ProjectList.tsx";
import ProjectListExplore from "./routes/ProjectListExplore.tsx";
import {AuthProvider} from "./contexts/AuthContext.tsx";
import RequireLoggedIn from "./components/RequireLoggedIn.tsx";
import Api from "./api.ts";
import {useEffect, useState} from "react";
import {LoadingOverlay} from "@mantine/core";
import {User} from "./interfaces.ts";
import ProjectIntents from "./routes/ProjectIntents.tsx";
import ProjectResponses from "./routes/ProjectResponses.tsx";
import ProjectStories from "./routes/ProjectStories.tsx";
import ProjectShare from "./routes/ProjectShare.tsx";
import Project from "./routes/Project.tsx";
import {ModalsProvider} from "@mantine/modals";
import ProjectBuilds from "./routes/ProjectBuilds.tsx";
import '@mantine/core/styles.css'
import '@mantine/core/styles.layer.css'
import 'mantine-datatable/styles.layer.css'
import '@mantine/notifications/styles.css'
import {Notifications} from "@mantine/notifications";
import Shared from "./routes/Shared.tsx";
import ProjectDeployments from "./routes/ProjectDeployments.tsx";
import ProjectTest from "./routes/ProjectTest.tsx";
import ForgotPassword from "./routes/ForgotPassword.tsx";
import PasswordReset from "./routes/PasswordReset.tsx";
import ProjectSniffer from "./routes/ProjectSniffer.tsx";
import ProjectCreate from "./routes/ProjectCreate.tsx";
import ProjectHome from "./routes/ProjectHome/index.tsx";
import ProjectSources from "./routes/ProjectSources.tsx";
import ProjectSettings from "./routes/ProjectSettings/index.tsx";
import ProjectPlayground from "./routes/ProjectPlayground/index.tsx";
import Settings from "./routes/Settings.tsx";
import Admin from "./routes/Admin.tsx";
import AdminHome from "./routes/AdminHome.tsx";
import AdminUsers from "./routes/AdminUsers.tsx";
import SettingsAccount from "./routes/SettingsAccount.tsx";
import SettingsBilling from "./routes/SettingsBilling.tsx";
import NotFound from "./routes/NotFound.tsx";
import { useApiErrorHandler } from "./hooks.ts";
import AdminProjects from "./routes/AdminProjects.tsx";
import TeamSettings from "./routes/TeamSettings.tsx";
import TeamSettingsTeam from "./routes/TeamSettingsTeam.tsx";
import TeamSettingsMembers from "./routes/TeamSettingsMembers.tsx";
import SettingsTeams from "./routes/SettingsTeams.tsx";
import TeamJoin from "./routes/TeamJoin.tsx";
import TeamSettingsBilling from "./routes/TeamSettingsBilling.tsx";


function App() {

    const handleError = useApiErrorHandler()
    const [user, setUser] = useState<User | null>(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        Api.getCurrentUser()
            .then(user => {
                setUser(user)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoading(false)
            })
    }, [])

    if (loading) {
        return (
            <LoadingOverlay visible loaderProps={{ size: "xl", variant: "oval"}}/>
        )
    } else {
        return (
            <ModalsProvider labels={{ confirm: "Submit", cancel: "Cancel" }}>
                <AuthProvider user={user}>
                    <Routes>
                        <Route path="/login" Component={Login}/>
                        <Route path="/shared" Component={Shared}/>
                        <Route path="/forgot-password" Component={ForgotPassword}/>
                        <Route path="/password-reset" Component={PasswordReset}/>
                        <Route element={<RequireLoggedIn/>}>
                            <Route path="/" element={<Navigate to="/projects" />}/>
                            <Route path="/projects" element={<ProjectList />}/>
                            <Route path="/projects/explore" element={<ProjectListExplore />}/>
                            <Route path="/settings" Component={Settings}>
                                <Route index element={<Navigate to="account" />}></Route>
                                <Route path="account" Component={SettingsAccount}/>
                                <Route path="billing" Component={SettingsBilling}></Route>
                                <Route path="teams" Component={SettingsTeams}></Route>
                            </Route>
                            <Route path="/teams/join" Component={TeamJoin}/>
                            <Route path="/teams/:teamId/settings" Component={TeamSettings}>
                                <Route index Component={TeamSettingsTeam}/>
                                <Route path="billing" Component={TeamSettingsBilling}></Route>
                                <Route path="members" Component={TeamSettingsMembers}></Route>
                            </Route>
                            <Route path="/projects/create" Component={ProjectCreate}/>
                            <Route path="/projects/:projectId" Component={Project}>
                                <Route index Component={ProjectHome}/>
                                <Route path="intents" Component={ProjectIntents}/>
                                <Route path="responses" Component={ProjectResponses}/>
                                <Route path="stories" Component={ProjectStories}/>,
                                <Route path="builds" Component={ProjectBuilds}/>,
                                <Route path="test" Component={ProjectTest}/>,
                                <Route path="playground" Component={ProjectPlayground}/>
                                <Route path="share" Component={ProjectShare}/>
                                <Route path="deployments" Component={ProjectDeployments}/>
                                <Route path="settings" Component={ProjectSettings}/>
                                <Route path="sniffer" Component={ProjectSniffer}/>
                                <Route path="sources" Component={ProjectSources}/>
                            </Route>
                            <Route path="/admin" Component={Admin}>
                                <Route index Component={AdminHome}/>
                                <Route path="users" Component={AdminUsers}/>
                                <Route path="projects" Component={AdminProjects}/>
                            </Route>
                            <Route path="*" Component={NotFound}/>
                        </Route>
                    </Routes>
                </AuthProvider>
                <Notifications />
            </ModalsProvider>
        )
    }
}

export default App
