//
//
//  Full Header
//
//

import {
    Container, Group,
} from "@mantine/core"
import classes from "./LoggedHeader.module.css"
import UserMenu from "./UserMenu.tsx"
import MAIALogo from "./MAIALogo.tsx";


function LoggedHeader() {
    return (
        <>
            <div className={classes.headerWrapper}>
                <Container className={classes.header}>
                    <MAIALogo/>
                    <Group>
                        <UserMenu/>
                    </Group>
                </Container>
            </div>
        </>
    )
}

export default LoggedHeader
