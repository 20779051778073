//
//
//  Project Responses Data Table
//
//

import {Response} from "../interfaces.ts";
import classes from "./DataTable.module.css"
import {Anchor, Flex, Spoiler, Text, Title} from "@mantine/core";
import {IconEdit} from "@tabler/icons-react";
import {DataTableEmpty} from "./DataTable.tsx";
import { DataTable } from "mantine-datatable";

import TimeAgoLocale from "./TimeAgoLocale.tsx";
import {useAutoAnimate} from "@formkit/auto-animate/react";
import { useTranslation } from "react-i18next";

function ProjectResponsesDataTable({responses, loading, onCreate, onSelection}: {responses: Response[], loading: boolean, onCreate: () => void, onSelection: (response: Response) => void}) {
    const { t, i18n } = useTranslation();
    const [bodyRef] = useAutoAnimate<HTMLTableSectionElement>();

    function renderResponseDetails(response: Response) {

        const isNew = (response as any)._isNew == true
        const itemStyle = isNew ? { backgroundColor: "#F5FBEF", marginBottom:'1rem'} : {marginBottom:'1rem'};

        return (
            <div className={classes.row} key={response.id} style={itemStyle}>
                <div className={classes.rowContent}>
                    <Anchor onClick={() => onSelection(response)}>
                        <Title size="h3" className={classes.rowTitle}>
                            {response.name}
                        </Title>
                    </Anchor>
                    <Spoiler maxHeight={40} showLabel={<Text size="sm">{t("Show more")}</Text>} hideLabel={<Text size="sm">{t("Hide")}</Text>} style={{"whiteSpace": "pre-line"}}>
                        <Text size="sm">
                            {response.response}
                        </Text>
                    </Spoiler>
                    <Flex className={classes.rowFooter}>
                        <Flex align="center" columnGap={6}>
                            <IconEdit size={12} color={"gray"}/>
                            <Text c="dimmed" size="xs">
                                <TimeAgoLocale date={new Date(response.updated_at)} language={i18n.language}/>
                            </Text>
                        </Flex>
                    </Flex>
                </div>
            </div>
        )
    }

    return (
        <DataTable
            noHeader
            withRowBorders={false}
            borderColor={'white'}
            rowClassName={classes.rowTitle}
            fetching={loading}
            verticalSpacing=""
            columns={[{ accessor: 'responses', render: renderResponseDetails }]}
            records={responses}
            bodyRef={bodyRef}
            emptyState={
                responses.length === 0 && (
                <div style={{ pointerEvents: 'all', width:'100%'}}>
                <DataTableEmpty
                    title={t("You don't have any response")}
                    subtitle={t("Let's get started") + " 🚀"}
                    action={t("Create your first Response")}
                    onAction={onCreate}
                />
                </div>)
            }
            minHeight={300}
            defaultColumnProps={{
                noWrap: true,
                ellipsis: true,
            }}
        />
    )
}

export default ProjectResponsesDataTable
