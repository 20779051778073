//
//
//  HelpAssistantChat
//
//


import {useState, useRef, useEffect} from 'react';
import {Container, ActionIcon, TextInput, rem} from '@mantine/core'
import {IconSend} from "@tabler/icons-react";
import {useForm} from "@mantine/form";
import classes from "./HelpAssistantChat.module.css"
import { HelpRequest } from '../interfaces.ts';
import Api from "../api.ts";
import { useTranslation } from "react-i18next";

type HelpAssistantChatProps = {
    chatMessages: any[];
    setChatMessages: React.Dispatch<React.SetStateAction<any[]>>;
    chatContext: HelpRequest[];
    setChatContext: React.Dispatch<React.SetStateAction<HelpRequest[]>>;
};

function HelpAssistantChat({ chatMessages, setChatMessages, chatContext, setChatContext }: HelpAssistantChatProps) {
    const { t } = useTranslation()
    const [botIsTyping, setBotIsTyping] = useState(false)
    const messagesRef = useRef<any[]>([])
    const [messages, setMessages] = useState<any[]>([])
    const [context, setContext] = useState<HelpRequest[]>();
      

    const form = useForm({
        initialValues: {
            text: ""
        }
    })
    useEffect(() => {
        setMessages(chatMessages);
        setContext(chatContext);
      }, []);

    async function onSubmit(values: any) {
        form.reset()
        const userMessage = {
            role: "user",
            content: values.text,
        };

        const updatedContext: HelpRequest[] = context ? [...context, userMessage] : [userMessage];        
        messagesRef.current = [...messages, {
            isUser: true,
            message: {
                text: values.text,
                datetime: new Date()
            }
        }]
        setMessages(messagesRef.current)
        setBotIsTyping(true)
        let streamValue = ''
        const response = await Api.help(updatedContext)
        const reader = response.body!.pipeThrough(new TextDecoderStream()).getReader()

        let messageIndex = -1
        // eslint-disable-next-line no-constant-condition
        while (true) {
            const {value, done} = await reader.read()
            if (value != undefined) {
                streamValue += value;
            }
            if (done) {
                setContext((prevContext) => [
                    ...(prevContext || []),
                    {
                      role: "user",
                      content: values.text,
                    },
                    {
                      role: "assistant",
                      content: streamValue,
                    },
                  ]);
                setChatMessages(messagesRef.current);
                if (context) {
                    setChatContext(context);
                  }
                break
            }
            if (messageIndex < 0) {
                const newMessages = [...messagesRef.current]
                messageIndex = newMessages.push({
                    isUser: false,
                    message: {
                        text: value,
                        datetime: new Date()
                    }
                }) - 1
                messagesRef.current = newMessages
                setMessages(messagesRef.current)
                setBotIsTyping(false)
            } else {
                const newMessages = [...messagesRef.current]
                newMessages[messageIndex]["message"]["text"] += value

                messagesRef.current = newMessages
                setMessages(messagesRef.current)
            }
        }
    }

    return (
            <Container className={classes.wrapper}>
                    <maia-message-list
                        bot-is-typing={botIsTyping ? true : undefined}
                        style={{flexGrow: 1, overflow: scroll}}
                        messages={JSON.stringify(messages)}
                    ></maia-message-list>
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <TextInput
                        radius="xl"
                        size="md"
                        required
                        placeholder={t("Ask any question")}
                        rightSectionWidth={42}
                        rightSection={
                            <ActionIcon size={32} radius="xl" variant="filled" type="submit">
                                <IconSend style={{width: rem(18), height: rem(18)}} stroke={1.5}/>
                            </ActionIcon>
                        }
                        {...form.getInputProps("text")}
                    />
                </form>
            </Container>
    )
}

export default HelpAssistantChat
