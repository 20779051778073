//
//
//  Project Layout
//
//

import {ReactNode, useState} from "react";
import Api from "../api.ts";
import {AppShell, Container, Flex, Text} from "@mantine/core";
import LoggedHeader from "../components/LoggedHeader.tsx";
import ProjectNavbarHeader from "../components/ProjectNavbarHeader.tsx";
import classes from "./ProjectLayout.module.css";
import NavbarLink from "../components/NavbarLink.tsx";
import {IconSettings} from "@tabler/icons-react";
import {Project, NavbarSection, NavbarSectionItem} from "../interfaces.ts";
import {useLocation} from "react-router-dom";
import {notifications} from "@mantine/notifications";
import { useApiErrorHandler } from "../hooks.ts";
import { useTranslation } from "react-i18next";

function ProjectLayout({project, sections, children, explore}: {project: Project, sections: NavbarSection[], children: ReactNode, explore: boolean}) {
    const { t } = useTranslation();
    const {pathname} = useLocation()
    const handleError = useApiErrorHandler()
    const [buildLoading, setBuildLoading] = useState(false)

    // Get active URL
    const pathparts = /\/projects\/\d+\/(\w*)\/?/.exec(pathname)
    let active: string
    if (!pathparts) {
        active = "home"
    } else {
        active = pathparts[1]
    }

    function onBuild() {
        setBuildLoading(true)
        Api.createBuild(project.id)
            .then(() => {
                setBuildLoading(false)
                notifications.show({
                    title: t('Build created'),
                    message: t('Build created successfully. It may take a while ...') + ' 🚀',
                })
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setBuildLoading(false)
            })
    }

    return (
        <AppShell
            header={{height: 60}}
            navbar={{width: 300, breakpoint: 0}}
        >
            <AppShell.Header>
                <LoggedHeader/>
            </AppShell.Header>
            <AppShell.Navbar>
                <ProjectNavbarHeader project={project} onBuild={onBuild} buildLoading={buildLoading} explore={explore}/>
                <Flex direction="column" mt="md" className={classes.navbarMainSection}>
                    {sections.map((section: NavbarSection, sectionIndex: number) => {
                        return (
                            <Flex direction="column" key={sectionIndex}>
                                {section.label != null &&
                                    <Text size="xs" w={500} c="dimmed" className={classes.navbarSectionHeader}>
                                        {section.label}
                                    </Text>
                                }
                                {section.items.map((item: NavbarSectionItem, itemIndex: number) => {
                                    return (
                                        <NavbarLink state={project} {...item} active={active === item.active} key={itemIndex}/>
                                    )
                                })}
                            </Flex>
                        )
                    })}
                </Flex>
                <div className={classes.navbarFooter}>
                    <NavbarLink
                        state={project}
                        label={t("Settings")}
                        to={`/projects/${project.id}/settings/`}
                        icon={<IconSettings size={16}/>}
                        active={active === "settings"}
                    />
                </div>
            </AppShell.Navbar>
            <AppShell.Main className={classes.main}>
                <Container className={classes.container}>
                    {children}
                </Container>
            </AppShell.Main>
        </AppShell>
    )
}

export default ProjectLayout
