//
//
//  Project Playground
//
//

import "@maia-cognitive/maia-videochat"
// import "maia-chat-widget"  FIXME: NotSupportedError: Cannot define multiple custom elements with the same tag name
import {Project} from "../../interfaces.ts";
import {useOutletContext} from "react-router-dom";
import ProjectPlaygroundCustom from "./Custom.tsx";
import ProjectPlaygroundDocuments from "./Documents.tsx";
import { useTranslation } from "react-i18next";


function ProjectPlayground() {
    const { t } = useTranslation()
    const [project]: [Project] = useOutletContext()

    if (project.type === "CUSTOM") {
        return <ProjectPlaygroundCustom/>
    } else if (project.type === "DOCUMENTS") {
        return <ProjectPlaygroundDocuments/>
    } else {
        throw new Error(t(`Unexpected project type`) + `: ${project.type}`)
    }

}

export default ProjectPlayground
