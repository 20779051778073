//
//
//  Team Settings My Team
//
//

import {Button, Flex, Group, rem, Textarea, TextInput, Title} from "@mantine/core";
import {useEffect, useState} from "react";
import {useForm} from "@mantine/form";
import {useOutletContext} from "react-router-dom";
import Api from "../api.ts";
import {Team} from "../interfaces.ts";
import {useApiErrorHandler} from "../hooks.ts";
import {notifications} from "@mantine/notifications";
import { useTranslation } from "react-i18next";


function TeamSettingsTeam() {
    const { t } = useTranslation()
    const handleError = useApiErrorHandler()
    const [team, setTeam]: [Team, (team: Team) => void] = useOutletContext()
    const [submitLoading, setSubmitLoading] = useState(false)

    const form = useForm({
        initialValues: {
            name: team.name,
            description: team.description || ""
        },
        validate: {
            name: value => (value.length < 2 ? t('Name must have at least 2 letters') : null),
        },
    })

    useEffect(() => {
        form.setValues({
            name: team.name,
            description: team.description
        })
    }, [team])

    function onSubmit(values: any) {
        setSubmitLoading(true)
        Api.updateTeam(team.id, values)
            .then(response => {
                setTeam(response)
                notifications.show({
                    title: t("Team updated"),
                    message: t("Team updated successfully"),
                    color: "green",
                })
            }).catch(err => {
                console.error(err)
                handleError(err)
            }).finally(() => {
                setSubmitLoading(false)
            })
    }

    return (
        <>
            <Flex
                justify="space-between"
                align="center"
                direction="row"
                mt={rem(30)}
                mb={rem(30)}
            >
                <Title size="h1">{t("Team")}</Title>
            </Flex>
            <form onSubmit={form.onSubmit(onSubmit)}>
                <TextInput
                    withAsterisk
                    required
                    label={t("Name")}
                    {...form.getInputProps('name')}
                />
                <Textarea
                    mt="md"
                    label={t("Description")}
                    {...form.getInputProps('description')}
                />
                <Group justify="flex-start" mt="xl">
                    <Button loading={submitLoading} type="submit">{t("Save")}</Button>
                </Group>
            </form>
        </>
    )
}

export default TeamSettingsTeam
